import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import HighReturn from '../assets/imgs/vault/HighReturn.webp'
import Nonstock from '../assets/imgs/vault/Nonstock.webp'
import green from '../assets/imgs/vault/Green.webp'
import Monthly from '../assets/imgs/vault/MonthlyPayments.webp'
import money from '../assets/imgs/vault/money.webp'
import battery from '../assets/imgs/vault/battery.webp'
import half_ring from '../assets/imgs/half-ring.webp'

const RACEVaultSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <>
      <section className='RACE__Vault__main_section'>
        <img
          alt='img'
          loading='lazy'
          src={half_ring}
          className='img-fluid half_ring'
        />

        <div className='container'>
          <div className='RACE__Vault__main_headings'>
            <h1>RACE Vault</h1>
            <p>
              Give your investment portfolio a new look by investing in the
              world-class technology assets built by RACE, and get a new income
              stream to boost your finances​.
            </p>
          </div>
          <section>
            <Carousel
              swipeable={true}
              draggable={true}
              responsive={responsive}
              infinite={false}
              showDots={true}
              // ref={carouselRef}
            >
              <div>
                <div className='RACEVaultSlider_single_box'>
                  <div className='RACEVaultSlider_single_box_content'>
                    <h2>High Return Assets</h2>
                    <p>
                      Up to 26% IRR, our returns are the highest in the market.
                      ​
                    </p>
                  </div>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={HighReturn}
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className='RACEVaultSlider_single_box'>
                  <div className='RACEVaultSlider_single_box_content'>
                    <h2>Non-Market Linked</h2>
                    <p>
                      Get guaranteed profits that are secure even in volatile
                      markets.{' '}
                    </p>
                  </div>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={Nonstock}
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className='RACEVaultSlider_single_box'>
                  <div className='RACEVaultSlider_single_box_content'>
                    <h2>Go Green. Go Electric!</h2>
                    <p>
                      Helps reduce CO2 emissions and enrich auto-rickshaw
                      drivers’ lives.​
                    </p>
                  </div>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={green}
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className='RACEVaultSlider_single_box'>
                  <div className='RACEVaultSlider_single_box_content'>
                    <h2>Monthly Payment​</h2>
                    <p>Earn monthly returns on your investment on time. </p>
                  </div>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={Monthly}
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </Carousel>
          </section>

          <section className='slider_bottom_content_section'>
            <div className='row'>
              <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                <div className='slider_bottom_content_single_box'>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={money}
                      className='img-fluid money_img'
                    />
                  </div>
                  <div>
                    <p>Investments start as low as </p>
                    <h3>Rs. 62,000/-</h3>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                <div className='slider_bottom_content_single_box'>
                  <div>
                    <img
                      alt='img'
                      loading='lazy'
                      src={battery}
                      className='img-fluid battery_img'
                      style={{ width: '29px' }}
                    />
                  </div>
                  <div>
                    <p>Applicable on taxable income every year </p>
                    <h3>Enjoy depreciation benefits </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default RACEVaultSlider
