import React from 'react'
import Hero from './HomePages/Hero'
import Battery from './HomePages/Battery'
import OneStopSolution from './HomePages/OneStopSolution'
import VideoSection from './HomePages/VideoSection'
import OurTech from './HomePages/OurTech'
import Testimonial from './HomePages/Testimonial'
import Partnership from './HomePages/Partnership'
import Footprint from './HomePages/Footprint '
import Faq from './HomePages/Faq'

const Main = () => {
  return (
    <>
      <Hero />
      <Battery />
      <OneStopSolution />
      <VideoSection />
      <OurTech />
      <Testimonial />
      <Partnership />
      <Footprint />
      <Faq />
    </>
  )
}

export default Main
