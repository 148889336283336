import React from 'react'
import NewsRoomHero from './NewsRoomHero'
import Morestories from './Morestories'
import FeaturedVideos from './FeaturedVideos'
import MoreVideos from './MoreVideos'
import PressKit from './PressKit'

const NewsroomMain = () => {
  return (
    <>
      <NewsRoomHero />
      <Morestories />
      <FeaturedVideos />
      <MoreVideos />
      <PressKit />
    </>
  )
}

export default NewsroomMain
