import React from 'react'
import race_valut from '../assets/imgs/Franchise.png'
import high_return from '../assets/imgs/box_height_returns.png'
import Investment_money from '../assets/imgs/partner/invest-money.png'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import Franchise from '../assets/imgs/race-valut.png'
import partnerships from '../assets/imgs/Partnership.png'
import fleet from '../assets/imgs/fleet.png'
import partnership_right_arrow from '../assets/imgs/partnership_right-arrow.webp'
import partnership_bottom from '../assets/imgs/partnership_bottom.webp'
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const Partnership = () => {
  return (
    <>
      <section className='partnership_main_section'>
        <img
          src={partnership_right_arrow}
          alt='img'
          loading='lazy'
          className='img-fluid partnership_right_arrow'
        />
        <img
          src={partnership_bottom}
          alt='img'
          loading='lazy'
          className='img-fluid partnership_bottom'
        />

        <div className='container'>
          <div className='partnership_main_inner_box_border'>
            <div className='partnership_main_inner_box'>
              <div className='partnership_main_headings'>
                <h1>Let’s Talk About Partnership</h1>
                <p>
                  We offer promising opportunities for partners, businesses,
                  fleets and investors to earn market beating returns through
                  our cutting-edge tech and diverse product line.
                </p>
              </div>

              <div className='partnership_single_box'>
                <img
                  src={high_return}
                  alt='img'
                  loading='lazy'
                  className='img-fluid high_return_img'
                />
                <img
                  src={Investment_money}
                  alt='img'
                  loading='lazy'
                  className='img-fluid Investment_money'
                />

                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div>
                      <img
                        src={race_valut}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='partnership_single_box_content'>
                      <h1>RACE Vault</h1>
                      <p>
                        Invest in RACE's world-class technology assets to give
                        your investment portfolio a new look with guaranteed
                        return. Boost India's clean-tech revolution to reduce
                        pollution and move the country towards sustainability.
                      </p>
                    </div>
                    <div className='partnership_single_box_content_blue_btn'>
                      <HashLink to='/vault' className=''>
                        Start Investing
                        <img
                          src={btn_arrow}
                          className='learn_arrow'
                          alt='arrow'
                        />
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className='partnership_single_box'>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div>
                      <img
                        src={Franchise}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='partnership_single_box_content'>
                      <h1>Franchise</h1>
                      <p>
                        Transform vacant spaces into a lucrative source of
                        monthly income by hosting a RACE swap station.
                      </p>
                    </div>
                    <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                      <HashLink to='/network#Franchise' className=''>
                        Become franchise
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className='partnership_single_box'>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div>
                      <img
                        src={partnerships}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='partnership_single_box_content'>
                      <h1>Partnership </h1>
                      <p>
                        Bring & manage RACE’s Swap Network and the whole suite
                        of vehicle options to your country, city, town or area.
                      </p>
                    </div>
                    <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                      <HashLink to='/partner#Partnership' className=''>
                        Turn into area partner
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className='partnership_single_box'>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div>
                      <img
                        src={fleet}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='partnership_single_box_content'>
                      <h1>Fleet /OEM</h1>
                      <p>
                        Shift your fleet to or build your next EV on RACE’s high
                        energy density swappable batteries, now AIS 156 Phase II
                        certified.
                      </p>
                    </div>
                    <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                      <HashLink to='/vehicle-solution' className=''>
                        Try out fleet/OEM
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='partnership_mob_section'>
        <img
          src={partnership_right_arrow}
          alt='img'
          loading='lazy'
          className='img-fluid partnership_right_arrow'
        />
        <div className='container'>
          <div className='partnership_main_inner_box_border'>
            <div className='partnership_main_inner_box'>
              <div className='partnership_main_headings'>
                <h1>Lets talk about Partnership</h1>
                <p>
                  We offer promising opportunities for partners, businesses,
                  fleets and investors to earn market beating returns through
                  our cutting-edge tech and diverse product line.
                </p>
              </div>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    src={high_return}
                    alt='img'
                    loading='lazy'
                    className='img-fluid high_return_img'
                  />
                  <div className='mob_partnership_single_box_border'>
                    <div className='partnership_single_box'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div>
                            <img
                              src={race_valut}
                              alt='img'
                              loading='lazy'
                              className='img-fluid'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='partnership_single_box_content'>
                            <h1>RACE vault.</h1>
                            <p>
                              Invest in RACE's world-class technology assets to
                              give your investment portfolio a new look with
                              guaranteed return. Boost India's clean-tech
                              revolution to reduce pollution and move the
                              country towards sustainability.
                            </p>
                          </div>
                          <div className='partnership_single_box_content_blue_btn'>
                            <Link className=''>
                              Start Investing
                              <img
                                src={btn_arrow}
                                className='learn_arrow'
                                alt='arrow'
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='mob_partnership_single_box_border'>
                    <div className='partnership_single_box'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div>
                            <img
                              src={Franchise}
                              alt='img'
                              loading='lazy'
                              className='img-fluid'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='partnership_single_box_content'>
                            <h1>Franchise</h1>
                            <p>
                              Transform vacant spaces into a lucrative source of
                              monthly income by hosting a RACE swap station.
                            </p>
                          </div>
                          <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                            <Link className=''>Become franchise </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='mob_partnership_single_box_border'>
                    <div className='partnership_single_box'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div>
                            <img
                              src={partnerships}
                              alt='img'
                              loading='lazy'
                              className='img-fluid'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='partnership_single_box_content'>
                            <h1>Partnership </h1>
                            <p>
                              Bring & manage RACE’s Swap Network and the whole
                              suite of vehicle options to your country, city,
                              town or area.
                            </p>
                          </div>
                          <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                            <Link className=''>Turn into area partner</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='mob_partnership_single_box_border'>
                    <div className='partnership_single_box'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div>
                            <img
                              src={fleet}
                              alt='img'
                              loading='lazy'
                              className='img-fluid'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <div className='partnership_single_box_content'>
                            <h1>Fleet /OEM</h1>
                            <p>
                              Shift your fleet to or build your next EV on
                              RACE’s high energy density swappable batteries,
                              now AIS 156 Phase II certified.
                            </p>
                          </div>
                          <div className='partnership_single_box_content_blue_btn partnership_single_box_content_green_btn'>
                            <Link className=''>Try out fleet/OEM </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Partnership
