import React from 'react'

import footer_logo from '../components/assets/imgs/footer-logo.png'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useNavigate } from 'react-router-dom'
const Footer = () => {
  const area = useNavigate()
  const Franchise = useNavigate()

  const Fleet = useNavigate()
  const OEM = useNavigate()

  const handleAreaPartnerClick = () => {
    area('/partner#area')
  }
  const handleFranchiseClick = () => {
    Franchise('/partner#Franchise')
  }
  const handleFleetClick = () => {
    Fleet('/partner#Fleet')
  }
  const handleOEMClick = () => {
    OEM('/partner#OEM')
  }

  return (
    <>
      <footer className='footer_main_section'>
        <div className='footer_main_section_border'></div>
        <div className='footer_main_section_inner'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12 footer_col'>
                <div>
                  <img
                    src={footer_logo}
                    alt='logo'
                    loading='lazy'
                    className='img-fluid footer_logo'
                  />
                  <div>
                    <div className='footer_contact_details_single'>
                      <span>Email:</span>
                      <Link
                        className='phone__email'
                        to='mailto:hello@racenergy.in'
                      >
                        hello@racenergy.in
                      </Link>
                    </div>
                    <div className='footer_contact_details_single'>
                      <span>Partner:</span>
                      <Link
                        className='phone__email'
                        to='mailto:partner@racenergy.in'
                      >
                        partner@racenergy.in
                      </Link>
                    </div>
                    <div className='footer_contact_details_single'>
                      <span>Phone Number:</span>
                      <Link className='phone__email' to='tel:+919281066240 '>
                        +91 92810 66240
                      </Link>
                    </div>
                    <Link
                      to='/contactus'
                      className='theme_btn login_btn anchor footer_contact_btn'
                    >
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-8 col-md-8 col-sm-12'>
                <div className='row'>
                  <div className=' col-lg  col-sm-12   footer_col'>
                    <h3>
                      <Link to='/'> Home</Link>
                    </h3>
                    <HashLink to='/#onestopsolution'>
                      One Stop Solution
                    </HashLink>
                    <HashLink to='/#blogs'>Blogs</HashLink>
                    <HashLink to='/#FAQ'>FAQ</HashLink>
                  </div>
                  <div className=' col-lg  col-sm-12   footer_col'>
                    <h3>
                      <Link to='/battery'> Battery</Link>
                    </h3>
                    <HashLink to='/battery#technology'>Technology </HashLink>
                    <HashLink to='/battery#gen'>Gen 1 & Gen 2</HashLink>
                    <HashLink to='/battery#RACEVault'>RACE Vault</HashLink>
                  </div>
                  <div className=' col-lg  col-sm-12   footer_col'>
                    <h3>
                      <HashLink to='/network'> Network</HashLink>
                    </h3>
                    <HashLink to='/network#Features'>Features </HashLink>
                    <HashLink to='/network#Franchise'>RACE Franchise</HashLink>
                  </div>

                  <div className=' col-lg  col-sm-12   footer_col'>
                    <h3>
                      <HashLink to='/partner'> Partner</HashLink>
                    </h3>
                    <HashLink to='/partner#Partnership'> Area Partner</HashLink>
                    <HashLink to='/network#Franchise'> Franchise</HashLink>
                    <HashLink to='/vehicle-solution#fleet'> Fleet</HashLink>
                    <HashLink to='/vehicle-solution#oem'> OEM</HashLink>

                    <HashLink to='/vault'>Invest</HashLink>
                    <HashLink to='/partner#become-partner'>
                      Become a Partner
                    </HashLink>
                  </div>

                  <div className=' col-lg  col-sm-12   footer_col'>
                    <h3>
                      <Link to='/aboutus'> About Us</Link>
                    </h3>
                    <HashLink to='/aboutus#OurStory'>Our Story</HashLink>
                    <HashLink to='/aboutus#team'>Team</HashLink>
                    <HashLink to='/aboutus#Careers'>Careers</HashLink>
                    <HashLink to='/aboutus#News'>In the News</HashLink>
                    <HashLink to='/newsroom'>Newsroom</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='copy_right_text' id='contact'>
          ©2023 RACE Energy Ltd. | All rights reserved.
        </div>
      </footer>
    </>
  )
}

export default Footer
