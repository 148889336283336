import React, { useEffect } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link, json } from 'react-router-dom'
import { BsCheck2 } from 'react-icons/bs'
import { AiOutlinePlus } from 'react-icons/ai'
import Dropdown from 'react-bootstrap/Dropdown'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import btn_arrow_blue from '../assets/imgs/btn_arrow_blue.png'
import download from '../assets/imgs/download.png'
import { useNavigate } from 'react-router-dom'
import FileSaver from 'file-saver'

const JoinOur = () => {
  const navigate = useNavigate()

  const [file, setFile] = useState(null)
  const [fileStatus, setFileStatus] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  console.log(file)
  const handleFileChange = event => {
    setFile(event.target.files[0])
    setFileStatus(true)
  }

  const handleUpload = () => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://race.pselier.com/api/about-us', true)
    xhr.upload.addEventListener('progress', event => {
      const progress = Math.round((event.loaded / event.total) * 100)
      setUploadProgress(progress)
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File upload completed
        // Handle the response from the server if needed
      }
    }

    const formData = new FormData()
    formData.append('file', file)
    xhr.send(formData)
  }

  const [JobCat, setJobCat] = useState([])
  const [selectedcount, setselectedcount] = useState()
  // ============================================
  // ===========Modal after form submit==========
  // ============================================
  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)

  // ============================================
  // ===========Hooks for form Fields==========
  // ============================================
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    linkedIn: null,
    phone: null,
    designation: null,
    hear_about_us: null
  })

  const [resume, setResume] = useState(null)
  const { name, email, linkedIn, phone, designation, hear_about_us } = formData

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // ============================================
  // =============Hear about us==================
  // ============================================
  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  // ============================================
  // ======Form submit and call the API==========
  // ============================================
  const AboutForm = e => {
    e.preventDefault()
    AboutFormAPI()

    setFormData('')
    navigate('/thankyou')
    // console.log(name, email, linkedIn, phone, designation, hear_about_us)
    // if (
    //   name &&
    //   email &&
    //   phone &&
    //   linkedIn &&
    //   designation &&
    //   hearInput_string &&
    //   resume &&
    //   fileStatus &&
    //   file?.size < 5242880
    // ) {
    //   AboutFormAPI()

    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // ============================================
  // ================API Call===================
  // ============================================
  const AboutFormAPI = () => {
    if (file?.size > 5242880 || fileStatus == false) {
      return
    } else {
      var formdata = new FormData()
      formdata.append('name', name ?? '')
      formdata.append('email', email ?? '')
      formdata.append('mobile', phone ?? '')
      formdata.append('linkedIn_profile', linkedIn ?? '')
      formdata.append('designation', designation ?? '')
      formdata.append('hear_about_us', hearInput_string ?? '')
      formdata.append('resume', resume ?? '')

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      }

      fetch('https://race.pselier.com/api/about-us', requestOptions)
        .then(response => response.json())
        .then(result => {
          setResume(null)
          setFile(null)
          console.log(result)
          setFileStatus(false)
          handleShow_success()
        })
        .catch(error => console.log('error', error))
    }
  }

  const JobsCatApi = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/job-categories', requestOptions)
      .then(response => response.json())
      .then(result => setJobCat(result.result))
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    JobsCatApi()
  }, [])

  const defaultActiveKey = JobCat[0]?.name
  // const jobDes = JobCat[5]?.jobs[0].pdf

  // console.log('description', jobDes)

  // const handleDownload = () => {
  //   if (jobDes) {
  //     const blob = new Blob([jobDes]) // Adjust MIME type accordingly
  //     const url = URL.createObjectURL(blob)
  //     const a = document.createElement('a')
  //     a.href = url
  //     a.download = jobDes // Set the suggested filename
  //     a.click()
  //     URL.revokeObjectURL(url)
  //   }
  // }
  // const filebaseurl = race.pselier.com / uploads / pdf_files

  // https://race.pselier.com/uploads/pdf_files/64da0b8a7829c.pdf

  // const onButtonClick = Data => {
  //   console.log('File name', Data)
  //   fetch(Data).then(response => {
  //     response.blob().then(blob => {
  //       const fileURL = window.URL.createObjectURL(blob)
  //       console.log(blob)
  //       let alink = document.createElement('a')
  //       alink.href = fileURL
  //       alink.download = Data
  //       alink.click()
  //     })
  //   })
  // }

  const filebaseurl = 'https://race.pselier.com/uploads/pdf_files'

  const onButtonClick = fileName => {
    const completeFileURL =
      'https://race.pselier.com/uploads/pdf_files/' + fileName
    console.log(completeFileURL)
    fetch(completeFileURL)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = window.URL.createObjectURL(blob)

        let alink = document.createElement('a')
        alink.href = fileURL
        alink.download = fileName
        alink.click()
      })
    // .catch(error => {
    //   console.error('Error fetching the file:', error)
    // })
  }
  // const downloadFile = async () => {
  //   fetch(
  //     'https://carbon-copies-restapi.herokuapp.com/all-order-histories.txt'
  //   ).then(response => {
  //     response.blob().then(blob => {
  //       var FileSaver = require('file-saver')
  //       var blob = new Blob(blob, { type: 'text/plain;charset=utf-8' })
  //       FileSaver.saveAs(blob, 'hello world.txt')
  //     })
  //   })
  const downloadFile = async e => {}
  return (
    <>
      <section className=' join_our_main_section' id='Careers'>
        <div className='container'>
          <div className='main_headings'>
            <h1>Join Our Makerspace!</h1>
            <p>Where making, tinkering, exploring and learning is a passion!</p>
          </div>

          <section className='tabs_div'>
            <Tabs
              defaultActiveKey={defaultActiveKey}
              id='uncontrolled-tab-example'
              className=' tabs_div_inner'
            >
              {JobCat.map(currEle => {
                return (
                  <Tab eventKey={currEle.name} title={currEle.name}>
                    <div className='row'>
                      {currEle.jobs.map(currEles => {
                        return (
                          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                            <div className='join_our_small_boxes'>
                              <h3>{currEles.title}</h3>
                              <h4>{currEles.vacancy} Vacancy</h4>
                              <div
                                className='a download_job_btn'
                                onClick={() => {
                                  window.location.href =
                                    'https://race.pselier.com/api/new-applic?id=' +
                                    currEles.pdf
                                }}
                              >
                                Download Job Description
                                <img
                                  src={download}
                                  className='learn_arrow'
                                  alt='arrow'
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Tab>
                )
              })}
            </Tabs>
          </section>

          <section className='join_form_section_border'>
            <div className='join_form_section'>
              <h4>Apply now</h4>
              <form
                className='footprint_form'
                onSubmit={AboutForm}
                autoComplete='off'
              >
                <div className='row'>
                  <div className='col-lg-11 col-md-12 col-sm-12'>
                    <div className='row'>
                      {/* name */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='text'
                            name='name'
                            placeholder=' '
                            value={formData?.name ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>Name*</label>
                        </div>
                      </div>
                      {/* email */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='email'
                            name='email'
                            placeholder=' '
                            value={formData.email ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            Email Id*
                          </label>
                        </div>
                      </div>
                      {/* LinkedIn Profile* */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='text'
                            placeholder=' '
                            name='linkedIn'
                            value={formData.linkedIn ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            LinkedIn Profile*
                          </label>
                        </div>
                      </div>
                      {/* phone number */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='number'
                            placeholder=' '
                            name='phone'
                            value={formData.phone ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            Phone number*
                          </label>
                        </div>
                      </div>
                      {/* Job Designation */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='text'
                            placeholder=' '
                            name='designation'
                            value={formData.designation ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            Job Designation*
                          </label>
                        </div>
                      </div>
                      {/* Where did you .... */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <Dropdown className='custom_drop_down'>
                            <Dropdown.Toggle
                              variant=''
                              className='custom_drop_down_btn'
                              id='dropdown-basic'
                            >
                              {selectedcount ? (
                                <span> {selectedcount} Selected</span>
                              ) : (
                                <span>Where did you hear about us?</span>
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Search Engine (Google, Bing, etc.)'
                                  id='flexCheckChecked1'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked1'
                                >
                                  Search Engine (Google, Bing, etc.)
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Recommended by a Friend/Colleague'
                                  id='flexCheckChecked2'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked2'
                                >
                                  Recommended by a Friend/Colleague
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Social Media (LinkedIn, Twitter)'
                                  id='flexCheckChecked3'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked3'
                                >
                                  Social Media (LinkedIn, Twitter)
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Blog/Publication'
                                  id='flexCheckChecked4'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked4'
                                >
                                  Blog/Publication
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Signage'
                                  id='flexCheckChecked5'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked5'
                                >
                                  Signage
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Networking Event'
                                  id='flexCheckChecked6'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked6'
                                >
                                  Networking Event
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Startup Founder'
                                  id='flexCheckChecked7'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked7'
                                >
                                  Startup Founder
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Word of Mouth'
                                  id='flexCheckChecked8'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked8'
                                >
                                  Word of Mouth
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Other'
                                  id='flexCheckChecked9'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked9'
                                >
                                  Others
                                </label>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div className='col-lg-4 col-md-4 col-sm-12 join_form_field'>
                        <div className='upload-btn-wrapper'>
                          {!fileStatus ? (
                            <button className='file_upload_btn'>
                              Upload your resume{' '}
                              <AiOutlinePlus className='file_uppload_icon' />{' '}
                            </button>
                          ) : (
                            <>
                              <div className='file_upload_btn d_flex_centter'>
                                <div>{file?.name}</div>
                                {file?.size > 5242880 ? (
                                  <div style={{ color: 'red' }}>X</div>
                                ) : (
                                  <BsCheck2 className='check_btn' />
                                )}
                              </div>
                              <div>
                                {file?.size > 5242880 ? (
                                  <p className='error_name'>
                                    {' '}
                                    Please upload file less then 5MB.
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </>
                          )}
                          <input
                            type='file'
                            name='myfile'
                            accept='application/pdf'
                            onChange={e => {
                              setResume(e.target.files[0])
                              handleFileChange(e)
                              // handleUpload()
                            }}
                          />
                          {uploadProgress > 0 && (
                            <div>
                              <p className='uploading_file_name'>
                                {' '}
                                {/* {file?.name} Successfully Uploaded . */}
                              </p>
                              {/* <progress value={uploadProgress} max="100" /> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form_submit_btn'>
                  <button className='' type='submit'>
                    Submit{' '}
                    <img src={btn_arrow} className='learn_arrow' alt='arrow' />
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>

      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </>
  )
}

export default JoinOur
