import React from 'react'
import { BsChevronDown } from 'react-icons/bs'
import about_bg_fade1 from '../assets/imgs/about-bg-fade1.webp'
import about_bg_fade2 from '../assets/imgs/about-bg-fade2.webp'

const PartnerHero = () => {
  return (
    <>
      <div className="partner_hero_main overflow_hidden">
        <section className="about_hero_main_section">
          <img
            src={about_bg_fade1}
            className="img-fluid about_bg_fade1"
            alt="img"
            loading="lazy"
          />
          <img
            src={about_bg_fade2}
            className="img-fluid about_bg_fade2"
            alt="img"
            loading="lazy"
          />

          <div className="container">
            <h1 className="main_heading partner_hero gradient_heading">
              Let’s Together Build India’s EV Ecosystem
            </h1>
            <p className="main_para">
              Explore exciting partnership opportunities at RACE that is paving
              the way to EV growth in India.
            </p>

            <div className="arrow_down_btn bounce">
              <BsChevronDown className="hero_down_arrow_icon" />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PartnerHero
