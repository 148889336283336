import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import News1 from '../assets/imgs/newsroom/ev-charger1.webp'
import News2 from '../assets/imgs/newsroom/ev-charger2.webp'
import footer_bg from '../assets/imgs/footer-bg.webp'
import news_bg_fade1 from '../assets/imgs/newsroom/news-bg-fade1.png'
import news_bg_fade2 from '../assets/imgs/newsroom/news-bg-fade2.webp'
import news_bg_fade3 from '../assets/imgs/newsroom/news-bg-fade3.webp'
import news_bg_fade4 from '../assets/imgs/newsroom/news-bg-fade4.webp'

import { FiArrowUpRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import btn_arrow from '../assets/imgs/arrow_btn.png'

const Recommended_Reads = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="container">
        <section className="Recommended_Reads_section_main">
          <img
            src={news_bg_fade1}
            className="img-fluid news_bg_fade1"
            alt="img"
            loading="lazy"
          />
          <img
            src={news_bg_fade2}
            className="img-fluid news_bg_fade2"
            alt="img"
            loading="lazy"
          />
          <img
            src={news_bg_fade3}
            className="img-fluid news_bg_fade3"
            alt="img"
            loading="lazy"
          />
          <img
            src={news_bg_fade4}
            className="img-fluid news_bg_fade4"
            alt="img"
            loading="lazy"
          />
          <img
            src={footer_bg}
            className="img-fluid footer_bg"
            alt="img"
            loading="lazy"
          />

          <div className="Recommended_Reads_main_border">
            <div className="Recommended_Reads_main">
              <h1>Recommended Reads</h1>

              <section>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  responsive={responsive}
                  infinite={false}
                  showDots={true}
                >
                  <div>
                    <div className="new_box_border">
                      <div className="new_box_main">
                        <img
                          src={News1}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="new_read_more">
                          <Link className="read_more" to="#">
                            Read More{' '}
                            <img
                              src={btn_arrow}
                              className="learn_arrow"
                              alt="arrow"
                            />
                          </Link>
                        </div>
                        <div className="news_content">
                          <h3>5 min read</h3>
                          <h2>
                            Difference between EV charging & battery swap{' '}
                          </h2>
                          <p>
                            Ut enim ad minim veniam, quis nostr rcitatio enim ad
                            minim veniam, quis nostrminihumud xercitationUt
                            enquis nostr rcitatio enim ad{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="new_box_border">
                      <div className="new_box_main">
                        <img
                          src={News2}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="new_read_more">
                          <Link className="read_more" to="#">
                            Read More{' '}
                            <img
                              src={btn_arrow}
                              className="learn_arrow"
                              alt="arrow"
                            />
                          </Link>
                        </div>
                        <div className="news_content">
                          <h3>5 min read</h3>
                          <h2>
                            Difference between EV charging & battery swap{' '}
                          </h2>
                          <p>
                            Ut enim ad minim veniam, quis nostr rcitatio enim ad
                            minim veniam, quis nostrminihumud xercitationUt
                            enquis nostr rcitatio enim ad{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="new_box_border">
                      <div className="new_box_main">
                        <img
                          src={News1}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="new_read_more">
                          <Link className="read_more" to="#">
                            Read More{' '}
                            <img
                              src={btn_arrow}
                              className="learn_arrow"
                              alt="arrow"
                            />
                          </Link>
                        </div>
                        <div className="news_content">
                          <h3>Press release, 20th March 2023</h3>
                          <h2>
                            How state EV policies are revving up the shift
                            towards electric mobility{' '}
                          </h2>
                          <p>
                            Launched by the Indian government in 2015, Faster
                            Adoption and Manufacturing of (Hybrid &) Electric
                            Vehicles (FAME) schemes I and II have been widely
                            successful in strengthening electric.....{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="new_box_border">
                      <div className="new_box_main">
                        <img
                          src={News2}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="new_read_more">
                          <Link className="read_more" to="#">
                            Read More{' '}
                            <img
                              src={btn_arrow}
                              className="learn_arrow"
                              alt="arrow"
                            />
                          </Link>
                        </div>
                        <div className="news_content">
                          <h3>Press release, 20th March 2023</h3>
                          <h2>
                            How state EV policies are revving up the shift
                            towards electric mobility{' '}
                          </h2>
                          <p>
                            Launched by the Indian government in 2015, Faster
                            Adoption and Manufacturing of (Hybrid &) Electric
                            Vehicles (FAME) schemes I and II have been widely
                            successful in strengthening electric.....{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Recommended_Reads
