import React from 'react'
import high from '../assets/imgs/battery/high.png'
import invest from '../assets/imgs/battery/invest.png'
import co2 from '../assets/imgs/battery/co2.png'
import calender from '../assets/imgs/battery/calendar.png'

const InvestTech = () => {
  return (
    <>
      <section className='invest_tech_main_section' id=''>
        <div className='container'>
          <div className='invest_tech_main_headings'>
            <h1>Invest in Clean-Tech</h1>
            <p>
              Invest in CO2-reducing products that uplift low-income
              communities.
            </p>
            <a id='RACEVault' className='section-scroll-link' href='#'></a>

            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='invest_tech_boxes'>
                  <div className='invest_tech_boxes_img'>
                    <img
                      src={high}
                      alt='img'
                      loading='lazy'
                      className='img-fluid'
                    />
                  </div>
                  <div>
                    <h2>High Returns​</h2>
                    <p>
                      Up to 25% IRR, our returns are the highest in the market.
                      ​
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='invest_tech_boxes'>
                  <div className='invest_tech_boxes_img'>
                    <img
                      src={invest}
                      alt='img'
                      loading='lazy'
                      className='img-fluid'
                    />
                  </div>
                  <div>
                    <h2>Non-Market Linked</h2>
                    <p>
                      Get guaranteed profits on RACE assets that are secure even
                      in volatile markets.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='invest_tech_boxes'>
                  <div className='invest_tech_boxes_img'>
                    <img
                      src={co2}
                      alt='img'
                      loading='lazy'
                      className='img-fluid'
                    />
                  </div>
                  <div>
                    <h2>Go Green</h2>
                    <p>
                      Helps reduce CO2 emissions and enrich auto-rickshaw
                      drivers’ lives.​
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='invest_tech_boxes'>
                  <div className='invest_tech_boxes_img'>
                    <img
                      src={calender}
                      alt='img'
                      loading='lazy'
                      className='img-fluid'
                    />
                  </div>
                  <div>
                    <h2>Monthly Payment​</h2>
                    <p>You earn monthly returns on your investment.​</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestTech
