import React, { useState, useEffect, useRef } from 'react'
import { Carousel } from 'react-bootstrap'
import fleet_slider_1 from '../assets/imgs/custom/fleet_slider_1.webp'
import fleet_slider_2 from '../assets/imgs/custom/fleet_slider_2.webp'
import fleet_slider_3 from '../assets/imgs/custom/fleet_slider_3.webp'
import fleet_slider_4 from '../assets/imgs/custom/fleet_slider_4.webp'

import fleet_mob_1 from '../assets/imgs/custom/fleet_mob_1.png'
import fleet_mob_2 from '../assets/imgs/custom/fleet_mob_2.png'
import fleet_mob_3 from '../assets/imgs/custom/fleet_mob_3.png'
import fleet_mob_4 from '../assets/imgs/custom/fleet_mob_4.png'

import btn_arrow from '../assets/imgs/arrow_btn.png'

import Dropdown from 'react-bootstrap/Dropdown'
import custom_fade_bg from '../assets/imgs/custom/custom_fade_bg.webp'
import custom_arrow from '../assets/imgs/custom/custom_arrow.webp'
import green from '../assets/imgs/custom/green.webp'
import red from '../assets/imgs/custom/red.webp'
import graph1 from '../assets/imgs/custom/graph1.webp'
import graph2 from '../assets/imgs/custom/graph2.webp'
import graph3 from '../assets/imgs/custom/graph3.webp'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

const FleetSection = () => {
  const navigate = useNavigate()

  // ===========Modal after form submit==========

  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)
  const [selectedcount, setselectedcount] = useState()
  const [Vehiclecount, SetVehiclecount] = useState()

  // ===========Hooks for form Fields==========

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    partnership_type: '',
    investment_range: '',
    investment_range_f: '',
    selectedValue_race: '',
    company_name: '',
    previous_experience: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',
    type_of_vehicle: '',
    annual_vehicle_requirement: '',
    location_of_sale: '',
    contents: '',
    hear_about_us: '',
    new_fields: '',
    page_name: ''
  })

  const {
    name,
    email,
    phone,
    partnership_type,
    investment_range,
    investment_range_f,
    hear_about_us,
    company_name,
    previous_experience,
    city,
    state,
    country,
    pincode,
    annual_vehicle_requirement,
    location_of_sale,
    contents
  } = formData

  // ===========Current page path==========

  const currentPath = window.location.pathname
  // const currentPathSlug = currentPath.substring(
  //   currentPath.lastIndexOf('/') + 1,
  // )

  const [index, setIndex] = useState(0)

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const carouselInterval = setInterval(() => {}, 5000)

    setIntervalId(carouselInterval)

    return () => clearInterval(carouselInterval)
  }, [])

  const handleMouseEnter = () => {
    clearInterval(intervalId)
  }

  const handleMouseLeave = () => {
    const newInterval = setInterval(() => {}, 5000)

    setIntervalId(newInterval)
  }

  // =============Contact us from submit==================

  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    handleShow_success()
    setFormData('')
    navigate('/thankyou')

    // if (Vehiclecount.length === 0) {
    //   handleShow_error('Please select at least one vehicle type.')
    // } else {
    //   // Form submission logic
    //   // You can submit the selected checkboxes here
    //   handleShow_error('')
    // }

    // if (
    //   name != '' &&
    //   email != '' &&
    //   phone != '' &&
    //   hearInput_string != '' &&
    //   vehicleTypeInput_string != '' &&
    //   annual_vehicle_requirement != '' &&
    //   location_of_sale != ''
    // ) {
    //   contactUsApi()
    //   handleShow_success()
    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // =============contactUs Api call==================

  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')

    formdata.append('type_of_vehicle', vehicleTypeInput_string ?? '')
    formdata.append(
      'annual_vehicle_requirement',
      annual_vehicle_requirement ?? ''
    )
    formdata.append('location_of_sale', location_of_sale ?? '')
    formdata.append('hear_about_us', hearInput_string ?? '')
    formdata.append('page_name', currentPath ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // =============Hear about us==================

  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  // =============Vehicle Type==================

  const [vehicleTypeInput, setvehicleTypeInput] = useState([])
  const vehicleTypeInput_string = vehicleTypeInput.join(', ')

  const handleChangeVehicleType = e => {
    let VehicleTypeValue = [...vehicleTypeInput]

    if (e.target.checked) {
      console.log(e.target.value)
      VehicleTypeValue = [...vehicleTypeInput, e.target.value]
      if (VehicleTypeValue) {
        setvehicleTypeInput(VehicleTypeValue)
        const vehicleCount = VehicleTypeValue.length
        SetVehiclecount(vehicleCount)
      }
    } else {
      VehicleTypeValue.splice(vehicleTypeInput.indexOf(e.target.value), 1) // araay list
      setvehicleTypeInput(VehicleTypeValue)
      const vehicleCount = VehicleTypeValue.length
      SetVehiclecount(vehicleCount)
    }
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <>
      <section className='fleet_main_section'>
        <img
          src={custom_fade_bg}
          alt='img'
          loading='lazy'
          className='img-fluid custom_fade_bg'
        />
        <img
          src={custom_arrow}
          alt='img'
          loading='lazy'
          className='img-fluid custom_arrow'
        />
        <div className='container'>
          <div className='fleet_main_section_headings'>
            <h1>Fleet! We Have Got You Covered</h1>
            <p>
              Move any of your EVs to our swappable platform and never worry
              about the battery again. ​
            </p>
          </div>

          {/* slider */}
          <section className='tabs_slider_main_section'>
            <Carousel
              fade
              prevIcon=<button
                disabled={index == 0}
                className=' back_btn'
              ></button>
              nextIcon=<button
                disabled={index == 3}
                className='next_btn'
              ></button>
              onSelect={handleSelect}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Carousel.Item>
                <img
                  src={fleet_slider_1}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={fleet_mob_1}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />

                <Carousel.Caption className='carousel_caption'>
                  <h3>No Downtime for Charging​</h3>
                  <p>
                    With every battery being swapped in 4 seconds, your vehicles
                    will always generate revenue instead of staying stationary
                    charging. ​
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={fleet_slider_2}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={fleet_mob_2}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />
                <Carousel.Caption className='carousel_caption'>
                  <h3>Reduce Upfront Cost   </h3>
                  <p>
                    Battery investment is on us. You never have to worry about
                    picking the right battery. ​
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={fleet_slider_3}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={fleet_mob_3}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />
                <Carousel.Caption className='carousel_caption'>
                  <h3>Driver Profiling & Geofencing ​</h3>
                  <p>
                    Have full control over your fleet by monitoring driver
                    performance, planning optimal routes, and ensuring they are
                    always on the right route.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={fleet_slider_4}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={fleet_mob_4}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />

                <Carousel.Caption className='carousel_caption'>
                  <h3>Scale Rapidly ​</h3>
                  <p>
                    Scale your fleet business by switching to RACE battery swap
                    setup, reduce upfront vehicle cost and earn higher profits.{' '}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </section>

          {/* table section */}
          <section className='graph_table_section_main'>
            <div className='row'>
              <div className='col-lg-5 col-md-12 col-sm-12 order-2 order-lg-1  '>
                <div className='graph_table_first_col_content_side'>
                  <h1 className='Earn_Revenue_desktop'>
                    Earn Revenue All Day With Our Fleet Solutions​
                  </h1>
                  <div className='main_para'>
                    <p>
                      On a average revenue loss of 3-wheeler vehicle per hour is
                      Rs. 150/-
                    </p>
                  </div>
                  <div className='first_col_content_side_bottom_main'>
                    <div className='first_col_content_side_bottom_single'>
                      <h3>RACE fleet solutions </h3>
                      <div>
                        <p>
                          4 seconds swap per battery change Per day total
                          revenue loss{' '}
                        </p>
                        <p className='color_green'>0hr * Rs. 150 = No loss</p>
                      </div>
                    </div>
                    <div className='first_col_content_side_bottom_single border_top_bottom'>
                      <h3>Fast charging fleet setup </h3>
                      <div>
                        <p>
                          Average charging 2 hrs (time loss) Per day total
                          revenue loss
                        </p>
                        <p className='color_red'>4hr * Rs. 150 = Rs. 600/- </p>
                      </div>
                    </div>
                    <div className='first_col_content_side_bottom_single'>
                      <h3>Slow charging fleet setup </h3>
                      <div>
                        <p>
                          Average charging 5 hrs (time loss) Per day total
                          revenue loss
                        </p>
                        <p className='color_red'>
                          10hr * Rs. 150 = Rs. 1500/-{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 col-md-12 col-sm-12 order-1 order-lg-2  fleet_graph_hrs_div_col'>
                <h1 className='Earn_Revenue_mobile'>
                  Earn Revenue All Day With Our Fleet Solutions​
                </h1>
                <div className='fleet_graph_div_main'>
                  <div className='graph_indicators_main'>
                    <div className='graph_indicators_single'>
                      <div className='graph_dot'></div>
                      <h5>Vechile on road</h5>
                    </div>
                    <div className='graph_indicators_single'>
                      <div className='graph_dot graph_dot_2'></div>
                      <h5>Time lost in charging </h5>
                    </div>
                  </div>
                  <div className='fleet_graph_hrs_div_main'>
                    <div className='fleet_graph_hrs_div_single'>
                      <h4>0 hrs</h4>
                    </div>
                    <div className='fleet_graph_hrs_div_single'>
                      <h4>03 hrs</h4>
                    </div>
                    <div className='fleet_graph_hrs_div_single'>
                      <h4>6 hrs</h4>
                    </div>
                    <div className='fleet_graph_hrs_div_single'>
                      <h4>12 hrs</h4>
                    </div>
                    <div className='fleet_graph_hrs_div_single'>
                      <h4>24 hrs</h4>
                    </div>
                  </div>

                  <div className='fleet_graph_cols_div_main'>
                    <div className=''>
                      <div className='fleet_graph_cols_div_single'>
                        <div>
                          <img src={green} className='indicator_img' />
                          <h3>0 Hrs </h3>
                          <h2>No time loss</h2>
                        </div>
                        <img src={graph1} className='indicator_big_img' />
                      </div>
                      <h1 className='fleet_graph_cols_div_single_bottom_text'>
                        RACE fleet <br /> solutions{' '}
                      </h1>
                    </div>
                    <div className=''>
                      <div className='fleet_graph_cols_div_single'>
                        <div>
                          <div className='fleet_graph_cols_div_single_bg_change'></div>
                          <img src={red} className='indicator_img' />
                          <h3 style={{ color: '#fff' }}>2 Hrs </h3>
                          <h2 style={{ color: '#fff' }}>Avg. time loss</h2>
                        </div>
                        <img src={graph2} className='indicator_big_img' />
                      </div>
                      <h1 className='fleet_graph_cols_div_single_bottom_text'>
                        Fast charging <br /> fleet setup{' '}
                      </h1>
                    </div>
                    <div>
                      <div className='fleet_graph_cols_div_single'>
                        <div>
                          <div
                            className='fleet_graph_cols_div_single_bg_change fleet_graph_cols_div_single_bg_change_2'
                            style={{ height: '184px' }}
                          ></div>
                          <img src={red} className='indicator_img' />
                          <h3 style={{ color: '#fff' }}>5 Hrs </h3>
                          <h2 style={{ color: '#fff' }}>Avg. time loss</h2>
                        </div>
                        <img src={graph3} className='indicator_big_img' />
                      </div>
                      <h1 className='fleet_graph_cols_div_single_bottom_text'>
                        Slow charging <br /> fleet setup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* form section */}
          <section className='fleet_form_section_main'>
            <div className='fleet_form_section_main_headings'>
              <h4>We Are Here to Support Your Fleet </h4>
              <p>
                Upgrade your fleet business with RACE battery set-up and save
                vehicle cost and daily charge time.
              </p>
            </div>
            <section className='Battery_Form_main_section'>
              <div className=''>
                <section className='invest_form_main_border'>
                  <div className='invest_form_main_box'>
                    <h3>
                      Subject: Hey! I am interested in RACE fleet solutions.🙂
                    </h3>
                    <form
                      className='footprint_form'
                      onSubmit={Contactus_from_submit}
                      autoComplete='off'
                    >
                      <div className='row flex_end_content'>
                        <div className='col-lg-10 col-md-12 col-sm-12'>
                          <div className='row'>
                            {/* name */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='name'
                                  value={formData.name ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Name*
                                </label>
                              </div>
                            </div>
                            {/* email */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='email'
                                  placeholder=' '
                                  name='email'
                                  value={formData.email ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Email Id*
                                </label>
                              </div>
                            </div>
                            {/* phone number */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='number'
                                  placeholder=' '
                                  name='phone'
                                  value={formData.phone ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Phone number*
                                </label>
                              </div>
                            </div>

                            {/* Where did you .... */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <Dropdown className='custom_drop_down'>
                                  <Dropdown.Toggle
                                    variant=''
                                    className='custom_drop_down_btn'
                                    id='dropdown-basic'
                                  >
                                    {selectedcount ? (
                                      <span> {selectedcount} Selected</span>
                                    ) : (
                                      <span>Where did you hear about us?</span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Search Engine (Google, Bing, etc.)'
                                        id='flexCheckChecked1'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked1'
                                      >
                                        Search Engine (Google, Bing, etc.)
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Recommended by a Friend/Colleague'
                                        id='flexCheckChecked2'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked2'
                                      >
                                        Recommended by a Friend/Colleague
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Social Media (LinkedIn, Twitter)'
                                        id='flexCheckChecked3'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked3'
                                      >
                                        Social Media (LinkedIn, Twitter)
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Blog/Publication'
                                        id='flexCheckChecked4'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked4'
                                      >
                                        Blog/Publication
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Signage'
                                        id='flexCheckChecked5'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked5'
                                      >
                                        Signage
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Networking Event'
                                        id='flexCheckChecked6'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked6'
                                      >
                                        Networking Event
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Startup Founder'
                                        id='flexCheckChecked7'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked7'
                                      >
                                        Startup Founder
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Word of Mouth'
                                        id='flexCheckChecked8'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked8'
                                      >
                                        Word of Mouth
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Others'
                                        id='flexCheckChecked9'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked9'
                                      >
                                        Others
                                      </label>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h4>RACE Fleet Solutions </h4>
                          </div>

                          <div className='row'>
                            {/* Type of Vehicle* */}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                              <Dropdown className='custom_drop_down mb_24'>
                                <Dropdown.Toggle
                                  variant=''
                                  className='custom_drop_down_btn'
                                  id='dropdown-basic_Type_Vehicle'
                                  style={{ marginTop: '3px' }}
                                >
                                  {Vehiclecount ? (
                                    <span> {Vehiclecount} Selected</span>
                                  ) : (
                                    <span>Type of Vehicle*</span>
                                  )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='Low speed E-2-Wheeler'
                                      id='flexCheckChecked11'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked11'
                                    >
                                      Low speed E-2-Wheeler
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='High speed E-2-Wheeler'
                                      id='flexCheckChecked22'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked22'
                                    >
                                      High speed E-2-Wheeler
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='E-Rickshaw'
                                      id='flexCheckChecked33'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked33'
                                    >
                                      E-Rickshaw
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='E-Auto Passenger'
                                      id='flexCheckChecked44'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked44'
                                    >
                                      E-Auto Passenger
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='E-Auto Cargo'
                                      id='flexCheckChecked55'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked55'
                                    >
                                      E-Auto Cargo
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='IC Engine 3-Wheeler'
                                      id='flexCheckChecked66'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked66'
                                    >
                                      IC Engine 3-Wheeler
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value='Others'
                                      id='flexCheckChecked77'
                                      onClick={handleChangeVehicleType}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked77'
                                    >
                                      Others
                                    </label>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            {/* Annual Vehicle Requirement  */}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='annual_vehicle_requirement'
                                  value={
                                    formData.annual_vehicle_requirement ?? ''
                                  }
                                  onChange={onChange}
                                />
                                <label className='did-floating-label'>
                                  Annual Vehicle Requirement
                                </label>
                              </div>
                            </div>

                            {/* Location of Sale/ Market/ Demand */}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                              <div className='did-floating-label-content mb_24'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='location_of_sale'
                                  value={formData.location_of_sale ?? ''}
                                  onChange={onChange}
                                />
                                <label className='did-floating-label'>
                                  Location of Sale/ Market/ Demand
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-2 col-md-12 col-sm-12'>
                          <div className='form_submit_btn'>
                            <button className='' type='submit'>
                              Submit{' '}
                              <img
                                src={btn_arrow}
                                className='learn_arrow'
                                alt='arrow'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </section>
          </section>
        </div>
      </section>
      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </>
  )
}

export default FleetSection
