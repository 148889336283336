import React, { useState } from 'react'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import footer_bg from '../assets/imgs/footer-bg.webp'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

const BatteryForm = () => {
  const navigate = useNavigate()

  const [selectedcount, setselectedcount] = useState()

  // ===========Modal after form submit==========

  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)

  // ===========Hooks for form Fields==========

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    partnership_type: '',
    investment_range: '',
    investment_range_f: '',
    selectedValue_race: '',
    company_name: '',
    previous_experience: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',
    type_of_vehicle: '',
    annual_vehicle_requirement: '',
    location_of_sale: '',
    contents: '',
    hear_about_us: '',
    new_fields: '',
    page_name: ''
  })

  const {
    name,
    email,
    phone,
    partnership_type,
    investment_range,
    investment_range_f,
    hear_about_us,
    company_name,
    previous_experience,
    city,
    state,
    country,
    pincode,
    annual_vehicle_requirement,
    location_of_sale,
    contents
  } = formData

  // ===========Current page path==========

  const currentPath = window.location.pathname
  const currentPathSlug = currentPath.substring(
    currentPath.lastIndexOf('/') + 1
  )

  const [selectedValue_investment, setSelectedValue_investment] = useState('')
  const [labelPosition, setLabelPosition] = useState('')

  const handleSelectChange_investment = event => {
    setSelectedValue_investment(event.target.value)
    setLabelPosition(event.target.value ? 'top' : '')
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  // =============Contact us from submit==================

  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    setFormData('')
    navigate('/thankyou')
    // if (
    //   name != '' &&
    //   email != '' &&
    //   phone != '' &&
    //   hearInput_string != '' &&
    //   selectedValue_investment != '' &&
    //   company_name != '' &&
    //   city != '' &&
    //   state != '' &&
    //   country != '' &&
    //   pincode != ''
    // ) {
    //   contactUsApi()
    //   handleShow_success()
    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // =============contactUs Api call==================

  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')
    formdata.append('investment_range', selectedValue_investment ?? '')
    formdata.append('company_name', company_name ?? '')
    formdata.append('city', city ?? '')
    formdata.append('state', state ?? '')
    formdata.append('country', country ?? '')
    formdata.append('pincode', pincode ?? '')
    formdata.append('hear_about_us', hearInput_string ?? '')
    formdata.append('page_name', currentPathSlug ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // =============Hear about us==================

  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  return (
    <>
      <section className='Battery_Form_main_section'>
        <img
          src={footer_bg}
          alt='img'
          loading='lazy'
          className='img-fluid footer_bg'
        />
        <div className='container'>
          <section className='invest_form_main_border'>
            <div className='invest_form_main_box'>
              <h3>
                Subject : Hey! I am interested in RACE Vault investment.😊
              </h3>
              <form
                className='footprint_form'
                onSubmit={Contactus_from_submit}
                autoComplete='off'
              >
                <div className='row'>
                  <div className='col-lg-10'>
                    <div className='row'>
                      {/* name */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='text'
                            placeholder=' '
                            name='name'
                            value={formData.name ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>Name*</label>
                        </div>
                      </div>
                      {/* email */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='email'
                            placeholder=' '
                            name='email'
                            value={formData.email ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            Email Id*
                          </label>
                        </div>
                      </div>
                      {/* phone number */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <input
                            className='did-floating-input'
                            type='number'
                            placeholder=' '
                            name='phone'
                            value={formData.phone ?? ''}
                            onChange={onChange}
                            required
                          />
                          <label className='did-floating-label'>
                            Phone number*
                          </label>
                        </div>
                      </div>

                      {/* Where did you .... */}
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='did-floating-label-content mb_48'>
                          <Dropdown className='custom_drop_down'>
                            <Dropdown.Toggle
                              variant=''
                              className='custom_drop_down_btn'
                              id='dropdown-basic'
                            >
                              {selectedcount ? (
                                <span> {selectedcount} Selected</span>
                              ) : (
                                <span>Where did you hear about us?</span>
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Search Engine (Google, Bing, etc.)'
                                  id='flexCheckChecked1'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked1'
                                >
                                  Search Engine (Google, Bing, etc.)
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Recommended by a Friend/Colleague'
                                  id='flexCheckChecked2'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked2'
                                >
                                  Recommended by a Friend/Colleague
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Social Media (LinkedIn, Twitter)'
                                  id='flexCheckChecked3'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked3'
                                >
                                  Social Media (LinkedIn, Twitter)
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Blog/Publication'
                                  id='flexCheckChecked4'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked4'
                                >
                                  Blog/Publication
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Signage'
                                  id='flexCheckChecked5'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked5'
                                >
                                  Signage
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Networking Event'
                                  id='flexCheckChecked6'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked6'
                                >
                                  Networking Event
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Startup Founder'
                                  id='flexCheckChecked7'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked7'
                                >
                                  Startup Founder
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Word of Mouth'
                                  id='flexCheckChecked8'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked8'
                                >
                                  Word of Mouth
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='hearAboutUs'
                                  value='Others'
                                  id='flexCheckChecked9'
                                  onClick={handleChangeHearAbout}
                                />
                                <label
                                  className='form-check-label'
                                  for='flexCheckChecked9'
                                >
                                  Others
                                </label>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* small heading */}
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <h4 className=''>RACE Vault</h4>
                      </div>

                      <section>
                        <div className=''>
                          <div className='form_radio_buttons'>
                            {/* tab content */}
                            <div className=''>
                              {/* Area partner */}
                              <div className='new_form_tabs_content_main_single'>
                                <div className='row'>
                                  {/* investment range */}
                                  <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='select-container mb_48'>
                                      <label
                                        className={`select-label ${labelPosition}`}
                                      >
                                        Investment range*
                                      </label>
                                      <select
                                        className='new_select__box'
                                        value={selectedValue_investment}
                                        onChange={handleSelectChange_investment}
                                        required
                                      >
                                        <option value=''></option>
                                        <option value='Less than 1 Lakh'>
                                          Less than 1 Lakh
                                        </option>
                                        <option value='1- 10 Lakh'>
                                          1- 10 Lakh
                                        </option>
                                        <option value='10 Lakh- 1 Cr'>
                                          10 Lakh- 1 Cr
                                        </option>
                                        <option value='More than 1 Cr'>
                                          More than 1 Cr
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  {/* company name */}
                                  <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='did-floating-label-content mb_48'>
                                      <input
                                        className='did-floating-input'
                                        type='text'
                                        placeholder=' '
                                        name='company_name'
                                        value={formData.company_name ?? ''}
                                        onChange={onChange}
                                      />
                                      <label className='did-floating-label'>
                                        Company Name
                                      </label>
                                    </div>
                                  </div>

                                  {/* City */}
                                  <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='did-floating-label-content mb_24'>
                                      <input
                                        className='did-floating-input'
                                        type='text'
                                        placeholder=' '
                                        name='city'
                                        value={formData.city ?? ''}
                                        onChange={onChange}
                                      />
                                      <label className='did-floating-label'>
                                        City
                                      </label>
                                    </div>
                                  </div>
                                  {/* State */}
                                  <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='did-floating-label-content mb_24'>
                                      <input
                                        className='did-floating-input'
                                        type='text'
                                        placeholder=' '
                                        name='state'
                                        value={formData.state ?? ''}
                                        onChange={onChange}
                                      />
                                      <label className='did-floating-label'>
                                        State
                                      </label>
                                    </div>
                                  </div>
                                  {/* Country */}
                                  <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='did-floating-label-content mb_24'>
                                      <input
                                        className='did-floating-input'
                                        type='text'
                                        placeholder=' '
                                        name='country'
                                        value={formData.country ?? ''}
                                        onChange={onChange}
                                      />
                                      <label className='did-floating-label'>
                                        Country
                                      </label>
                                    </div>
                                  </div>
                                  {/* Pincode */}
                                  <div className='col-lg-3 col-md-6 col-sm-12'>
                                    <div className='did-floating-label-content mb_24'>
                                      <input
                                        className='did-floating-input'
                                        type='text'
                                        placeholder=' '
                                        name='pincode'
                                        value={formData.pincode ?? ''}
                                        onChange={onChange}
                                      />
                                      <label className='did-floating-label'>
                                        Pincode
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='form_submit_btn form_submit_btn_flex_end'>
                      <button className='' type='submit'>
                        Submit{' '}
                        <img
                          src={btn_arrow}
                          className='learn_arrow'
                          alt='arrow'
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </>
  )
}

export default BatteryForm
