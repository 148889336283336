import React from 'react'

import FeaturedVideos_bg from '../assets/imgs/partners-bg.webp'
import { Link } from 'react-router-dom'

const FeaturedVideos = () => {
  return (
    <>
      <section className="FeaturedVideos_main_section">
        <img
          src={FeaturedVideos_bg}
          className="img-fluid FeaturedVideos_bg"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="FeaturedVideos_main_section_headings">
            <h1>Featured Videos</h1>
          </div>
          <section className="FeaturedVideos_main_display_none">
            <div className="row ">
              <div className="col-12">
                <div>
                  <iframe
                    width="100%"
                    height="610"
                    style={{ borderRadius: '5px' }}
                    src="https://www.youtube.com/embed/3I687-mdEi4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div className="news_content_left_side_content_main">
                    <h4>Electric Motor News</h4>
                    <h2>
                      <Link to="https://www.youtube.com/watch?v=mlmVRxJ7viA&ab_channel=electricmotornews">
                        Swapping batteries systems for tuk-tuks - Electric Motor
                        News n° 3 (2023){' '}
                      </Link>
                    </h2>
                    <div className="news_by_and_date_div">
                      <span className="news_color">Video |</span>
                      <span className="news_by">by motornewsitaly |</span>
                      <span>Feb 18, 2023 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default FeaturedVideos
