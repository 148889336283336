import React, { useRef } from 'react'
import out_story_gif from '../assets/imgs/About/about-out-story.gif'
import about_arrow from '../assets/imgs/about-arrow.webp'

const OurStory = () => {
  const ServicesRef = useRef(null)
  const gotoServices = () =>
    window.scrollTo({
      top: ServicesRef.current.offsetTop,
      behavior: 'smooth',
      // You can also assign value "auto"
      // to the behavior parameter.
    })
  return (
    <>
      <section className="Our_Story_main_section">
        <a id="OurStory" className="section-scroll-link" href="#"></a>
        <img
          src={about_arrow}
          className="img-fluid about_arrow"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="row ">
            <div className="col-lg-1 col-md-1 col-sm-12 "></div>
            <div className="col-11 col-lg-10 col-md-12 m-auto">
              <div className="row our_story_container">
                <div className="col-lg-6 col-md-12 col-sm-12 our_story_img">
                  <img
                    src={out_story_gif}
                    className="img-fluid"
                    alt="img"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="our_story_content_side">
                    <h1>Our Story</h1>
                    <p>
                      RACE is a deep-tech battery swapping company, building
                      advanced swappable batteries and smart swapping network
                      for use in across all segments of EVs.
                    </p>

                    <p>
                      Founded in 2019 in Hyderabad by Arun and Gautham, RACE has
                      a grand vision of powering the world’s largest battery
                      swapping network. The founders have the DNA of motorsports
                      and automotive engineering, having started building
                      products at the age of 17 and have a knack for bringing
                      futuristic technology into effective implementation.
                    </p>

                    <p>
                      With extensive and uncompromising R&D, we are building
                      highly robust, safe and advanced swapping technology, that
                      is addressing a multitude of problems that fixed-battery
                      EVs are facing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurStory
