import React from 'react'
import NetworkHero from './NetworkHero'
import Map from './Map'
import Features from './Features'
import Host from './Host'

const NetworkMain = () => {
  return (
    <>
      <NetworkHero />
      <Map />
      <Features />

      <Host />
    </>
  )
}

export default NetworkMain
