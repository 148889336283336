import React, { useState } from 'react'
import footer_bg from '../assets/imgs/footer-bg.webp'
import partner_bg2 from '../assets/imgs/partner-bg2.webp'
import Dropdown from 'react-bootstrap/Dropdown'
import TextField from '@mui/material/TextField'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import CommonForm from '../CommonForm'

const BecomeourPartner = () => {
  const [radioInput, setradioInput] = useState('area-partner')

  const handleChange = (e) => {
    console.log(e.target.value)
    setradioInput(e.target.value)
  }
  return (
    <>
      <a id="become-partner" className="section-scroll-link" href="#"></a>

      <section className="Become_our_Partner_main_section" id="">
        <img
          src={footer_bg}
          className="img-fluid footer_bg"
          alt="img"
          loading="lazy"
        />
        <img
          src={partner_bg2}
          className="img-fluid partner_bg2"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="Become_our_Partner_main_box_border">
            <div className="Become_our_Partner_main_box">
              <div className="">
                <h1>Become Our Partner</h1>
                <p>
                  Fill in the form if you are interested in any of our
                  partnership models.{' '}
                </p>
              </div>

              <div>
                <CommonForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BecomeourPartner
