import React, { useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import left_arrow from '../assets/imgs/left-arrow.webp'
import halfround from '../assets/imgs/half-round.webp'
import video1 from '../assets/imgs/quick.webm'
import video2 from '../assets/imgs/Infinite.webm'
import video3 from '../assets/imgs/Battery.webm'
import video4 from '../assets/imgs/best-battery.webm'
import { Link } from 'react-router-dom'
import btn_arrow_blue from '../assets/imgs/btn_arrow_blue.png'

const OneStopSolution = () => {
  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 6000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <>
      <section className='One_stop_solution_section' id='onestopsolution'>
        <div className='One_stop_solution_bg_img'>
          <img
            src={left_arrow}
            className='img-fluid one'
            alt='img'
            loading='lazy'
          />
          <img
            src={halfround}
            className='img-fluid two'
            alt='img'
            loading='lazy'
          />
        </div>
        <div className='container'>
          <h1>One stop solution</h1>
          <Carousel ref={carouselRef} fade>
            <Carousel.Item interval={6000}>
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <video autoPlay muted width='100%' height='100%' loop>
                    <source src={video1} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='One_stop_solution_content'>
                    <div className='One_stop_solution_content_small_box'>
                      <h3> Swap Station</h3>
                    </div>
                    <h2>Quick as a Wink</h2>
                    <p>
                      0-100% in four seconds. ​ Quicker than fast, flash, rapid,
                      hyper, super, warp etc. charging​
                    </p>
                    <Link to='/network' className='Learn_More_btn'>
                      Learn More{' '}
                      <img
                        src={btn_arrow_blue}
                        className='learn_arrow'
                        alt='arrow'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={6000}>
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <video autoPlay muted width='100%' height='100%' loop>
                    <source src={video2} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='One_stop_solution_content'>
                    <h2>Infinite Range​</h2>
                    <p>
                      Mimic your petrol experience and be on the road always.​
                      Drive at optimal speed and cover high distance range with
                      RACE EV setup.
                    </p>
                    <Link to='/vehicle-solution' className='Learn_More_btn'>
                      Learn More{' '}
                      <img
                        src={btn_arrow_blue}
                        className='learn_arrow'
                        alt='arrow'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item interval={6000}>
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <video autoPlay muted width='100%' height='100%' loop>
                    <source src={video3} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='One_stop_solution_content'>
                    <h2>Battery Headache on Us</h2>
                    <p>
                      Focus on the driving, not the maintenance. ​ Never have to
                      pay for a battery replacement. Ever.​
                    </p>
                    <Link to='/vehicle-solution' className='Learn_More_btn'>
                      Learn More{' '}
                      <img
                        src={btn_arrow_blue}
                        className='learn_arrow'
                        alt='arrow'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item interval={6000}>
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <video autoPlay muted width='100%' height='100%' loop>
                    <source src={video4} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='One_stop_solution_content'>
                    <div className='One_stop_solution_content_small_box'>
                      <h3> Battery Pack</h3>
                    </div>
                    <h2>Best Battery Always</h2>
                    <p>
                      Not only does the software in your EV evolve, but so does
                      the battery. Have the most up-to-date battery technology
                      in your vehicle with RACE.
                    </p>
                    <Link to='/battery' className='Learn_More_btn'>
                      Learn More{' '}
                      <img
                        src={btn_arrow_blue}
                        className='learn_arrow'
                        alt='arrow'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default OneStopSolution
