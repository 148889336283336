import React from 'react'
import feature1 from '../assets/imgs/network/feature1.png'
import feature2 from '../assets/imgs/network/feature2.png'
import feature3 from '../assets/imgs/network/feature3.png'
import feature4 from '../assets/imgs/network/feature4.png'
import feature5 from '../assets/imgs/network/feature5.png'
import feature6 from '../assets/imgs/network/feature6.png'
import feature7 from '../assets/imgs/network/feature7.png'
import feature8 from '../assets/imgs/network/feature8.png'
import feature9 from '../assets/imgs/network/feature9.png'
import feature10 from '../assets/imgs/network/feature10.png'
import feature11 from '../assets/imgs/network/feature11.png'
import features_arrow from '../assets/imgs/network/features-arrow.webp'
import features_ring from '../assets/imgs/network/features-ring.webp'
import grid from '../assets/imgs/network/grid.webp'
import swap from '../assets/imgs/network/swap.webp'
import network from '../assets/imgs/network/Network.webp'

const Features = () => {
  return (
    <>
      <a id="Features" className="section-scroll-link" href="#"></a>

      <section className="features_main_section overflow_hidden" id="">
        <img
          src={features_arrow}
          alt="img"
          loading="lazy"
          className="img-fluid features_arrow"
        />
        <img
          src={features_ring}
          alt="img"
          loading="lazy"
          className="img-fluid features_ring"
        />

        <div className="container">
          <div className="features_main_headings">
            <h1>Features You've Got</h1>
          </div>
          <div className="row">
            <div className="col-lg-10 m-auto">
              {/* feature1 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col">
                    <h1 className="left_side">01</h1>
                    <img
                      src={feature1}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_1_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={grid}
                      style={{ width: '57px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid grid_img"
                    />

                    <h2>Charge in Low Demand Periods for Use in Peak Hours</h2>
                    <p>
                      Optimize power consumption at your swap station by slow
                      charging batteries in the night and keep them ready for
                      utilisation at peak hours, reducing peak demand on the
                      grid and increasing battery life.
                    </p>
                  </div>
                </div>
              </div>

              {/* feature2 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12  order-2 order-lg-1 ">
                  <div className="features_content_col features_content_col_left features_content_col_main">
                    <img
                      src={grid}
                      style={{ width: '57px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid grid_img"
                    />
                    <h2>Differential Charging to Optimise Costs</h2>
                    <p>
                      RACE swap stations facilitate optimizing charging time and
                      cost as per differential charging rates in the day. This
                      aids in cost-efficient and effective control of power and
                      battery supply.{' '}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12  order-1 order-lg-2">
                  <div className="features_img_col ">
                    <h1 className="right_side">02</h1>
                    <img
                      src={feature2}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_2_img"
                    />
                  </div>
                </div>
              </div>

              {/* feature3 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col">
                    <h1 className="left_side">03</h1>
                    <img
                      src={feature3}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_3_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={grid}
                      style={{ width: '57px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid grid_img"
                    />
                    <h2>Data Driven Network Expansion Engine</h2>
                    <p>
                      RACE smart analytics can provide density indicators for
                      any geographic location demonstrating scope of expansion
                      for new swap stations based on demand analysis.
                    </p>
                  </div>
                </div>
              </div>

              {/* feature4 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12 order-2 order-lg-1 ">
                  <div className="features_content_col features_content_col_left features_content_col_main">
                    <img
                      src={grid}
                      style={{ width: '57px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid grid_img"
                    />
                    <h2>Variable Charging Protocol</h2>
                    <p>
                      RACE swap stations run smart charging algorithms that
                      decide charging rates based on demand for swaps and shift
                      to slow charging to conserve battery life in low demand
                      periods.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 order-1 order-lg-2">
                  <div className="features_img_col features_img_four">
                    <h1 className="right_side">04</h1>
                    <img
                      src={feature4}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_4_img"
                    />
                  </div>
                </div>
              </div>

              {/* feature5 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col">
                    <h1 className="left_side">05</h1>
                    <img
                      src={feature5}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_5_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={grid}
                      style={{ width: '57px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid grid_img"
                    />
                    <h2>Swap Demand Prediction Models</h2>
                    <p>
                      Based on the swap cycles we predict the demand for RACE
                      batteries in any given location. All smart swap stations
                      are connected as a network that shares information about
                      peak time & energy demand.
                    </p>
                  </div>
                </div>
              </div>

              {/* feature6 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12 order-2 order-lg-1 ">
                  <div className="features_content_col features_content_col_left features_content_col_main">
                    <img
                      src={network}
                      style={{ width: '113px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid network_img"
                    />
                    <h2>
                      Entire Battery Ecosystem Controlled Individually and
                      Uniquely
                    </h2>
                    <p>
                      All RACE swap stations are cloud-connected, allowing for
                      centralised and efficient management of all batteries
                      based on insights from charging rates, thermal performance
                      and increasing efficiency levels.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 order-1 order-lg-2">
                  <div className="features_img_col features_img_six">
                    <h1 className="right_side">06</h1>
                    <img
                      src={feature6}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_6_img"
                    />
                  </div>
                </div>
              </div>

              {/* feature7 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col features_img_seven">
                    <h1 className="left_side">07</h1>
                    <img
                      src={feature7}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_7_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={network}
                      style={{ width: '113px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid network_img"
                    />
                    <h2>Swap The Best Batteries Always</h2>
                    <p>
                      RACE Clustering Algorithms run at all smart stations to
                      identify the best set of identical batteries with similar
                      usage and performance levels to be given out for swaps
                      ensuring the highest operating efficiency.
                    </p>
                  </div>
                </div>
              </div>

              {/* feature8 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12 order-2 order-lg-1 ">
                  <div className="features_content_col features_content_col_left features_content_col_main">
                    <img
                      src={swap}
                      style={{ width: '178px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid swap_img"
                    />
                    <h2>Stacks Like Building Blocks</h2>
                    <p>
                      RACE swap stations can be modularly stacked in numbers to
                      cater to increasing demand. The simple to set up design
                      allows for scale with ease and power entire localities
                      swiftly.{' '}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 order-1 order-lg-2">
                  <div className="features_img_col features_img_eight">
                    <h1 className="right_side">08</h1>
                    <img
                      src={feature8}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_8_img"
                    />
                  </div>
                </div>
              </div>

              {/* feature9 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col features_img_nine">
                    <h1 className="left_side">09</h1>
                    <img
                      src={feature9}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_9_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={swap}
                      style={{ width: '178px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid swap_img"
                    />
                    <h2>Fully Automated with Integrated Payment System</h2>
                    <p>
                      Simply swap a charged battery for a drained one, then
                      utilise one of the smart payment options available to get
                      back on the road faster.{' '}
                    </p>
                  </div>
                </div>
              </div>

              {/* feature10 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12 order-2 order-lg-1 ">
                  <div className="features_content_col features_content_col_left features_content_col_main">
                    <img
                      src={swap}
                      style={{ width: '178px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid swap_img"
                    />
                    <h2>Fast Charging Integrated Into Swapping</h2>
                    <p>
                      RACE swap stations can be modularly stacked in numbers to
                      cater to increasing demand. The simple to set up design
                      allows for scale with ease and power entire localities
                      swiftly{' '}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 order-1 order-lg-2">
                  <div className="features_img_col features_img_ten">
                    <h1 className="right_side">10</h1>
                    <img
                      src={feature10}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_10_img"
                    />
                  </div>
                </div>
              </div>

              {/* feature11 */}
              <div className="row features_content_row">
                <div className="col-lg-6 co-md-6 col-sm-12">
                  <div className="features_img_col features_img_eleven">
                    <h1 className="left_side left_side_eleven">11</h1>
                    <img
                      src={feature11}
                      alt="img"
                      loading="lazy"
                      className="img-fluid feature_11_img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 co-md-6 col-sm-12 ">
                  <div className="features_content_col features_content_col_right features_content_col_main">
                    <img
                      src={swap}
                      style={{ width: '178px', marginBottom: '8px' }}
                      alt="img"
                      loading="lazy"
                      className="img-fluid swap_img"
                    />
                    <h2>Temperature Controlled Charging Protocol</h2>
                    <p>
                      RACE batteries have integrated internal cooling mechanisms
                      that keep the temperature of the battery close to 25 deg C
                      at all times to obtain maximum performance and extend
                      battery usage life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
