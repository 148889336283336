import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import btn_arrow from './assets/imgs/arrow_btn.png'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
const CommonForm = () => {
  const navigate = useNavigate()
  // ============================================
  // ===========Hooks for form Fields==========
  // ============================================
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    partnership_type: '',
    investment_range: '',
    investment_range_f: '',
    selectedValue_race: '',
    company_name: '',
    previous_experience: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',
    type_of_vehicle: '',
    annual_vehicle_requirement: '',
    location_of_sale: '',
    contents: '',
    hear_about_us: '',
    new_fields: '',
    page_name: '',
    other_partnership: ''
  })

  const {
    name,
    email,
    phone,
    partnership_type,
    investment_range,
    investment_range_f,
    hear_about_us,
    company_name,
    previous_experience,
    city,
    state,
    country,
    pincode,
    annual_vehicle_requirement,
    location_of_sale,
    contents,
    other_partnership
  } = formData

  const [selectedcount, setselectedcount] = useState()
  const [Vehiclecount, SetVehiclecount] = useState()

  // ============================================
  // ===========Current page path==========
  // ============================================
  const currentPath = window.location.pathname
  const currentPathSlug = currentPath.substring(
    currentPath.lastIndexOf('/') + 1
  )

  const [radioInput, setradioInput] = useState('area-partner')

  const [selectedValue_investment, setSelectedValue_investment] = useState('')
  const [labelPosition, setLabelPosition] = useState('')

  const [selectedValue_power, setSelectedValue_power] = useState('')
  const [labelPosition_power, setLabelPosition_power] = useState('')

  const [selectedValue_space, setSelectedValue_space] = useState('')
  const [labelPosition_space, setLabelPosition_space] = useState('')

  const [selectedValue_investment_f, setSelectedValue_investment_f] =
    useState('')
  const [labelPosition_investment_f, setlabelPosition_investment_f] =
    useState('')

  const [selectedValue_race, setSelectedValue_race] = useState('')
  const [labelPosition_race, setLabelPosition_race] = useState('')

  const [showTextField, setShowTextField] = useState(false)

  // ===============Investment Range=================
  const handleSelectChange_investment = event => {
    setSelectedValue_investment(event.target.value)
    setLabelPosition(event.target.value ? 'top' : '')
    setSelectedValue_investment(event.target.value)
  }

  const handleSelectChange_power = event => {
    setSelectedValue_power(event.target.value)
    setLabelPosition_power(event.target.value ? 'top' : '')
  }
  const handleSelectChange_space = event => {
    setSelectedValue_space(event.target.value)
    setLabelPosition_space(event.target.value ? 'top' : '')
  }

  const handleSelectChange_investment_f = event => {
    setSelectedValue_investment_f(event.target.value)
    setlabelPosition_investment_f(event.target.value ? 'top' : '')
  }
  const handleSelectChange_race = event => {
    setSelectedValue_race(event.target.value)
    setLabelPosition_race(event.target.value ? 'top' : '')
  }
  // =============Radio Button==================
  const handleChange = e => {
    console.log(e.target.value)
    setradioInput(e.target.value)
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  // ============================================
  // =============Contact us from submit==================
  // ============================================
  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    setFormData('')
    navigate('/thankyou')
  }

  // ============================================
  // =============contactUs Api call==================
  // ============================================
  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')
    formdata.append('partnership_type', radioInput ?? '')
    formdata.append('investment_range', selectedValue_investment ?? '')
    formdata.append('investment_range', selectedValue_investment_f ?? '')
    formdata.append('investment_range', selectedValue_race ?? '')
    formdata.append('company_name', company_name ?? '')
    formdata.append('previous_experience', previous_experience ?? '')
    formdata.append('city', city ?? '')
    formdata.append('state', state ?? '')
    formdata.append('country', country ?? '')
    formdata.append('pincode', pincode ?? '')
    formdata.append('power_availability', selectedValue_power ?? '')
    formdata.append('type_of_space', selectedValue_space ?? '')
    formdata.append('type_of_vehicle', vehicleTypeInput_string ?? '')
    formdata.append(
      'annual_vehicle_requirement',
      annual_vehicle_requirement ?? ''
    )
    formdata.append('location_of_sale', location_of_sale ?? '')
    formdata.append('contents', contents ?? '')
    formdata.append('page_name', currentPathSlug ?? '')
    formdata.append('other_partnership', other_partnership ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // ============================================
  // =============Hear about us==================
  // ============================================
  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }
  // ============================================
  // =============Vehicle Type==================
  // ============================================
  const [vehicleTypeInput, setvehicleTypeInput] = useState([])
  const vehicleTypeInput_string = vehicleTypeInput.join(', ')

  const handleChangeVehicleType = e => {
    let VehicleTypeValue = [...vehicleTypeInput]

    if (e.target.checked) {
      console.log(e.target.value)
      VehicleTypeValue = [...vehicleTypeInput, e.target.value]
      if (VehicleTypeValue) {
        setvehicleTypeInput(VehicleTypeValue)

        const vehicleCount = VehicleTypeValue.length
        SetVehiclecount(vehicleCount)
      }
    } else {
      VehicleTypeValue.splice(vehicleTypeInput.indexOf(e.target.value), 1) // araay list
      setvehicleTypeInput(VehicleTypeValue)

      const vehicleCount = VehicleTypeValue.length
      SetVehiclecount(vehicleCount)
    }
  }

  // ============================================
  // =============modal==================
  // ============================================
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div className='new_form_section_main'>
        <form
          className='footprint_form'
          onSubmit={Contactus_from_submit}
          autoComplete='off'
        >
          <div className='row'>
            <div className='col-lg-11 col-md-11 col-sm-12'>
              <div className='row'>
                {/* name */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='did-floating-label-content mb_48'>
                    <input
                      required
                      className='did-floating-input'
                      type='text'
                      placeholder=' '
                      name='name'
                      value={formData.name ?? ''}
                      onChange={onChange}
                    />
                    <label className='did-floating-label'>Name*</label>{' '}
                  </div>
                </div>
                {/* email */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='did-floating-label-content mb_48'>
                    <input
                      required
                      className='did-floating-input'
                      type='email'
                      placeholder=' '
                      name='email'
                      value={formData.email ?? ''}
                      onChange={onChange}
                    />
                    <label className='did-floating-label'>Email Id*</label>
                  </div>
                </div>
                {/* phone number */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='did-floating-label-content mb_48'>
                    <input
                      required
                      className='did-floating-input'
                      type='number'
                      placeholder=' '
                      name='phone'
                      value={formData.phone ?? ''}
                      onChange={onChange}
                    />
                    <label className='did-floating-label'>Phone number*</label>
                  </div>
                </div>

                {/* Where did you .... */}
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='did-floating-label-content mb_48'>
                    <Dropdown className='custom_drop_down'>
                      <Dropdown.Toggle
                        variant=''
                        className='custom_drop_down_btn'
                        id='dropdown-basic'
                      >
                        {selectedcount ? (
                          <span> {selectedcount} Selected</span>
                        ) : (
                          <span>Where did you hear about us?</span>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Search Engine (Google, Bing, etc.)'
                            id='flexCheckChecked1'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked1'
                          >
                            Search Engine (Google, Bing, etc.)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Recommended by a Friend/Colleague'
                            id='flexCheckChecked2'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked2'
                          >
                            Recommended by a Friend/Colleague
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Social Media (LinkedIn, Twitter)'
                            id='flexCheckChecked3'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked3'
                          >
                            Social Media (LinkedIn, Twitter)
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Blog/Publication'
                            id='flexCheckChecked4'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked4'
                          >
                            Blog/Publication
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Signage'
                            id='flexCheckChecked5'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked5'
                          >
                            Signage
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Networking Event'
                            id='flexCheckChecked6'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked6'
                          >
                            Networking Event
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Startup Founder'
                            id='flexCheckChecked7'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked7'
                          >
                            Startup Founder
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Word of Mouth'
                            id='flexCheckChecked8'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked8'
                          >
                            Word of Mouth
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='hearAboutUs'
                            value='Other'
                            id='flexCheckChecked9'
                            onClick={handleChangeHearAbout}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckChecked9'
                          >
                            Others
                          </label>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                {/* small heading */}
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <h3>
                    Let us know what kind of partnership you are looking for?
                  </h3>
                </div>
                <section>
                  <div className=''>
                    <div className='form_radio_buttons'>
                      <div className='btn-group btn-group-justified form_radio_buttonsss'>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='AreaPartner2'
                            type='radio'
                            className='tabs_input custom__radio'
                            name='fav_language'
                            value='area-partner'
                            onChange={handleChange}
                            checked={radioInput == 'area-partner'}
                          />
                          <label
                            htmlFor='AreaPartner2'
                            style={{
                              color:
                                radioInput == 'area-partner'
                                  ? '#17e884'
                                  : '#FFFFFFB2'
                            }}
                          >
                            {' '}
                            Area Partner
                          </label>
                        </div>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='Franchise2'
                            type='radio'
                            className='tabs_input custom__radio '
                            name='fav_language'
                            value='franchise'
                            onChange={handleChange}
                            checked={radioInput == 'franchise'}
                          />
                          <label
                            style={{
                              color:
                                radioInput == 'franchise'
                                  ? '#17e884'
                                  : '#FFFFFFB2'
                            }}
                            htmlFor='Franchise2'
                          >
                            {' '}
                            Franchise
                          </label>
                        </div>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='RACEVault2'
                            type='radio'
                            className='tabs_input custom__radio '
                            name='fav_language'
                            value='race-vault'
                            onChange={handleChange}
                            checked={radioInput == 'race-vault'}
                          />
                          <label
                            style={{
                              color:
                                radioInput == 'race-vault'
                                  ? '#17e884'
                                  : '#FFFFFFB2'
                            }}
                            htmlFor='RACEVault2'
                          >
                            {' '}
                            RACE Vault
                          </label>
                        </div>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='Fleet2'
                            type='radio'
                            className='tabs_input custom__radio'
                            name='fav_language'
                            value='Fleet'
                            onChange={handleChange}
                            checked={radioInput == 'Fleet'}
                          />
                          <label
                            style={{
                              color:
                                radioInput == 'Fleet' ? '#17e884' : '#FFFFFFB2'
                            }}
                            htmlFor='Fleet2'
                          >
                            {' '}
                            Fleet
                          </label>
                        </div>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='OEM2'
                            type='radio'
                            className='tabs_input custom__radio'
                            name='fav_language'
                            value='OEM'
                            onChange={handleChange}
                            checked={radioInput == 'OEM'}
                          />
                          <label
                            style={{
                              color:
                                radioInput == 'OEM' ? '#17e884' : '#FFFFFFB2'
                            }}
                            htmlFor='OEM2'
                          >
                            {' '}
                            OEM
                          </label>
                        </div>
                        <div className='new_form_radio_buttons'>
                          <input
                            id='Other2'
                            type='radio'
                            className='tabs_input custom__radio '
                            name='fav_language'
                            value='other'
                            onChange={handleChange}
                            checked={radioInput == 'other'}
                          />
                          <label
                            style={{
                              color:
                                radioInput == 'other' ? '#17e884' : '#FFFFFFB2'
                            }}
                            htmlFor='Other2'
                          >
                            {' '}
                            Other
                          </label>
                        </div>
                      </div>
                      {/* tab content */}
                      <div className='new_form_tabs_content_main'>
                        {/* Area partner */}
                        {radioInput == 'area-partner' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Partner with us to deploy the most advanced
                              swappable batteries and high-performing e-Autos
                              and earn high returns on your investments.
                            </p>

                            <div className='row'>
                              {/* investment range */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='select-container mb_48'>
                                  <label
                                    className={`select-label ${labelPosition}`}
                                  >
                                    Investment range*
                                  </label>
                                  <select
                                    className='new_select__box'
                                    value={selectedValue_investment}
                                    onChange={handleSelectChange_investment}
                                  >
                                    <option value=''></option>
                                    <option value='Less than 1 Lakh'>
                                      Less than 1 Lakh
                                    </option>
                                    <option value='1- 10 Lakh'>
                                      1- 10 Lakh
                                    </option>
                                    <option value='10 Lakh- 1 Cr'>
                                      10 Lakh- 1 Cr
                                    </option>
                                    <option value='More than 1 Cr'>
                                      More than 1 Cr
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {/* company name */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='company_name'
                                    value={formData.company_name ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Company Name
                                  </label>
                                </div>
                              </div>

                              {/* Please help us understand your previous experience.  */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='previous_experience'
                                    value={formData.previous_experience ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Please help us understand your previous
                                    experience.
                                  </label>
                                </div>
                              </div>

                              {/* location */}
                              <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h3>Preferred Partnership Location</h3>
                              </div>
                              {/* City */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='city'
                                    value={formData.city ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    City
                                  </label>
                                </div>
                              </div>
                              {/* State */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='state'
                                    value={formData.state ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    State
                                  </label>
                                </div>
                              </div>
                              {/* Country */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='country'
                                    value={formData.country ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Country
                                  </label>
                                </div>
                              </div>
                              {/* Pincode */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='pincode'
                                    value={formData.pincode ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Pincode
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* franchise */}
                        {radioInput == 'franchise' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Swappable battery is the new petrol. ​Own a swap
                              station and earn revenue.{' '}
                            </p>

                            <div className='row'>
                              {/* Power Availability */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='select-container mb_48'>
                                  <label
                                    className={`select-label ${labelPosition_power}`}
                                  >
                                    Power Availability*
                                  </label>
                                  <select
                                    className='new_select__box'
                                    value={selectedValue_power}
                                    onChange={handleSelectChange_power}
                                  >
                                    <option value=''></option>
                                    <option value='Less than 5 kVa'>
                                      Less than 5 kVa
                                    </option>
                                    <option value='5- 15 kVa'>5- 15 kVa</option>
                                    <option value='16- 25 kVa'>
                                      16- 25 kVa
                                    </option>
                                    <option value='> 25 kVa'>
                                      Greater than 25 kVa
                                    </option>
                                  </select>
                                </div>
                              </div>
                              {/* investment range */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='select-container mb_48'>
                                  <label
                                    className={`select-label ${labelPosition_investment_f}`}
                                  >
                                    Investment range*
                                  </label>
                                  <select
                                    className='new_select__box'
                                    value={selectedValue_investment_f}
                                    onChange={handleSelectChange_investment_f}
                                  >
                                    <option value=''></option>
                                    <option value='Less than 1 Lakh'>
                                      Less than 1 Lakh
                                    </option>
                                    <option value='1- 10 Lakh'>
                                      1- 10 Lakh
                                    </option>
                                    <option value='10 Lakh- 1 Cr'>
                                      10 Lakh- 1 Cr
                                    </option>
                                    <option value='More than 1 Cr'>
                                      More than 1 Cr
                                    </option>
                                  </select>
                                </div>
                              </div>
                              {/* Type of Space* */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='select-container mb_48'>
                                  <label
                                    className={`select-label ${labelPosition_space}`}
                                  >
                                    Type of Space*
                                  </label>
                                  <select
                                    className='new_select__box'
                                    value={selectedValue_space}
                                    onChange={handleSelectChange_space}
                                  >
                                    <option value=''></option>
                                    <option value='Commercial Building'>
                                      Commercial Building
                                    </option>
                                    <option value='Petrol Pump'>
                                      Petrol Pump
                                    </option>
                                    <option value='Automative Store'>
                                      Automative Store
                                    </option>
                                    <option value='Parking Space'>
                                      Parking Space
                                    </option>
                                    <option value='Other'>
                                      Other (Mention)
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {/* Other Type of space */}
                              {selectedValue_space === 'Other' && (
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                  <div className='did-floating-label-content mb_48'>
                                    <input
                                      required
                                      className='did-floating-input'
                                      type='text'
                                      placeholder=' '
                                      name='other_partnership'
                                      value={formData.other_partnership ?? ''}
                                      onChange={onChange}
                                    />
                                    <label className='did-floating-label'>
                                      Other Type of space
                                    </label>
                                  </div>
                                </div>
                              )}

                              {/* Company Name  */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='company_name'
                                    value={formData.company_name ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Company Name
                                  </label>
                                </div>
                              </div>

                              {/* location */}
                              <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h3>Preferred Partnership Location</h3>
                              </div>
                              {/* City */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='city'
                                    value={formData.city ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    City
                                  </label>
                                </div>
                              </div>
                              {/* State */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='state'
                                    value={formData.state ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    State
                                  </label>
                                </div>
                              </div>
                              {/* Country */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='country'
                                    value={formData.country ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Country
                                  </label>
                                </div>
                              </div>
                              {/* Pincode */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='pincode'
                                    value={formData.pincode ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Pincode
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* race-vault */}
                        {radioInput == 'race-vault' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Invest in CO2-reducing products that uplift
                              low-income communities.
                            </p>

                            <div className='row'>
                              {/* investment range */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='select-container mb_48'>
                                  <label
                                    className={`select-label ${labelPosition_race}`}
                                  >
                                    Investment range*
                                  </label>
                                  <select
                                    className='new_select__box'
                                    value={selectedValue_race}
                                    onChange={handleSelectChange_race}
                                  >
                                    <option value=''></option>
                                    <option value='Less than 1 Lakh'>
                                      Less than 1 Lakh
                                    </option>
                                    <option value='1- 10 Lakh'>
                                      1- 10 Lakh
                                    </option>
                                    <option value='10 Lakh- 1 Cr'>
                                      10 Lakh- 1 Cr
                                    </option>
                                    <option value='More than 1 Cr'>
                                      More than 1 Cr
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {/* Company Name  */}
                              <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='company_name'
                                    value={formData.company_name ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Company Name
                                  </label>
                                </div>
                              </div>

                              {/* City */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='city'
                                    value={formData.city ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    City
                                  </label>
                                </div>
                              </div>
                              {/* State */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='state'
                                    value={formData.state ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    State
                                  </label>
                                </div>
                              </div>
                              {/* Country */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='country'
                                    value={formData.country ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Country
                                  </label>
                                </div>
                              </div>
                              {/* Pincode */}
                              <div className='col-lg-3 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='pincode'
                                    value={formData.pincode ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Pincode
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Fleet */}
                        {radioInput == 'Fleet' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Partner with us to build the future generation of
                              Indian E-autos with high-capacity battery setups
                              connected to automated swap stations.
                            </p>

                            <div className='row'>
                              {/* Type of Vehicle* */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <Dropdown className='custom_drop_down mb_24'>
                                  <Dropdown.Toggle
                                    variant=''
                                    className='custom_drop_down_btn'
                                    id='dropdown-basic_Type_Vehicle'
                                    style={{ marginTop: '3px' }}
                                  >
                                    {/* Type of Vehicle* */}
                                    {Vehiclecount ? (
                                      <span> {Vehiclecount} Selected</span>
                                    ) : (
                                      <span>Type of Vehicle*</span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='Low speed E-2-Wheeler'
                                        id='flexCheckChecked11'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked11'
                                      >
                                        Low speed E-2-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='High speed E-2-Wheeler'
                                        id='flexCheckChecked22'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked22'
                                      >
                                        High speed E-2-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Rickshaw'
                                        id='flexCheckChecked33'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked33'
                                      >
                                        E-Rickshaw
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Auto Passenger'
                                        id='flexCheckChecked44'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked44'
                                      >
                                        E-Auto Passenger
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Auto Cargo'
                                        id='flexCheckChecked55'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked55'
                                      >
                                        E-Auto Cargo
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='IC Engine 3-Wheeler'
                                        id='flexCheckChecked66'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked66'
                                      >
                                        IC Engine 3-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='Other'
                                        id='flexCheckChecked77'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked77'
                                      >
                                        Others
                                      </label>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              {/* Annual Vehicle Requirement  */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='annual_vehicle_requirement'
                                    value={
                                      formData.annual_vehicle_requirement ?? ''
                                    }
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Annual Vehicle Requirement
                                  </label>
                                </div>
                              </div>

                              {/* Location of Sale/ Market/ Demand */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='location_of_sale'
                                    value={formData.location_of_sale ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Location of Sale/ Market/ Demand
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* OEM */}
                        {radioInput == 'OEM' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Partner with us to build the future generation of
                              Indian E-autos with high-capacity battery setups
                              connected to automated swap stations.
                            </p>

                            <div className='row'>
                              {/* Type of Vehicle* */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <Dropdown className='custom_drop_down mb_24'>
                                  <Dropdown.Toggle
                                    variant=''
                                    className='custom_drop_down_btn'
                                    id='dropdown-basic_Type_Vehicle'
                                    style={{ marginTop: '3px' }}
                                  >
                                    {Vehiclecount ? (
                                      <span> {Vehiclecount} Selected</span>
                                    ) : (
                                      <span>Type of Vehicle*</span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='Low speed E-2-Wheeler'
                                        id='flexCheckChecked11'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked11'
                                      >
                                        Low speed E-2-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='High speed E-2-Wheeler'
                                        id='flexCheckChecked22'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked22'
                                      >
                                        High speed E-2-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Rickshaw'
                                        id='flexCheckChecked33'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked33'
                                      >
                                        E-Rickshaw
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Auto Passenger'
                                        id='flexCheckChecked44'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked44'
                                      >
                                        E-Auto Passenger
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='E-Auto Cargo'
                                        id='flexCheckChecked55'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked55'
                                      >
                                        E-Auto Cargo
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='IC Engine 3-Wheeler'
                                        id='flexCheckChecked66'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked66'
                                      >
                                        IC Engine 3-Wheeler
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='Other (Mention)'
                                        id='flexCheckChecked77'
                                        onClick={handleChangeVehicleType}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexCheckChecked77'
                                      >
                                        Others
                                      </label>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              {/* Annual Vehicle Requirement  */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='annual_vehicle_requirement'
                                    value={
                                      formData.annual_vehicle_requirement ?? ''
                                    }
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Annual Vehicle Requirement
                                  </label>
                                </div>
                              </div>

                              {/* Location of Sale/ Market/ Demand */}
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='location_of_sale'
                                    value={formData.location_of_sale ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Location of Sale/ Market/ Demand
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* other */}
                        {radioInput == 'other' && (
                          <div className='new_form_tabs_content_main_single'>
                            <p>
                              Partner with us to build the future generation
                              Indian E-autos with high-capacity battery setup
                              connected to automated swap stations.{' '}
                            </p>

                            <div className='row'>
                              {/* Please let us know why you are interested in connecting with us.  */}
                              <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='did-floating-label-content mb_24'>
                                  <input
                                    required
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='contents'
                                    value={formData.contents ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Please let us know why you are interested in
                                    connecting with us.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className='form_submit_btn mt_56'>
            <button className='' type='submit'>
              Submit <img src={btn_arrow} className='learn_arrow' alt='arrow' />
            </button>
          </div>
        </form>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          className='Error_modal'
        >
          <Modal.Body>Mail Send is Successfully </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default CommonForm
