import React, { useRef, useEffect } from 'react'
import network_banner from '../assets/imgs/network/network.webm'
import slider1 from '../assets/imgs/network/slider1.png'
import slider2 from '../assets/imgs/network/slider2.png'
import slider3 from '../assets/imgs/network/slider3.png'
import slider4 from '../assets/imgs/network/slider4.png'
import slider5 from '../assets/imgs/network/slider5.png'
import slider6 from '../assets/imgs/network/slider6.png'
import slider7 from '../assets/imgs/network/slider7.png'
import slider8 from '../assets/imgs/network/slider8.png'

import Carousel from 'react-bootstrap/Carousel'
const NetworkHero = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch((error) => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className="Network_Hero_main_section">
        <div className="Network_Hero_main_section_inner">
          <video ref={videoRef} autoPlay muted width="100%" height="100%" loop>
            <source src={network_banner} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="Network_Hero_content_main">
          <h1>
            RACE Smart Ecosystem is <br /> Reimagining Mobility{' '}
          </h1>
        </div>

        <div className="top_slider_main_div">
          <Carousel fade>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider1}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider2}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider3}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider4}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider5}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider6}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider7}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className=""
                src={slider8}
                alt="First slide"
                loading="lazy"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      <div className="Network_Hero_content_main_mobile">
        <h1>RACE Smart Ecosystem is Reimagining Mobility </h1>
      </div>
    </>
  )
}

export default NetworkHero
