import React, { useRef, useEffect } from 'react'
import hero_bg from '../assets/imgs/hero-video.webm'
import hero_bgposter from '../assets/imgs/Background.webp'
import arrow_right from '../assets/imgs/arrow_right.webp'
import { BsChevronDown } from 'react-icons/bs'

const Hero = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch((error) => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className="hero_video_section">
        <video
          ref={videoRef}
          autoPlay
          muted
          width="100%"
          height="100%"
          loop
          poster={hero_bgposter}
        >
          <source src={hero_bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero_content_div">
          <h1>
            Swap Right & <span>RACE</span>{' '}
          </h1>
          <p>The Battery Swapping Revolution is here! </p>
          <p>
            Presenting the safest, most energy dense, and modular swappable
            battery built for all kinds of EVs.
          </p>
        </div>
        <div className="hero_bg_images">
          <img src={arrow_right} />
        </div>
        <div className="pos_abs hero_down_arrow bounce">
          <BsChevronDown className="hero_down_arrow_icon" />
        </div>
      </section>
    </>
  )
}

export default Hero
