import React, { useState, useEffect } from 'react'
import Race_Video1 from '../assets/imgs/Race_Video111.webm'
import Race_Video2 from '../assets/imgs/Race_Video222.webm'
import Race_Video3 from '../assets/imgs/Race_Video333.webm'

import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom'
const Battery = () => {
  const [index, setIndex] = useState(0)

  const slides = [
    { src: Race_Video1, interval: 16000, link: '/battery' },
    { src: Race_Video2, interval: 22000, link: '/network' },
    { src: Race_Video3, interval: 3000, link: '/vehicle-solution' },
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % slides.length)
    }, slides[index].interval)

    return () => {
      clearInterval(timer)
    }
  }, [index, slides])

  return (
    <>
      <section className="battery_main_section">
        <Carousel
          slide={false}
          activeIndex={index}
          onSelect={(selectedIndex) => setIndex(selectedIndex)}
        >
          {slides.map((slide, i) => (
            <Carousel.Item key={i}>
              <Link to={slide.link}>
                <video autoPlay muted width="100%" height="100%" loop>
                  <source
                    src={slide.src}
                    alt={`Slide ${i + 1}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </>
  )
}

export default Battery
