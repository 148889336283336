import React, { useState, useEffect, useRef } from 'react'
import test_bg from '../assets/imgs/testimonial_bg.webp'
import Carousel from 'react-bootstrap/Carousel'
import img1 from '../assets/imgs/testimonail_text.webp'
import driver from '../assets/imgs/rambabu.png'
import driver2 from '../assets/imgs/vijay.png'
import vijay from '../assets/imgs/vijay_driver.png'
import Rambabu from '../assets/imgs/rambabu_driver.png'
import podni from '../assets/imgs/podni.png'

import translate from '../assets/imgs/translate.webp'

const Testimonial = () => {
  const [isToggle, setIsToggle] = useState(true)

  const [paragraphContent, setParagraphContent] = useState(
    '“I was always concerned about long charging times with other electric autos, but in this auto, I can get a full charge in 2 minutes.”',
  )
  const [paragraphContent2, setParagraphContent2] = useState(
    '“ఇతర ఎలక్ట్రిక్ ఆటోలతో ఎక్కువ ఛార్జింగ్ సమయాల గురించి నేను ఎల్లప్పుడూ ఆందోళన చెందుతాను, కానీ ఈ ఆటోలో, నేను 2 నిమిషాల్లో పూర్తి ఛార్జ్ పొందగలను.”',
  )

  const [paragraphContenttwo, setParagraphContenttwo] = useState(
    '“RACE Energy kit allows me to drive for longer hours as it can handle heavy use at a cheap cost.”',
  )
  const [paragraphContenttwo2, setParagraphContenttwo2] = useState(
    '"రేస్‌నర్జీ కిట్ నన్ను ఎక్కువ గంటలు డ్రైవ్ చేయడానికి అనుమతిస్తుంది, ఎందుకంటే ఇది తక్కువ ఖర్చుతో భారీ వినియోగాన్ని నిర్వహించగలదు."',
  )

  const handleButtonClick = () => {
    setIsToggle(!isToggle)
  }

  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const [activeIndex, setActiveIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex)
  }

  const goToIndex = (index) => {
    setActiveIndex(index)
  }

  return (
    <>
      <section className="testimonail_section">
        <div className="testimonail_section_heading_div">
          <h1>
            Drivers Experiences <br /> With RACE’s Technology{' '}
          </h1>
        </div>
        <div className="test_bg_img">
          <img
            src={test_bg}
            alt="testimonial"
            loading="lazy"
            className="img-fluid"
          />
        </div>

        <div className="testimonail_inner_main container">
          <Carousel
            fade
            ref={carouselRef}
            activeIndex={activeIndex}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <div className="row">
                <div className=" col-lg-5 col-md-12 col-sm-12">
                  <div className="testi_content_div">
                    <img
                      src={img1}
                      className="img-fluid test_test_img"
                      alt="testimonial"
                      loading="lazy"
                    />
                    <div className="testi_text_div">
                      <div className="translate_btn">
                        <button onClick={handleButtonClick}>
                          {' '}
                          <img
                            src={translate}
                            className="img-fluid"
                            alt="testimonial"
                            loading="lazy"
                          />{' '}
                        </button>
                      </div>
                      {isToggle ? (
                        <p>{paragraphContent}</p>
                      ) : (
                        <p>{paragraphContent2}</p>
                      )}

                      <div className="testi_diver_name_div">
                        <h3>Vijay</h3>
                        <h4>Driver</h4>
                      </div>
                    </div>
                    <img
                      src={driver2}
                      className="img-fluid driver_img"
                      alt="testimonial"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 test_side_single_div_main">
                  <div>
                    <div
                      className="test_side_single_div test_side_single_active"
                      onClick={() => goToIndex(0)}
                    >
                      <div className="test_side_single_div_inner">
                        <img
                          src={vijay}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>Vijay</h3>
                          <h4>Driver</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(1)}
                      >
                        <img
                          src={Rambabu}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>“RACE Energy kit is built for heavy use.”</h3>
                          <h4>Rambabu</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(2)}
                      >
                        <img
                          src={podni}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>
                            "By switching to E-Auto, I'm making twice as much
                            money."
                          </h3>
                          <h4>Podni Anjaneyulu</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="row">
                <div className=" col-lg-5 col-md-12 col-sm-12">
                  <div className="testi_content_div">
                    <img
                      src={img1}
                      className="img-fluid test_test_img"
                      alt="testimonial"
                      loading="lazy"
                    />
                    <div className="testi_text_div">
                      <div className="translate_btn">
                        <button onClick={handleButtonClick}>
                          {' '}
                          <img
                            src={translate}
                            className="img-fluid"
                            alt="testimonial"
                            loading="lazy"
                          />{' '}
                        </button>
                      </div>
                      {isToggle ? (
                        <p>{paragraphContenttwo}</p>
                      ) : (
                        <p>{paragraphContenttwo2}</p>
                      )}
                      <div className="testi_diver_name_div">
                        <h3>Rambabu</h3>
                        <h4>Driver</h4>
                      </div>
                    </div>
                    <img
                      src={driver}
                      className="img-fluid driver_img"
                      alt="testimonial"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 test_side_single_div_main">
                  <div>
                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(0)}
                      >
                        <img
                          src={vijay}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>"No more long charging times with RACE."</h3>
                          <h4>Vijay</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div test_side_single_active">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(1)}
                      >
                        <img
                          src={Rambabu}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>Rambabu</h3>
                          <h4>Driver</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(2)}
                      >
                        <img
                          src={podni}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>
                            "By switching to E-Auto, I'm making twice as much
                            money."
                          </h3>
                          <h4>Podni Anjaneyulu</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="row" style={{ alignItems: 'center' }}>
                <div className=" col-lg-5 col-md-12 col-sm-12">
                  <div className="testi_content_div">
                    <iframe
                      width="100%"
                      height="306"
                      src="https://www.youtube.com/embed/3I687-mdEi4"
                      title="A Spark of Change - Podni Anjaneyulu | Impact Stories | Rang De"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 test_side_single_div_main">
                  <div>
                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(0)}
                      >
                        <img
                          src={vijay}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>"No more long charging times with RACE."</h3>
                          <h4>Vijay</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div ">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(1)}
                      >
                        <img
                          src={Rambabu}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3> “RACE Energy kit is built for heavy use.”</h3>
                          <h4>Rambabu</h4>
                        </div>
                      </div>
                    </div>

                    <div className="test_side_single_div test_side_single_active">
                      <div
                        className="test_side_single_div_inner"
                        onClick={() => goToIndex(2)}
                      >
                        <img
                          src={podni}
                          alt="testimonial"
                          loading="lazy"
                          className="img-fluid driver_small_img"
                        />
                        <div>
                          <h3>Podni Anjaneyulu</h3>
                          <h4>Driver</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default Testimonial
