import React, { useState } from 'react'
import host1 from '../assets/imgs/network/host1.png'
import host2 from '../assets/imgs/network/host2.png'
import host3 from '../assets/imgs/network/host3.png'
import host4 from '../assets/imgs/network/host4.png'
import host5 from '../assets/imgs/network/host5.png'
import host6 from '../assets/imgs/network/host6.png'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

import Dropdown from 'react-bootstrap/Dropdown'

import footer_bg from '../assets/imgs/footer-bg.webp'
const Host = () => {
  const navigate = useNavigate()

  const [selectedcount, setselectedcount] = useState()

  // ===========Modal after form submit==========

  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)

  // ===========Hooks for form Fields==========

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',

    hear_about_us: '',
    new_fields: '',
    page_name: '',
    other_partnership: ''
  })

  const {
    name,
    email,
    phone,
    hear_about_us,
    city,
    state,
    country,
    pincode,
    new_fields,
    other_partnership
  } = formData

  // ===========Current page path==========

  const currentPath = window.location.pathname
  const currentPathSlug = currentPath.substring(
    currentPath.lastIndexOf('/') + 1
  )

  const [selectedValue_power, setSelectedValue_power] = useState('')
  const [labelPosition_power, setLabelPosition_power] = useState('')

  const [selectedValue_space, setSelectedValue_space] = useState('')
  const [labelPosition_space, setLabelPosition_space] = useState('')

  const handleSelectChange_power = event => {
    setSelectedValue_power(event.target.value)
    setLabelPosition_power(event.target.value ? 'top' : '')
  }
  const handleSelectChange_space = event => {
    setSelectedValue_space(event.target.value)
    setLabelPosition_space(event.target.value ? 'top' : '')
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  // =============Contact us from submit==================

  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    // handleShow_success()
    setFormData('')
    navigate('/thankyou')
    // if (
    //   name != '' &&
    //   email != '' &&
    //   phone != '' &&
    //   hearInput_string != '' &&
    //   selectedValue_power != '' &&
    //   new_fields != '' &&
    //   selectedValue_space != '' &&
    //   city != '' &&
    //   state != '' &&
    //   country != '' &&
    //   pincode != ''
    // ) {
    //   contactUsApi()
    //   handleShow_success()
    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // =============contactUs Api call==================

  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')
    formdata.append('hear_about_us', hearInput_string ?? '')

    formdata.append('city', city ?? '')
    formdata.append('state', state ?? '')
    formdata.append('country', country ?? '')
    formdata.append('pincode', pincode ?? '')
    formdata.append('power_availability', selectedValue_power ?? '')
    formdata.append('type_of_space', selectedValue_space ?? '')
    formdata.append('new_fields', new_fields ?? '')
    formdata.append('page_name', currentPathSlug ?? '')
    formdata.append('other_partnership', other_partnership ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // =============Hear about us==================

  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  return (
    <>
      <a id='Franchise' className='section-scroll-link' href='#'></a>

      <section className='host_main_section'>
        <section className='invest_tech_main_section'>
          <div className='container'>
            <div className='invest_tech_main_headings'>
              <h1>Host a Swap Station </h1>
              <p>
                Swappable battery is the new petrol. ​Own a swap station and
                earn revenue.{' '}
              </p>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img width_62'>
                      <img
                        src={host1}
                        alt='img'
                        loading='lazy'
                        className='img-fluid home_img '
                      />
                    </div>
                    <div>
                      <h2>Turn Your Space Into a Profitable Asset </h2>
                      <p>
                        There is no reason why your space shouldn't make you
                        money. Liven it up with a swap station and boost your
                        earnings​.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img'>
                      <img
                        src={host2}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                    <div>
                      <h2>A No-Brainer Value Pack​</h2>
                      <p>
                        Your investment comes with a minimum income guarantee.
                        It's simple. And true.​​{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img'>
                      <img
                        src={host3}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                    <div>
                      <h2>Be Future Ready</h2>
                      <p>
                        With electric mobility taking centre stage, batteries
                        are the new fuel. Invest in a battery swap station today
                        to stay up with the times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img'>
                      <img
                        src={host4}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                    <div>
                      <h2>Additional Income​</h2>
                      <p>Use your space to generate extra income.​​</p>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img'>
                      <img
                        src={host5}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                    <div>
                      <h2>Fuel of the Future​</h2>
                      <p>
                        EVs are in and petrol is out. Host and grow a swap
                        station ​
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='invest_tech_boxes'>
                    <div className='invest_tech_boxes_img'>
                      <img
                        src={host6}
                        alt='img'
                        loading='lazy'
                        className='img-fluid'
                      />
                    </div>
                    <div>
                      <h2>Guaranteed Revenue ​</h2>
                      <p>
                        Every battery owned as part of a swap station earns a
                        fixed revenue guaranteeing minimum income.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='Battery_Form_main_section'>
          <img
            src={footer_bg}
            alt='img'
            loading='lazy'
            className='img-fluid footer_bg'
          />
          <div className='container'>
            <div className='container'>
              <section className='invest_form_main_border'>
                <div className='invest_form_main_box'>
                  <h3>
                    Subject : Hey! I am interested in opening a franchise. 🙂
                  </h3>
                  <form
                    className='footprint_form'
                    onSubmit={Contactus_from_submit}
                    autoComplete='off'
                  >
                    <div className='row'>
                      <div className='col-lg-10'>
                        <div className='row'>
                          {/* name */}
                          <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='did-floating-label-content mb_48'>
                              <input
                                className='did-floating-input'
                                type='text'
                                placeholder=' '
                                name='name'
                                value={formData.name ?? ''}
                                onChange={onChange}
                                required
                              />
                              <label className='did-floating-label'>
                                Name*
                              </label>
                            </div>
                          </div>
                          {/* email */}
                          <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='did-floating-label-content mb_48'>
                              <input
                                className='did-floating-input'
                                type='email'
                                placeholder=' '
                                name='email'
                                value={formData.email ?? ''}
                                onChange={onChange}
                                required
                              />
                              <label className='did-floating-label'>
                                Email Id*
                              </label>
                            </div>
                          </div>
                          {/* phone number */}
                          <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='did-floating-label-content mb_48'>
                              <input
                                className='did-floating-input'
                                type='number'
                                placeholder=' '
                                name='phone'
                                value={formData.phone ?? ''}
                                onChange={onChange}
                                required
                              />
                              <label className='did-floating-label'>
                                Phone number*
                              </label>
                            </div>
                          </div>

                          {/* Where did you .... */}
                          <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='did-floating-label-content mb_48'>
                              <Dropdown className='custom_drop_down'>
                                <Dropdown.Toggle
                                  variant=''
                                  className='custom_drop_down_btn'
                                  id='dropdown-basic'
                                >
                                  {selectedcount ? (
                                    <span> {selectedcount} Selected</span>
                                  ) : (
                                    <span>Where did you hear about us?</span>
                                  )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Search Engine (Google, Bing, etc.)'
                                      id='flexCheckChecked1'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked1'
                                    >
                                      Search Engine (Google, Bing, etc.)
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Recommended by a Friend/Colleague'
                                      id='flexCheckChecked2'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked2'
                                    >
                                      Recommended by a Friend/Colleague
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Social Media (LinkedIn, Twitter)'
                                      id='flexCheckChecked3'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked3'
                                    >
                                      Social Media (LinkedIn, Twitter)
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Blog/Publication'
                                      id='flexCheckChecked4'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked4'
                                    >
                                      Blog/Publication
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Signage'
                                      id='flexCheckChecked5'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked5'
                                    >
                                      Signage
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Networking Event'
                                      id='flexCheckChecked6'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked6'
                                    >
                                      Networking Event
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Startup Founder'
                                      id='flexCheckChecked7'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked7'
                                    >
                                      Startup Founder
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Word of Mouth'
                                      id='flexCheckChecked8'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked8'
                                    >
                                      Word of Mouth
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name='hearAboutUs'
                                      value='Others'
                                      id='flexCheckChecked9'
                                      onClick={handleChangeHearAbout}
                                    />
                                    <label
                                      className='form-check-label'
                                      for='flexCheckChecked9'
                                    >
                                      Others
                                    </label>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          {/* small heading */}
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <h4 className=''>RACE Franchise</h4>
                          </div>

                          <section>
                            <div className=''>
                              <div className='form_radio_buttons'>
                                {/* tab content */}
                                <div className=''>
                                  <div className='new_form_tabs_content_main_single'>
                                    <div className='row'>
                                      {/* Power Availability */}
                                      <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='select-container mb_48'>
                                          <label
                                            className={`select-label ${labelPosition_power}`}
                                          >
                                            Power Availability*
                                          </label>
                                          <select
                                            className='new_select__box'
                                            value={selectedValue_power}
                                            onChange={handleSelectChange_power}
                                            required
                                          >
                                            <option value=''></option>
                                            <option value='Less than 5 kVa'>
                                              Less than 5 kVa
                                            </option>
                                            <option value='5- 15 kVa'>
                                              5- 15 kVa
                                            </option>
                                            <option value='16- 25 kVa'>
                                              16- 25 kVa
                                            </option>
                                            <option value='> 25 kVa'>
                                              Greater than 25 kVa
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      {/* New Fields* */}
                                      <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='did-floating-label-content mb_48'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                            name='new_fields'
                                            value={formData.new_fields ?? ''}
                                            onChange={onChange}
                                            required
                                          />
                                          <label className='did-floating-label'>
                                            New Fields*
                                          </label>
                                        </div>
                                      </div>

                                      {/* How much money are you looking to invest? */}
                                      <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='did-floating-label-content mb_48'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                          />
                                          <label className='did-floating-label'>
                                            How much money are you looking to
                                            invest?
                                          </label>
                                        </div>
                                      </div>
                                      {/* Type of Space* */}
                                      <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='select-container mb_48'>
                                          <label
                                            className={`select-label ${labelPosition_space}`}
                                          >
                                            Type of Space*
                                          </label>
                                          <select
                                            className='new_select__box'
                                            value={selectedValue_space}
                                            onChange={handleSelectChange_space}
                                            required
                                          >
                                            <option value=''></option>
                                            <option value='Commercial Building'>
                                              Commercial Building
                                            </option>
                                            <option value='Petrol Pump'>
                                              Petrol Pump
                                            </option>
                                            <option value='Automative Store'>
                                              Automative Store
                                            </option>
                                            <option value='Parking Space'>
                                              Parking Space
                                            </option>
                                            <option value='Other'>
                                              Other (Mention)
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      {/* Other Type of space */}
                                      {selectedValue_space === 'Other' && (
                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                          <div className='did-floating-label-content mb_48'>
                                            <input
                                              className='did-floating-input'
                                              type='text'
                                              placeholder=' '
                                              name='other_partnership'
                                              value={
                                                formData.other_partnership ?? ''
                                              }
                                              onChange={onChange}
                                            />
                                            <label className='did-floating-label'>
                                              Other Type of space
                                            </label>
                                          </div>
                                        </div>
                                      )}

                                      {/* location */}
                                      <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <h3>Location you want to partner</h3>
                                      </div>

                                      {/* small heading */}
                                      <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <h4 className=''>
                                          Preferred Partnership Location
                                        </h4>
                                      </div>

                                      {/* City */}
                                      <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='did-floating-label-content mb_24'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                            name='city'
                                            value={formData.city ?? ''}
                                            onChange={onChange}
                                          />
                                          <label className='did-floating-label'>
                                            City
                                          </label>
                                        </div>
                                      </div>
                                      {/* State */}
                                      <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='did-floating-label-content mb_24'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                            name='state'
                                            value={formData.state ?? ''}
                                            onChange={onChange}
                                          />
                                          <label className='did-floating-label'>
                                            State
                                          </label>
                                        </div>
                                      </div>
                                      {/* Country */}
                                      <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='did-floating-label-content mb_24'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                            name='country'
                                            value={formData.country ?? ''}
                                            onChange={onChange}
                                          />
                                          <label className='did-floating-label'>
                                            Country
                                          </label>
                                        </div>
                                      </div>
                                      {/* Pincode */}
                                      <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='did-floating-label-content mb_24'>
                                          <input
                                            className='did-floating-input'
                                            type='text'
                                            placeholder=' '
                                            name='pincode'
                                            value={formData.pincode ?? ''}
                                            onChange={onChange}
                                          />
                                          <label className='did-floating-label'>
                                            Pincode
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <div className='form_submit_btn form_submit_btn_flex_end'>
                          <button className='' type='submit'>
                            Submit{' '}
                            <img
                              src={btn_arrow}
                              className='learn_arrow'
                              alt='arrow'
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>

      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </>
  )
}

export default Host
