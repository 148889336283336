import React, { useRef, useEffect } from 'react'
import battery_banner from '../assets/imgs/battery/battery-banner.webm'
import banner from '../assets/imgs/battery/banner.png'

import { BsChevronDown } from 'react-icons/bs'

const BatteryHero = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch(error => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className='Battery_Hero_main_section'>
        {/* <video
          width='100%'
          height='100%'
          loop
          autoPlay={true}
          ref={videoRef}
          poster={banner}
        >
          <source src={battery_banner} type='video/mp4' />
          Your browser does not support the video tag.
        </video> */}
        <video
          ref={videoRef}
          autoPlay
          muted
          width='100%'
          height='100%'
          loop
          poster={banner}
        >
          <source src={battery_banner} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='pos_abs hero_down_arrow bounce'>
          <BsChevronDown className='hero_down_arrow_icon' />
        </div>
      </section>
    </>
  )
}

export default BatteryHero
