import React from 'react'
import right from './assets/imgs/thankyou.svg'
import { Link, useNavigate } from 'react-router-dom'
import btn_arrow from './assets/imgs/arrow_btn.png'
import footer_bg from './assets/imgs/footer-bg.webp'
import thankyoubg1 from './assets/imgs/thankybg1.png'
import thankyoubg2 from './assets/imgs/thankybg2.png'

const ThankYou = () => {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <>
      <section className="thankyou_page_main_section">
        <img
          src={footer_bg}
          alt="img"
          loading="lazy"
          className="img-fluid footer_bg"
        />
        <img
          src={thankyoubg1}
          alt="img"
          loading="lazy"
          className="img-fluid footer_bg1"
        />
        <img
          src={thankyoubg2}
          alt="img"
          loading="lazy"
          className="img-fluid footer_bg2"
        />

        <img src={right} className="img-fluid thankyou_check" />

        <h1>Thank you for reaching out!</h1>
        <p>
          We have received your request. <br />
          We will be contacting you shortly.
        </p>
        <div className="back_to_home_btn_outer">
          {' '}
          <Link className="back_to_home_btn" onClick={goBack}>
            Back to the Page{' '}
            <img src={btn_arrow} className="learn_arrows" alt="arrow" />
          </Link>
        </div>
      </section>
    </>
  )
}

export default ThankYou
