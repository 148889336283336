import React from 'react'

import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom'
const MoreVideos = () => {
  return (
    <>
      <section className="MoreVideos_main_section">
        <section className="more_stories_main_section more_stories_main_section_mob_none">
          <div className="container">
            <div className="more_stories_main_headings">
              <h1>More Videos </h1>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '30px' }}
                    width="100%"
                    height="210"
                    src="https://www.youtube.com/embed/p52bB0ifAHE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>

                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">Technology</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=p52bB0ifAHE&ab_channel=ETVTelangana">
                      Young people doing retro fitting of autos Hyderabad | Yuva
                    </Link>
                  </h3>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '30px' }}
                    width="100%"
                    height="210"
                    src="https://www.youtube.com/embed/TRwoZwczsAg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">EV Technology</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=p52bB0ifAHE&ab_channel=ETVTelangana">
                      EV's New Technology Battery Swapping | StartUp in
                      Hyderabad | RACE Energy CEO Arun sreyas Reddy{' '}
                    </Link>
                  </h3>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '30px' }}
                    width="100%"
                    height="210"
                    src="https://www.youtube.com/embed/cUZP6z3c8Ro"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">Swapping</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=cUZP6z3c8Ro&ab_channel=ETAuto">
                      Panel Discussion on Charging, Swapping and Powering Ahead{' '}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="FeaturedVideos_main_section_slider">
          <div className="container">
            <Carousel fade>
              <Carousel.Item>
                {/* <div className="more_stories_single_box">
                  <video
                    poster={video_poster1}
                    width="100%"
                    height="100%"
                    loop
                    controls
                    autoPlay="true"
                  >
                    <source
                      src="https://media.istockphoto.com/id/1472082471/video/aerial-view-of-residential-buildings-in-the-crowded-govandi-neighbourhood-in-the-the-suburbs.mp4?s=mp4-640x640-is&k=20&c=r3hv-QsLb39s9WfclgxJdhDwsnvp-a76Ndt8E07SVp8="
                      type="video/mp4"
                    />
                    <source
                      src="https://media.istockphoto.com/id/1472082471/video/aerial-view-of-residential-buildings-in-the-crowded-govandi-neighbourhood-in-the-the-suburbs.mp4?s=mp4-640x640-is&k=20&c=r3hv-QsLb39s9WfclgxJdhDwsnvp-a76Ndt8E07SVp8="
                      type="video/ogg"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">Technology</span>
                  </div>
                  <h3>
                    Young people doing retro fitting of autos Hyderabad | Yuva
                  </h3>
                </div> */}
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '5px' }}
                    width="100%"
                    height="167"
                    src="https://www.youtube.com/embed/p52bB0ifAHE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>

                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">Technology</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=p52bB0ifAHE&ab_channel=ETVTelangana">
                      Young people doing retro fitting of autos Hyderabad | Yuva
                    </Link>
                  </h3>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '30px' }}
                    width="100%"
                    height="210"
                    src="https://www.youtube.com/embed/TRwoZwczsAg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">EV Technology</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=p52bB0ifAHE&ab_channel=ETVTelangana">
                      EV's New Technology Battery Swapping | StartUp in
                      Hyderabad | RACE Energy CEO Arun sreyas Reddy{' '}
                    </Link>
                  </h3>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="more_stories_single_box">
                  <iframe
                    style={{ borderRadius: '30px' }}
                    width="100%"
                    height="210"
                    src="https://www.youtube.com/embed/cUZP6z3c8Ro"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div className="news_by_and_date_div">
                    <span className="news_color">Video |</span>
                    <span className="news_by">Swapping</span>
                  </div>
                  <h3>
                    <Link to="https://www.youtube.com/watch?v=cUZP6z3c8Ro&ab_channel=ETAuto">
                      Panel Discussion on Charging, Swapping and Powering Ahead{' '}
                    </Link>
                  </h3>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </section>
      </section>
    </>
  )
}

export default MoreVideos
