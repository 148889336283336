import React, { useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import invester1 from '../assets/imgs/vault/invester1.png'
import invester2 from '../assets/imgs/vault/invester2.png'
import invester3 from '../assets/imgs/vault/shri.png'
import invester4 from '../assets/imgs/vault/rahul.png'

import quote from '../assets/imgs/vault/quote.webp'
import { Link } from 'react-router-dom'

const InvestorsSlider = () => {
  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 6000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  return (
    <>
      <section className="InvestorsSlider_main_section">
        <div className="container">
          <div className="InvestorsSlider_main_headings">
            <h1>Hear From Our Investors</h1>
          </div>
          <section>
            <Carousel slide={false} ref={carouselRef}>
              <Carousel.Item interval={3000} className="InvestorsSlider_single">
                <div className="row InvestorsSlider_main_section_inner">
                  <div className="col-lg-7 col-md-7 col-sm-12 ">
                    <div className="qote_small_img_flex">
                      <h3>Earn High Returns by Supporting Clean Energy </h3>
                      <img src={quote} className="img-fluid qote_small_img" />
                    </div>

                    <p>
                      RACE Vault has been a wonderful platform for us to get
                      back high returns on the investments we made, but it is an
                      even better platform for enabling us to be a part of the
                      World’s transition to clean energy. Being associated with
                      RACE through RACE Vault, we feel privileged to be part of
                      the technological advancements in clean energy.
                    </p>
                    <div className="Investors_small_img">
                      <img
                        src={invester1}
                        className="img-fluid"
                        alt="img"
                        loading="lazy"
                      />
                      <h4>Sidhartha Namburi</h4>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 InvestorsSlider_quote_col">
                    <img
                      src={quote}
                      className="img-fluid"
                      alt="img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={3000} className="InvestorsSlider_single">
                <div className="row InvestorsSlider_main_section_inner">
                  <div className="col-lg-7 col-md-7 col-sm-12 ">
                    <div className="qote_small_img_flex">
                      <h3>Entire Process Is Simple and Transparent </h3>
                      <img src={quote} className="img-fluid qote_small_img" />
                    </div>
                    <p>
                      RACE Vault has provided me with the opportunity to earn
                      higher returns than even public markets, and I am grateful
                      to be introduced to this opportunity. It is an amazing
                      option for alternative investment, with a very low ticket
                      size required to get started. The entire process is simple
                      and transparent - and returns have been credited to my
                      account on time and without any hassle. I would strongly
                      recommend RACE Vault as a strong contender for anyone
                      looking to deploy capital in investments. The team is
                      building exciting products and I am proud to be part of
                      their growth journey!
                    </p>
                    <div className="Investors_small_img">
                      <img
                        src={invester2}
                        className="img-fluid"
                        alt="img"
                        loading="lazy"
                      />
                      <h4>Siddhant Ranjan</h4>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 InvestorsSlider_quote_col">
                    <img
                      src={quote}
                      className="img-fluid"
                      alt="img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={3000} className="InvestorsSlider_single">
                <div className="row InvestorsSlider_main_section_inner">
                  <div className="col-lg-7 col-md-7 col-sm-12 ">
                    <div className="qote_small_img_flex">
                      <h3>Fixed Returns in a Timely Manner</h3>
                      <img src={quote} className="img-fluid qote_small_img" />
                    </div>
                    <p>
                      I’m very glad to have been one of the early investors of
                      RACE Vault as it has not only given me lucrative fixed
                      returns on time but its also an exciting industry to be a
                      part of that is genuinely making the world a better place.
                      Arun has been an excellent founder to work with and RACE
                      Vault has managed to be an inclusive platform for all
                      considering the low ticket size investment requirements. I
                      am very excitedly looking forward to the future of this
                      company.
                    </p>
                    <div className="Investors_small_img">
                      <Link
                        to="https://www.linkedin.com/in/rahul-kanamarlapudi-3151b9104/"
                        target="_blank"
                      >
                        <img
                          src={invester3}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />
                        <h4>Shrivatsa Vakil</h4>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 InvestorsSlider_quote_col">
                    <img
                      src={quote}
                      className="img-fluid"
                      alt="img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={3000} className="InvestorsSlider_single">
                <div className="row InvestorsSlider_main_section_inner">
                  <div className="col-lg-7 col-md-7 col-sm-12 ">
                    <div className="qote_small_img_flex">
                      <h3>
                        Contribute to a Cleaner and Sustainable Mother Earth{' '}
                      </h3>
                      <img src={quote} className="img-fluid qote_small_img" />
                    </div>
                    <p>
                      The journey with RACE Vault was so far hassle-free and
                      comfortable. The returns on our investments have
                      encouraged us even more to be a vital part of the growing
                      family. Additionally, we feel this journey is an honour
                      because it is a channel where we as responsible humans can
                      contribute to a cleaner and sustainable mother earth. 🌎
                    </p>

                    <div className="Investors_small_img">
                      <Link
                        to="https://www.linkedin.com/in/rahul-kanamarlapudi-3151b9104/"
                        target="_blank"
                      >
                        <img
                          src={invester4}
                          className="img-fluid"
                          alt="img"
                          loading="lazy"
                        />

                        <h4>Rahul Kanamarlapudi</h4>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 InvestorsSlider_quote_col">
                    <img
                      src={quote}
                      className="img-fluid"
                      alt="img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </section>
        </div>
      </section>
    </>
  )
}

export default InvestorsSlider
