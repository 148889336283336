import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import e_1 from '../assets/imgs/custom/e-1.webp'
import e_2 from '../assets/imgs/custom/e-2.webp'
import e_3 from '../assets/imgs/custom/e-3.webp'
import e_4 from '../assets/imgs/custom/e-4.webp'
const EautoTableSlider = () => {
  return (
    <>
      <section className='eauto_table_slider_mobile'>
        <div className='row'>
          <div className='col-4'>
            <div className='e_auto_graph_first_col'>
              <div className='e_auto_graph_first_col_div'>
                <h2 className='pl_1 '>
                  Total one time <br /> cost
                </h2>
              </div>
              <div className='e_auto_graph_first_col_div'>
                <h2 className='l_h_23'>Lifetime energy cost</h2>
              </div>
              <div className='e_auto_graph_first_col_div'>
                <h2 className='pb_0 l_h_18'>Monthly maintenance cost</h2>
                <h2 className='pt_0 l_h_18'>Lifetime maintenance cost</h2>
              </div>
              <div className='e_auto_graph_first_col_div'>
                <h2 className='l_h_25'>Battery/Engine Replacement cost</h2>
              </div>
              <div className='e_auto_graph_first_col_div'>
                <h2 className='l_h_23'>Total cost of ownership </h2>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <Carousel fade>
              <Carousel.Item>
                <div className='row'>
                  <div className='col-6'>
                    <div className='e_auto_graph_other_cols_border'>
                      <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_one'>
                        <h3 className='headings'>RACE/OEM Vehicle</h3>

                        <img
                          src={e_1}
                          className='auto_img img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 2,50,000/-</h4>
                          <h5>No Permit Required</h5>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 4,62,400/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms min_h_80'>
                          <h4>Rs. 200/-</h4>
                          <h4>Rs. 19,200/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                          <h4>-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_bottoms'>
                          <h4
                            style={{ color: '#17E884', marginTop: '16px' }}
                            className='high_light_text'
                          >
                            Rs. 7,31,600/-
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='e_auto_graph_other_cols_border'>
                      <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_one'>
                        <h3 className='headings'>
                          RACE <br /> Retrofit
                        </h3>

                        <img
                          src={e_2}
                          className='auto_img img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 1,50,000/-</h4>
                          <h5>No Permit Required</h5>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 6,80,000/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms min_h_80'>
                          <h4>Rs. 200/-</h4>
                          <h4>Rs. 19,200/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                          <h4>-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_bottoms'>
                          <h4
                            style={{ color: '#17E884', marginTop: '16px' }}
                            className='high_light_text'
                          >
                            Rs. 8,49,200/-
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className='row'>
                  <div className='col-6'>
                    <div className='e_auto_graph_other_cols_border_two'>
                      <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_two'>
                        <h3 className='headings'>CNG</h3>

                        <img
                          src={e_3}
                          className='auto_img img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 4,00,000/-</h4>
                          <h5>Incl. Permit in Hyderabad</h5>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 8,16,000/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms min_h_80'>
                          <h4>Rs. 2,000/-</h4>
                          <h4>Rs. 1,92,000/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                          <h4>Rs. 53,333/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_bottoms'>
                          <h4
                            style={{ color: '#EB5757', marginTop: '16px' }}
                            className='high_light_text'
                          >
                            Rs. 14,61,333/-
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='e_auto_graph_other_cols_border_two'>
                      <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_two'>
                        <h3 className='headings'>
                          EV Fixed <br /> Battery
                        </h3>

                        <img
                          src={e_4}
                          className='auto_img img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 3,30,000/-</h4>
                          <h5>No Permit Required</h5>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms'>
                          <h4>Rs. 2,72,000/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms min_h_80'>
                          <h4>Rs. 200/-</h4>
                          <h4>Rs. 19,200/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                          <h4>Rs. 3,00,000/-</h4>
                        </div>
                        <div className='e_auto_graph_small_box border_bottoms'>
                          <h4
                            style={{ color: '#EB5757', marginTop: '16px' }}
                            className='high_light_text'
                          >
                            Rs. 9,21,200/-
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default EautoTableSlider
