import React from 'react'
import CustomSlider from './CustomSlider'
import OneBattery from './OneBattery'
import CustomHero from './CustomHero'
import SolutionTabs from './SolutionTabs'

const CustomSolutionMain = () => {
  return (
    <>
      <CustomSlider />
      <CustomHero />
      <OneBattery />
      <SolutionTabs />
    </>
  )
}

export default CustomSolutionMain
