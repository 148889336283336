import React, { useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import tree from '../assets/imgs/video_tree.mp4'
import factory from '../assets/imgs/CO2.webm'
import Benefiting from '../assets/imgs/Benefiting.webm'
import Million from '../assets/imgs/milion.webm'


const VideoSection = () => {
  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {})
        .catch((error) => {})
    } else {
    }
  }, [])
  return (
    <>
      <section className="video_section_main">
        <Carousel fade ref={carouselRef}>
          <Carousel.Item interval={5000}>
            <div className="video_section_main_div">
              <video
                width="100%"
                height="100%"
                loop
                ref={videoRef}
                autoPlay
                muted
              >
                <source src={factory} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="video_overlay"></div>
              <div className="video_section_inner_div">
                <h1>130 Million kg of CO2e</h1>
                <p>Emissions Savings by 2025</p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={5000}>
            <div className="video_section_main_div">
              <video
                width="100%"
                height="100%"
                loop
                ref={videoRef}
                autoPlay
                muted
              >
                <source src={tree} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="video_overlay"></div>
              <div className="video_section_inner_div">
                <p>Equal to Planting</p>
                <h1>4 Million Trees</h1>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={5000}>
            <div className="video_section_main_div">
              <video
                width="100%"
                height="100%"
                loop
                ref={videoRef}
                autoPlay
                muted
              >
               
                  <source src={Million} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="video_overlay"></div>
              <div className="video_section_inner_div">
                <h1>$75 Million in Savings</h1>
                <p>Financial Benefit to Drivers</p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={5000}>
            <div className="video_section_main_div">
              <video
                width="100%"
                height="100%"
                loop
                ref={videoRef}
                autoPlay
                muted
              >
                <source src={Benefiting} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="video_overlay"></div>
              <div className="video_section_inner_div">
                <h1>Benefiting Half a Million Lives</h1>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  )
}

export default VideoSection
