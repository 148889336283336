import React from 'react'
import video_img from '../assets/imgs/vault/banner.mp4'
import footer_bg from '../assets/imgs/footer-bg.webp'

const ReducePollution = () => {
  return (
    <>
      <section className="ReducePollution_main_section">
        <img
          src={footer_bg}
          className="img-fluid footer_bg"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="Reduce_Pollution_main_inner">
            <video width="100%" height="100%" loop autoPlay="true" controls>
              <source src={video_img} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="Reduce_Pollution_video_bg"></div>
            <div className="Reduce_Pollution_content_main">
              <h1>Reduce Pollution, Save Lives</h1>

              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="border_right">
                    <h3>130 Million kg of CO2e </h3>
                    <p>Emissions Savings by 2025</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="border_right border_right_40">
                    <h3>$75 Million in Savings </h3>
                    <p>Financial Benefit to Drivers</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className=" border_right_40">
                    <h3>500,000 Lives </h3>
                    <p>Drivers Lives Benefited</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReducePollution
