import React from 'react'
import map from '../assets/imgs/network/map.webp'
import map_bg1 from '../assets/imgs/network/map-bg1.webp'
import map_bg2 from '../assets/imgs/network/map-bg2.webp'

const Map = () => {
  return (
    <>
      <section className="map_main_section">
        <img
          src={map_bg1}
          alt="img"
          loading="lazy"
          className="img-fluid map_bg1"
        />
        <img
          src={map_bg2}
          alt="img"
          loading="lazy"
          className="img-fluid map_bg2"
        />

        <div className="container">
          <div className="">
            <h1>Unified Swapping Grid Network </h1>
            <img src={map} alt="img" loading="lazy" className="img-fluid" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Map
