import React, { useState, useEffect, useRef } from 'react'
import teamWork1 from '../assets/imgs/About/teamWork1.webp'
import teamWork2 from '../assets/imgs/About/teamWork2.png'
import teamWork3 from '../assets/imgs/About/teamWork3.webp'
import teamWork4 from '../assets/imgs/About/teamWork4.webp'
import teamWork5 from '../assets/imgs/About/teamWork5.webp'
import teamWork6 from '../assets/imgs/About/teamWork6.png'
import teamWork7 from '../assets/imgs/About/teamWork7.png'
import teamWork8 from '../assets/imgs/About/teamWork8.png'
import teamWork9 from '../assets/imgs/About/teamWork9.png'
import teamWork10 from '../assets/imgs/About/teamWork10.webp'
import teamWork11 from '../assets/imgs/About/teamWork11.webp'
import teamWork12 from '../assets/imgs/About/teamWork12.png'
import teamWork13 from '../assets/imgs/About/teamWork13.png'
import teamWork14 from '../assets/imgs/About/teamWork14.png'
import teamWork15 from '../assets/imgs/About/teamWork15.webp'

import Carousel from 'react-bootstrap/Carousel'
import team_work_bg from '../assets/imgs/About/team-work-bg.webp'

const TeamWork = () => {
  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 4000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  return (
    <>
      <div className='team_work_main_section_main'>
        <section className='team_work_main_section'>
          <img
            src={team_work_bg}
            className='img-fluid team_work_bg'
            alt='img'
            loading='lazy'
          />
          <div className='container'>
            <h1 className='main_headings'>Team Work Makes the Dream Work</h1>
            <section className='team_work_desktop_slider'>
              <Carousel fade ref={carouselRef}>
                <Carousel.Item>
                  <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                      <img
                        src={teamWork1}
                        className='img-fluid'
                        alt='img'
                        loading='lazy'
                      />
                      <img
                        src={teamWork2}
                        className='img-fluid team__img'
                        alt='img'
                        loading='lazy'
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                      <div className='row'>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <img
                            src={teamWork3}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <img
                            src={teamWork4}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <img
                            src={teamWork5}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                      <img
                        src={teamWork6}
                        className='img-fluid'
                        alt='img'
                        loading='lazy'
                      />
                      <img
                        src={teamWork7}
                        className='img-fluid'
                        alt='img'
                        loading='lazy'
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                      <div className='row'>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <img
                            src={teamWork8}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <img
                            src={teamWork9}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <img
                            src={teamWork10}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                <Carousel.Item className='disabled'>
                  <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                      <img
                        src={teamWork11}
                        className='img-fluid'
                        alt='img'
                        loading='lazy'
                      />
                      <img
                        src={teamWork12}
                        className='img-fluid'
                        alt='img'
                        loading='lazy'
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                      <div className='row'>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <img
                            src={teamWork13}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <img
                            src={teamWork14}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          <img
                            src={teamWork15}
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </section>

            <section className='team_work_mobile_slider'>
              <Carousel fade ref={carouselRef}>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork1}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork2}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork3}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork4}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork5}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork6}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork7}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork8}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork9}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork10}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork11}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork12}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork13}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork14}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='mob_team_work'>
                    <img
                      src={teamWork15}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </section>
          </div>
        </section>
      </div>
    </>
  )
}

export default TeamWork
