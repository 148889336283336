import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import newroom_ring from '../assets/imgs/tecg-ring.webp'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

const NewsRoomHero = () => {
  const { id } = useParams()

  const baseurl = 'https://race.pselier.com'
  const [blog, setBlog] = useState([])
  const [mainBlog, setMainBlog] = useState([])

  const getBlogs = () => {
    fetch('https://race.pselier.com/api/blogs')
      .then((response) => response.json())
      .then((result) => setBlog(result.result))
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getBlogs()
    BlogParaContent()
    NewsParaContent()
  }, [])

  function BlogParaContent() {
    // return {
    //   __html: mainBlog?.blogs[0]?.description,
    // }
  }

  function NewsParaContent() {
    // return {
    //   __html: mainBlog?.news[0]?.description,
    // }
  }

  const MainBlogAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    fetch('https://race.pselier.com/api/more-stories', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setMainBlog(result.result)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    MainBlogAPI()
  }, [])

  return (
    <>
      {blog && (
        <section className="newsroom_hero_main_section">
          <img
            src={newroom_ring}
            className="img-fluid newroom_ring"
            alt="img"
            loading="lazy"
          />

          <div className="container">
            <div className="newsroom_hero_main_section_headings">
              <h1>Newsroom</h1>
            </div>

            <section className="tabs_div news_border_bottom">
              <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className=" tabs_div_inner "
              >
                <Tab eventKey="All" title="All" className="">
                  <section>
                    <div className="row">
                      {mainBlog?.blog_featured?.slice(0, 1).map((value) => {
                        return (
                          <div className="col-lg-8 col-md-12 col-sm-12">
                            {/* <Link to={`/article/${blog[0]?.slug}`}> */}
                            <Link to={`/article/${value.slug}`}>
                              <div className="news_content_left_side_main">
                                <img
                                  src={
                                    baseurl +
                                    '/' +
                                    'uploads/admin_blogs_images/' +
                                    value?.featured_image
                                  }
                                  className="img-fluid "
                                  alt="img"
                                  loading="lazy"
                                />
                                <div className="news_content_left_side_content_main">
                                  <h4>{value.category?.name} </h4>
                                  <h2>{value.title}</h2>
                                  <div className="news_by_and_date_div">
                                    <span className="news_color">
                                      {value.type} |
                                    </span>
                                    <span className="news_by">
                                      by {value.author_name} |
                                    </span>
                                    <span>
                                      {dayjs(value?.created_at).format(
                                        'MMM DD, YYYY',
                                      )}
                                    </span>
                                  </div>
                                  {/* <div
                                    dangerouslySetInnerHTML={{
                                      __html: value.short_description,
                                    }}
                                  /> */}
                                  <div>
                                    <p>{value?.short_description}</p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })}

                      <div className="col-lg-4 col-md-12 col-sm-12">
                        {mainBlog?.news_featured?.slice(1, 2).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}

                        {mainBlog?.news_featured?.slice(2, 3).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </section>
                </Tab>

                <Tab eventKey="Blog " title="Blog ">
                  <section>
                    <div className="row">
                      {mainBlog?.blog_featured?.slice(0, 1).map((value) => {
                        return (
                          <div className="col-lg-8 col-md-12 col-sm-12">
                            {/* <Link to={`/article/${blog[0]?.slug}`}> */}
                            <Link to={`/article/${value.slug}`}>
                              <div className="news_content_left_side_main">
                                <img
                                  src={
                                    baseurl +
                                    '/' +
                                    'uploads/admin_blogs_images/' +
                                    value?.featured_image
                                  }
                                  className="img-fluid "
                                  alt="img"
                                  loading="lazy"
                                />
                                <div className="news_content_left_side_content_main">
                                  <h4>{value.category?.name} </h4>
                                  <h2>{value.title}</h2>
                                  <div className="news_by_and_date_div">
                                    <span className="news_color">
                                      {value.type} |
                                    </span>
                                    <span className="news_by">
                                      by {value.author_name} |
                                    </span>
                                    <span>
                                      {dayjs(value?.created_at).format(
                                        'MMM DD, YYYY',
                                      )}
                                    </span>
                                  </div>
                                  <p>{value.short_description}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })}

                      <div className="col-lg-4 col-md-12 col-sm-12">
                        {mainBlog?.blog_featured?.slice(1, 2).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}

                        {mainBlog?.blog_featured?.slice(2, 3).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </section>
                </Tab>
                <Tab eventKey="News" title="News">
                  <section>
                    <div className="row">
                      {mainBlog?.news_featured?.slice(0, 1).map((value) => {
                        return (
                          <>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                              {/* <Link to={`/article/${blog[0]?.slug}`}> */}
                              <Link to={`/article/${value.slug}`}>
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid "
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value.author_name} |
                                      </span>
                                      <span>
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    {/* <div
                                    dangerouslySetInnerHTML={{
                                      __html: value.short_description,
                                    }}
                                  /> */}
                                    <p>{value.short_description}</p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </>
                        )
                      })}

                      <div className="col-lg-4 col-md-12 col-sm-12">
                        {mainBlog?.news_featured?.slice(1, 2).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}

                        {mainBlog?.news_featured?.slice(2, 3).map((value) => {
                          return (
                            <Link to={`/article/${value?.slug}`}>
                              <div className="news_content_right_side_main_single news_content_right_side_main_single_none">
                                <div className="news_content_left_side_main">
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      value?.featured_image
                                    }
                                    className="img-fluid featured_image_short"
                                    alt="img"
                                    loading="lazy"
                                  />
                                  <div className="news_content_left_side_content_main">
                                    <h4>{value.category?.name} </h4>
                                    <h2>{value.title}</h2>
                                    <div className="news_by_and_date_div">
                                      <span className="news_color">
                                        {value.type} |
                                      </span>
                                      <span className="news_by">
                                        by {value?.author_name} |
                                      </span>
                                      <span>
                                        {' '}
                                        {dayjs(value?.created_at).format(
                                          'MMM DD, YYYY',
                                        )}
                                      </span>
                                    </div>
                                    <div className="side_short_des">
                                      <p>{value?.short_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </section>
                </Tab>
              </Tabs>
            </section>
          </div>
        </section>
      )}
    </>
  )
}

export default NewsRoomHero
