import React, { useRef, useEffect } from 'react'
import Advanced_battery from '../assets/imgs/battery/Advanced_battery.webp'
import battery from '../assets/imgs/battery/battery.jpeg'
import battery_bg_arrow from '../assets/imgs/battery/battery-bg-arrow.webp'
import battery_banner from '../assets/imgs/battery/battery.webm'

const Advancedbattery = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch(error => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className='Advanced_battery_main_section'>
        <img
          alt='img'
          loading='lazy'
          src={battery_bg_arrow}
          className='img-fluid battery_bg_arrow'
        />
        <img
          alt='img'
          loading='lazy'
          src={Advanced_battery}
          className='img-fluid Advanced_battery_main_section_bg'
        />
        <div className='container'>
          <div className='Advanced_battery_main_headings'>
            <h1>
              World’s Most Advanced <br />​ Swappable Battery
            </h1>
            <a id='technology' className='section-scroll-link' href='#'></a>

            <h2 id=''>
              Discover the Inner Engineering <br /> of Our Battery{' '}
            </h2>
            <p>
              RACE Energy's battery technology provides reliable and sustainable
              energy solutions offering high performance, durability, and
              efficiency.
            </p>
            <div className='battery_big_img_div'>
              {/* <img
                alt='img'
                loading='lazy'
                src={battery}
                className='img-fluid'
              /> */}
              <video
                ref={videoRef}
                autoPlay
                muted
                width='100%'
                height='100%'
                loop
                poster={battery}
              >
                <source src={battery_banner} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Advancedbattery
