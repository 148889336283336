import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import tecg_ring from '../assets/imgs/tecg-ring.webp'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import btn_arrow_down from '../assets/imgs/btn_arrow_down.png'
import dayjs from 'dayjs'

const OurTech = () => {
  const [moreStories, setMoreStories] = useState([])
  const baseurl = 'https://race.pselier.com'

  const morestoriesAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    fetch('https://race.pselier.com/api/more-stories', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMoreStories(result.result)
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    morestoriesAPI()
  }, [])

  let navigate = useNavigate()
  const routeChange = () => {
    navigate('/vehicle-solution')
  }
  return (
    <>
      <section className="our_tech_main_section" id="blogs">
        <div className="tech_ring_img">
          <img src={tecg_ring} className="img-fluid" alt="img" loading="lazy" />
        </div>
        <div className="container">
          <div className="our_tech_main_white_section">
            <div className="our_tech_main_headings_div">
              <h1>The Nitty Gritty of Our Tech </h1>
              <p>
                Explore the inner workings of the world's most advanced
                swappable network built to drive India's electric vehicle
                industry.
              </p>
              <div className="Explore_our_technology_btn">
                <button onClick={routeChange}>
                  Explore our technology{' '}
                  <img
                    src={btn_arrow_down}
                    className="learn_arrow"
                    alt="arrow"
                  />
                </button>
              </div>
            </div>
            <div className="row">
              {moreStories?.home_blog?.slice(0, 1).map((value) => {
                return (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="tech_single_box_border">
                      <Link to={`/article/${value.slug}`}>
                        <div className="tech_single_box">
                          <div className="tech_read_more_btn">
                            <Link to={`/article/${value.slug}`}>
                              {' '}
                              Read More{' '}
                              <span>
                                {' '}
                                <img
                                  src={btn_arrow}
                                  className="learn_arrow"
                                  alt="arrow"
                                />
                              </span>{' '}
                            </Link>
                          </div>
                          <div className="tech_img_inner">
                            <img
                              src={
                                baseurl +
                                '/' +
                                'uploads/admin_blogs_images/' +
                                value?.featured_image
                              }
                              className="img-fluid"
                              alt="img"
                              loading="lazy"
                            />
                          </div>
                          <div className="tech_single_box_content">
                            <h3>
                              {dayjs(value?.created_at).format('MMM DD, YYYY')}
                            </h3>
                            <h2>{value.title}</h2>
                            <p>{value.short_description}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}

              {moreStories?.home_blog?.slice(1, 2).map((value) => {
                return (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="tech_single_box_border">
                      <Link to={`/article/${value.slug}`}>
                        <div className="tech_single_box">
                          <div className="tech_read_more_btn">
                            <Link to={`/article/${value.slug}`}>
                              {' '}
                              Read More{' '}
                              <span>
                                {' '}
                                <img
                                  src={btn_arrow}
                                  className="learn_arrow"
                                  alt="arrow"
                                />
                              </span>{' '}
                            </Link>
                          </div>
                          <div className="tech_img_inner">
                            <img
                              src={
                                baseurl +
                                '/' +
                                'uploads/admin_blogs_images/' +
                                value?.featured_image
                              }
                              className="img-fluid"
                              alt="img"
                              loading="lazy"
                            />
                          </div>
                          <div className="tech_single_box_content">
                            <h3>
                              {' '}
                              {dayjs(value?.created_at).format('MMM DD, YYYY')}
                            </h3>
                            <h2>{value.title}</h2>
                            <p>{value.short_description}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurTech
