import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Outlet, Link } from 'react-router-dom'
import logo from '../components/assets/imgs/logo.png'
import $ from 'jquery'

$(document).ready(function () {
  $('a').click(function () {
    $('a').removeClass('nav_active')
    $(this).addClass('nav_active')
  })
})

const Header = () => {
  const [expanded, setExpanded] = useState(false)

  const handleMenuClick = () => {
    setExpanded(false)
  }

  return (
    <>
      <Navbar
        bg=""
        expand="lg"
        className="header_bg"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/" onClick={handleMenuClick}>
              <img src={logo} className="img-fluid header_logo" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto header_menus">
              <Link className="anchor" to="/battery" onClick={handleMenuClick}>
                Battery
              </Link>
              <Link className="anchor" to="/network" onClick={handleMenuClick}>
                Network
              </Link>
              <Link
                className="anchor"
                to="/vehicle-solution"
                onClick={handleMenuClick}
              >
                Vehicle Solutions
              </Link>
              <Link className="anchor" to="/partner" onClick={handleMenuClick}>
                Partner
              </Link>
              <Link className="anchor" to="/vault" onClick={handleMenuClick}>
                Vault
              </Link>
              <Link className="anchor" to="/aboutus" onClick={handleMenuClick}>
                About us
              </Link>
              <Link className="anchor" to="/newsroom" onClick={handleMenuClick}>
                Newsroom
              </Link>
              <Link
                to="/contactus"
                className="theme_btn login_btn anchor"
                onClick={handleMenuClick}
              >
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />
    </>
  )
}

export default Header
