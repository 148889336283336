import React from 'react'

import partner_bg from '../assets/imgs/partners-bg.webp'
import $ from 'jquery'
import { useState } from 'react'
import { useEffect } from 'react'
const Partners = () => {
  const [partner, setPartner] = useState([])

  $(document).on('click mousemove', 'body', function (e) {
    var x = e.clientX
    var y = e.clientY
    var newposX = x - 160
    var newposY = y - 160
    $('.circle').css(
      'transform',
      'translate3d(' + newposX + 'px,' + newposY + 'px,0px)',
    )
  })

  const PartnerApi = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    fetch('https://race.pselier.com/api/partners', requestOptions)
      .then((response) => response.json())
      .then((result) => setPartner(result.result))
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    PartnerApi()
  }, [])

  return (
    <>
      <section className="partners_main_Section">
        <img
          src={partner_bg}
          className="img-fluid partner_bg"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="partners_main_Section_headings">
            <h1>Our Existing Partners</h1>
          </div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="row">
            <div className=" col-lg-6 col-md-6 col-sm-12 m-auto">
              <div className="row">
                {partner.map((currEle) => {
                  return (
                    <div className="col-6 col-lg-4 col-md-4 col-sm-12">
                      <div className="partner_box">
                        <img
                          src={currEle.logo}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Partners
