import React from 'react'
import AboutHero from './AboutHero'
import OurStory from './OurStory'
import OurTeam from './OurTeam'
import BoxIcons from './BoxIcons'
import TeamWork from './TeamWork'
import JoinOur from './JoinOur'
import News from './News'

const AboutMain = () => {
  return (
    <>
     <section>
        <AboutHero/>
        <OurStory/>
        <OurTeam/>
        <BoxIcons/>
        <TeamWork/>
        <JoinOur/>
        <News/>
     </section> 
    </>
  )
}

export default AboutMain
