import React, { useEffect, useRef } from 'react'
import slider from '../assets/imgs/custom/slider.webp'
import slider1 from '../assets/imgs/custom/slider1.webp'
import slider2 from '../assets/imgs/custom/slider2.webp'
import slider3 from '../assets/imgs/custom/slider3.webp'
import Carousel from 'react-bootstrap/Carousel'

const CustomSlider = () => {
  const carouselRef = useRef(null)
  useEffect(() => {
    const intervalId = setInterval(() => {
      carouselRef.current.next()
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <>
      <section className="Custom_Slider_main_section">
        <Carousel ref={carouselRef} fade>
          <Carousel.Item>
            <img src={slider} className="img-fluid" alt="img" loading="lazy" />
            <h1>
              Vehicle Solutions <br /> Curated to Your Needs{' '}
            </h1>
          </Carousel.Item>
          <Carousel.Item>
            <img src={slider1} className="img-fluid" alt="img" loading="lazy" />
            <h1>
              Vehicle Solutions <br /> Curated to Your Needs{' '}
            </h1>
          </Carousel.Item>
          <Carousel.Item>
            <img src={slider2} className="img-fluid" alt="img" loading="lazy" />
            <h1>
              Vehicle Solutions <br /> Curated to Your Needs{' '}
            </h1>
          </Carousel.Item>
          <Carousel.Item>
            <img src={slider3} className="img-fluid" alt="img" loading="lazy" />
            <h1>
              Vehicle Solutions <br /> Curated to Your Needs{' '}
            </h1>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  )
}

export default CustomSlider
