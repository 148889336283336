import React, { useEffect, useState } from 'react'
import linkedin from '../assets/imgs/About/linkedin.webp'

import team_bg from '../assets/imgs/About/team-bg.webp'
import { Link } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'

const OurTeam = () => {
  const [team, setTeam] = useState([])
  const baseurl = 'https://race.pselier.com'
  // console.log(team)

  const getTeams = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/team-members', requestOptions)
      .then(response => response.json())
      .then(result => {
        const newRes = result.result.map(item => ({ ...item, toggle: false }))
        setTeam(newRes)
      })
      .catch(error => console.log('error', error))
  }
  useEffect(() => {
    getTeams()
    console.log('team', team)
  }, [])

  const chunkSize = 2
  const teamChunks = []
  for (let i = 0; i < team.length; i += chunkSize) {
    teamChunks.push(team.slice(i, i + chunkSize))
  }

  const [expanded, setExpanded] = useState([])

  const toggleContent = id => {
    const newRes = team.map(book =>
      book.id === id ? { ...book, toggle: !book.toggle } : book
    )
    setTeam(newRes)
  }

  return (
    <>
      <section className='Our_Team_section' id='team'>
        <img
          src={team_bg}
          className='img-fluid team_bg'
          alt='img'
          loading='lazy'
        />

        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h1 className='main_heading'>Our Team </h1>
            </div>

            <section className='team_section_desktop'>
              <div className='row'>
                <div className='  col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                  <div className='team_single_box_border'>
                    <div className='team_single_box'>
                      <div className='team_single_box_inner'>
                        <div className='img_side'>
                          <img
                            src={
                              baseurl +
                              '/' +
                              'uploads/team_member_images/' +
                              team[0]?.image
                            }
                            className='img-fluid team_img'
                            alt='img'
                            loading='lazy'
                          />
                          <div className='team_name_div_flex'>
                            <div>
                              <h3>{team[0]?.name}</h3>
                              <h4>{team[0]?.designation}</h4>
                            </div>
                            <div>
                              <Link
                                to={team[0]?.linkedIn_profile_link}
                                target='_blank'
                              >
                                {' '}
                                <img
                                  src={linkedin}
                                  className='img-fluid '
                                  alt='img'
                                  loading='lazy'
                                />{' '}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className='content_side'>
                          <p>{team[0]?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='  col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                  <div className='team_single_box_border'>
                    <div className='team_single_box'>
                      <div className='team_single_box_inner'>
                        <div className='img_side'>
                          <img
                            src={
                              baseurl +
                              '/' +
                              'uploads/team_member_images/' +
                              team[1]?.image
                            }
                            className='img-fluid team_img'
                            alt='img'
                            loading='lazy'
                          />
                          <div className='team_name_div_flex'>
                            <div>
                              <h3>{team[1]?.name}</h3>
                              <h4>{team[1]?.designation}</h4>
                            </div>
                            <div>
                              <Link
                                to={team[1]?.linkedIn_profile_link}
                                target='_blank'
                              >
                                {' '}
                                <img
                                  src={linkedin}
                                  className='img-fluid '
                                  alt='img'
                                  loading='lazy'
                                />{' '}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className='content_side'>
                          <p>{team[1]?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className='team_section_desktop'>
            <div className='row'>
              {team.slice(2).map(currEle => {
                expanded[currEle.id] = false
                return (
                  <>
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                      <div className='team_small_box_border'>
                        <div
                          className='team_small_box'
                          style={{ height: expanded ? 'auto' : '200px' }}
                        >
                          <div className='team_small_box_img'>
                            <img
                              src={
                                baseurl +
                                '/' +
                                'uploads/team_member_images/' +
                                currEle?.image
                              }
                              className='img-fluid'
                              alt='img'
                              loading='lazy'
                            />
                          </div>
                          <div className='team_name_div_flex'>
                            <div>
                              <h3>{currEle.name}</h3>
                              <h4>{currEle.designation}</h4>
                            </div>
                            <div>
                              <Link
                                to={currEle.linkedIn_profile_link}
                                target='_blank'
                              >
                                {' '}
                                <img
                                  src={linkedin}
                                  className='img-fluid '
                                  alt='img'
                                  loading='lazy'
                                />{' '}
                              </Link>
                            </div>
                          </div>
                          <p>{currEle.description}</p>
                          {/* <p>
                            {currEle.toggle
                              ? currEle.description
                              : currEle.description.slice(0, 220)}
                            {currEle.description.length > 220 && (
                              <button
                                className='team_read_more_btn'
                                onClick={() => {
                                  toggleContent(currEle.id)
                                }}
                              >
                                <span style={{ marginLeft: '4px' }}>
                                  {' '}
                                  {currEle.toggle ? ' Show Less' : ' Show More'}
                                </span>
                              </button>
                            )}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </section>
        </div>
      </section>

      {/* mobile  */}
      <section className='team_section_mobile'>
        <img
          src={team_bg}
          className='img-fluid team_bg2'
          alt='img'
          loading='lazy'
        />

        <Carousel interval={6000} indicators={true}>
          {teamChunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className='container'>
                <div className='row'>
                  {chunk.map(teamMember => (
                    <div
                      className='col-12 col-md-6 col-sm-12'
                      key={teamMember.id}
                    >
                      <div className='team_small_box_border'>
                        <div className='team_small_box'>
                          <img
                            // src={teamMember.image}
                            src={
                              baseurl +
                              '/' +
                              'uploads/team_member_images/' +
                              teamMember.image
                            }
                            className='img-fluid'
                            alt='img'
                            loading='lazy'
                          />
                          <div className='team_name_div_flex'>
                            <div>
                              <h3>{teamMember.name}</h3>
                              <h4>{teamMember.designation}</h4>
                            </div>
                            <div>
                              <a
                                href={teamMember.linkedIn_profile_link}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <img
                                  src={linkedin}
                                  className='img-fluid'
                                  alt='img'
                                  loading='lazy'
                                />
                              </a>
                            </div>
                          </div>
                          <p>{teamMember.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </>
  )
}

export default OurTeam
