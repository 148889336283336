import React, { useEffect, useState } from 'react'
import faq_bg from '../assets/imgs/faq-bg.webp'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accordion from 'react-bootstrap/Accordion'
import footer_bg from '../assets/imgs/footer-bg.webp'
import faq_bg1 from '../assets/imgs/faq-bg-1.webp'
import faq_bg2 from '../assets/imgs/faq-bg-2.webp'
import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im'

const Faq = () => {
  const [FaqCat, setFaqCat] = useState([])
  const [currentTab, setCurrentTab] = useState(0) // Initialize with the first tab
  const [activeTabKey, setActiveTabKey] = useState(FaqCat[currentTab]?.name)

  const getFaqCategories = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/faq-categories', requestOptions)
      .then(response => response.json())
      .then(result => setFaqCat(result.result))
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    getFaqCategories()
  }, [])

  // Function to handle "Next" button click
  const handleNextClick = () => {
    setCurrentTab(prevTab => (prevTab + 1) % FaqCat.length)
    setActiveTabKey(FaqCat[currentTab]?.name)
  }

  // Function to handle "Back" button click
  const handleBackClick = () => {
    setCurrentTab(prevTab => (prevTab - 1 + FaqCat.length) % FaqCat.length)
    setActiveTabKey(FaqCat[currentTab]?.name)
  }

  // Function to handle tab selection
  const handleTabSelect = selectedTabKey => {
    setActiveTabKey(selectedTabKey)
  }

  return (
    <>
      <section className='faq_main_section ' id='FAQ'>
        <img src={footer_bg} alt='img' className='img-fluid footer_bg' />
        <img
          src={faq_bg}
          className='img-fluid faq_bg_img'
          alt='img'
          loading='lazy'
        />
        <img
          src={faq_bg1}
          className='img-fluid faq_bg_img1'
          alt='img'
          loading='lazy'
        />
        <img
          src={faq_bg2}
          className='img-fluid faq_bg_img2'
          alt='img'
          loading='lazy'
        />

        <div className='container'>
          <div className='faq_main_headings'>
            <h1>FAQ</h1>
          </div>

          <div className='tabs_div '>
            <Tabs
              activeKey={activeTabKey} // Use the activeTabKey state for the active tab
              id='uncontrolled-tab-example'
              className='mb-3 custom-tabs'
              onSelect={handleTabSelect} // Handle tab selection
            >
              {FaqCat.map(currEle => {
                return (
                  <Tab
                    key={currEle.id}
                    eventKey={currEle.name}
                    title={currEle.name}
                  >
                    <Accordion flush>
                      {currEle.faqs.map(currEles => {
                        return (
                          <Accordion.Item
                            key={currEles.id}
                            eventKey={currEles.id}
                            className='faq_sibgle_box'
                          >
                            <Accordion.Header className=''>
                              <h2> {currEles.question}</h2>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>{currEles.answer}</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </Tab>
                )
              })}
            </Tabs>

            <div className='faq_slider_buttons_div'>
              <button
                className='faq_slider_buttons right_gap'
                onClick={handleBackClick}
              >
                {' '}
                <ImArrowLeft2 className='slider_arrow' />{' '}
              </button>

              <button
                className='faq_slider_buttons right_gap'
                onClick={handleNextClick}
              >
                <ImArrowRight2 className='slider_arrow' />{' '}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq
