import React from 'react'
import VaultHero from './VaultHero'
import RACEVaultSlider from './RACEVaultSlider'
import PlantBG from './PlantBG'
import InvestForm from './InvestForm'
import InvestorsSlider from './InvestorsSlider'
import ReducePollution from './ReducePollution'

const VaultMain = () => {
  return (
    <>
      <VaultHero/>
      <RACEVaultSlider/>
      <PlantBG/>
      <InvestForm/>
      <InvestorsSlider/>
      <ReducePollution/>
    </>
  )
}

export default VaultMain
