import React from 'react'
import onebattery1 from '../assets/imgs/custom/onebattery1.png'
import onebattery2 from '../assets/imgs/custom/onebattery2.png'
import onebattery3 from '../assets/imgs/custom/onebattery3.png'

const OneBattery = () => {
  return (
    <>
      <section className="OneBattery_main_section">
        <div className="container">
          <div className="OneBattery_main_headings">
            <h1>One Battery. Any Vehicle​ </h1>
            <p>
              Tailor-made solutions are brought to life in one week for any
              vehicle.​
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 OneBattery_single_box">
              <img
                src={onebattery1}
                alt="img"
                loading="lazy"
                className="img-fluid OneBattery_single_box_img_one"
              />
              <h3>Connected Vehicles​</h3>
              <p>
                Powered by 4G LTE and Bluetooth, it connects and controls all of
                your vehicles.
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 OneBattery_single_box">
              <img
                src={onebattery2}
                alt="img"
                loading="lazy"
                className="img-fluid OneBattery_single_box_img_two"
              />
              <h3>Instant Battery Insights</h3>
              <p>
                Get alerts on battery temperature, current, voltage, state of
                charge and health monitoring.​{' '}
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 OneBattery_single_box">
              <img
                src={onebattery3}
                alt="img"
                loading="lazy"
                className="img-fluid OneBattery_single_box_img_three"
              />
              <h3>Seamless & Automatic Swap​​</h3>
              <p>
                Swap batteries in seconds, make cashless payments and leave
                battery maintenance to us.{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OneBattery
