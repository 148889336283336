import React, { useEffect } from 'react'
import './components/assets/css/style.css'
import './components/assets/css/partner.css'
import './components/assets/css/newsroom.css'
import './components/assets/css/article.css'
import './components/assets/css/vault.css'
import './components/assets/css/battery.css'
import './components/assets/css/custom-solution.css'
import './components/assets/css/contact.css'
import './components/assets/css/network.css'
import './components/assets/css/commaonform.css'
import './components/assets/css/resoinsive.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Main from './components/Main'
import Header from './components/Header'
import Footer from './components/Footer'

import { HashRouter, Routes, Route, useLocation } from 'react-router-dom'
import AboutMain from './components/Aboutus/AboutMain'
import PartnerMain from './components/Partnership/PartnerMain'
import NewsroomMain from './components/Newsroom/NewsroomMain'
import ArticleMain from './components/Article/ArticleMain'
import VaultMain from './components/Vault/VaultMain'
import BatteryMain from './components/Battery/BatteryMain'
import CustomSolutionMain from './components/CustomSolution/CustomSolutionMain'
import ContactMain from './components/Contactus/ContactMain'
import NetworkMain from './components/Network/NetworkMain'
import ThankYou from './components/ThankYou'

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <div className="heder_main_div">
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="aboutus" element={<AboutMain />} />
        <Route path="partner" element={<PartnerMain />} />
        <Route path="newsroom" element={<NewsroomMain />} />
        {/* <Route path="article" element={<ArticleMain />} /> */}
        <Route path="/article/:slug" element={<ArticleMain />} />
        <Route path="vault" element={<VaultMain />} />
        <Route path="battery" element={<BatteryMain />} />
        <Route path="vehicle-solution" element={<CustomSolutionMain />} />
        <Route path="contactus" element={<ContactMain />} />
        <Route path="network" element={<NetworkMain />} />
        <Route path="thankyou" element={<ThankYou />} />
      </Routes>
      <Footer id="section1" />
    </>
  )
}

export default App
