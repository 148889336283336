import React, { useState, useEffect } from 'react'
import ArticleHero from './ArticleHero'

import Recommended_Reads from './Recommended_Reads'
import { useParams } from 'react-router-dom'

const ArticleMain = () => {
  const [singleBlog, setSingleBlog] = useState([])

  const { slug } = useParams()

  const SingleBlogAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`https://race.pselier.com/api/blog-by-slug/${slug}`, requestOptions)
      .then(response => response.json())
      .then(result => setSingleBlog(result))
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    SingleBlogAPI()
  }, [slug])

  return (
    <>
      <section className='article_main_width'>
        <ArticleHero singleBlog={singleBlog} />
      </section>
      <Recommended_Reads />
    </>
  )
}

export default ArticleMain
