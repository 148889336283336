import React from 'react'
import gif from '../assets/imgs/battery/no plug and play.webm'

const GIFSection = () => {
  return (
    <>
      <section className="gif_number_section">
        <div className="img_box">
          <video
            className="desktop_img"
            autoPlay
            muted
            width="100%"
            height="100%"
            loop
          >
            <source src={gif} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <video
          className="mob_img"
          autoPlay
          muted
          width="100%"
          height="100%"
          loop
        >
          <source src={gif} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
    </>
  )
}

export default GIFSection
