import React, { useState, useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import eauto_slider_1 from '../assets/imgs/custom/eauto_slider_1.webp'
import eauto_slider_2 from '../assets/imgs/custom/eauto_slider_2.webp'
import eauto_slider_3 from '../assets/imgs/custom/eauto_slider_3.webp'
import eauto_slider_4 from '../assets/imgs/custom/eauto_slider_4.webp'
import eauto_slider_5 from '../assets/imgs/custom/eauto_slider_5.webp'
import auto_mob_1 from '../assets/imgs/custom/auto_mob_1.png'
import auto_mob_2 from '../assets/imgs/custom/auto_mob_2.png'
import auto_mob_3 from '../assets/imgs/custom/auto_mob_3.png'
import auto_mob_4 from '../assets/imgs/custom/auto_mob_4.png'
import auto_mob_5 from '../assets/imgs/custom/auto_mob_5.png'
import Assumptions1 from '../assets/imgs/custom/Assumptions1.png'
import Assumptions2 from '../assets/imgs/custom/Assumptions2.png'
import Assumptions3 from '../assets/imgs/custom/Assumptions3.png'
import Dropdown from 'react-bootstrap/Dropdown'
import e_auto_bg from '../assets/imgs/testimonial_bg.webp'
import eauto_bg from '../assets/imgs/custom/eauto_bg.webp'
import e_1 from '../assets/imgs/custom/e-1.webp'
import e_2 from '../assets/imgs/custom/e-2.webp'
import e_3 from '../assets/imgs/custom/e-3.webp'
import e_4 from '../assets/imgs/custom/e-4.webp'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import TextField from '@mui/material/TextField'
import { FiArrowUpRight } from 'react-icons/fi'
import Modal from 'react-bootstrap/Modal'
import EautoTableSlider from './EautoTableSlider'
import { useNavigate } from 'react-router-dom'

const EautoSection = () => {
  const navigate = useNavigate()

  // ===========Modal after form submit==========

  const [selectedcount, setselectedcount] = useState()
  const [Vehiclecount, SetVehiclecount] = useState()
  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)

  // ===========Hooks for form Fields==========

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    partnership_type: '',
    investment_range: '',
    investment_range_f: '',
    selectedValue_race: '',
    company_name: '',
    previous_experience: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',
    type_of_vehicle: '',
    annual_vehicle_requirement: '',
    location_of_sale: '',
    contents: '',
    hear_about_us: '',
    new_fields: '',
    page_name: ''
  })

  const {
    name,
    email,
    phone,
    partnership_type,
    investment_range,
    investment_range_f,
    hear_about_us,
    company_name,
    previous_experience,
    city,
    state,
    country,
    pincode,
    annual_vehicle_requirement,
    location_of_sale,
    contents
  } = formData

  // ===========Current page path==========

  const currentPath = window.location.pathname

  const [index, setIndex] = useState(0)
  const [radioInput, setradioInput] = useState('area-partner')

  const [selectedValue_investment, setSelectedValue_investment] = useState('')
  const [labelPosition, setLabelPosition] = useState('')

  const [selectedValue_power, setSelectedValue_power] = useState('')
  const [labelPosition_power, setLabelPosition_power] = useState('')

  const [selectedValue_space, setSelectedValue_space] = useState('')
  const [labelPosition_space, setLabelPosition_space] = useState('')

  const [selectedValue_investment_f, setSelectedValue_investment_f] =
    useState('')
  const [labelPosition_investment_f, setlabelPosition_investment_f] =
    useState('')

  const [selectedValue_race, setSelectedValue_race] = useState('')
  const [labelPosition_race, setLabelPosition_race] = useState('')

  const handleSelectChange_investment = event => {
    setSelectedValue_investment(event.target.value)
    setLabelPosition(event.target.value ? 'top' : '')
  }

  const handleSelectChange_power = event => {
    setSelectedValue_power(event.target.value)
    setLabelPosition_power(event.target.value ? 'top' : '')
  }
  const handleSelectChange_space = event => {
    setSelectedValue_space(event.target.value)
    setLabelPosition_space(event.target.value ? 'top' : '')
  }

  const handleSelectChange_investment_f = event => {
    setSelectedValue_investment_f(event.target.value)
    setlabelPosition_investment_f(event.target.value ? 'top' : '')
  }
  const handleSelectChange_race = event => {
    setSelectedValue_race(event.target.value)
    setLabelPosition_race(event.target.value ? 'top' : '')
  }
  const handleChange = e => {
    console.log(e.target.value)
    setradioInput(e.target.value)
  }

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const carouselInterval = setInterval(() => {}, 5000)

    setIntervalId(carouselInterval)

    return () => clearInterval(carouselInterval)
  }, [])

  const handleMouseEnter = () => {
    clearInterval(intervalId)
  }

  const handleMouseLeave = () => {
    const newInterval = setInterval(() => {}, 5000)

    setIntervalId(newInterval)
  }

  // =============Contact us from submit==================

  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    handleShow_success()
    setFormData('')
    navigate('/thankyou')
    // if (
    //   name != '' &&
    //   email != '' &&
    //   phone != '' &&
    //   hearInput_string != '' &&
    //   selectedValue_investment != '' &&
    //   company_name != '' &&
    //   previous_experience != '' &&
    //   city != '' &&
    //   state != '' &&
    //   country != '' &&
    //   pincode != ''
    // ) {
    //   contactUsApi()
    //   handleShow_success()
    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // =============contactUs Api call==================

  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')
    formdata.append('investment_range', selectedValue_investment ?? '')
    formdata.append('company_name', company_name ?? '')
    formdata.append('previous_experience', previous_experience ?? '')
    formdata.append('city', city ?? '')
    formdata.append('state', state ?? '')
    formdata.append('country', country ?? '')
    formdata.append('pincode', pincode ?? '')
    formdata.append('hear_about_us', hearInput_string ?? '')
    formdata.append('page_name', currentPath ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // =============Hear about us==================

  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  // =============Vehicle Type==================

  const [vehicleTypeInput, setvehicleTypeInput] = useState([])
  const vehicleTypeInput_string = vehicleTypeInput.join(', ')

  const handleChangeVehicleType = e => {
    let VehicleTypeValue = [...vehicleTypeInput]

    if (e.target.checked) {
      console.log(e.target.value)
      VehicleTypeValue = [...vehicleTypeInput, e.target.value]
      if (VehicleTypeValue) {
        setvehicleTypeInput(VehicleTypeValue)
        console.log(VehicleTypeValue)
      }
    } else {
      VehicleTypeValue.splice(vehicleTypeInput.indexOf(e.target.value), 1) // araay list
      setvehicleTypeInput(VehicleTypeValue)
      console.log(VehicleTypeValue)
    }
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <>
      <section className='e_auto_main_section'>
        <img
          src={e_auto_bg}
          alt='img'
          loading='lazy'
          className='img-fluid e_auto_bg'
        />
        <img
          src={eauto_bg}
          alt='img'
          loading='lazy'
          className='img-fluid eauto_bg'
        />

        <section className='fleet_main_section'>
          <div className='container'>
            <div className='fleet_main_section_headings'>
              <h1>Go Electric With Our Powerful E-Auto. ​</h1>
              <p>
                Our powerful electric vehicles have been carefully developed
                with our OEM (Original Equipment Manufacturer) partners for the
                auto-rickshaw market{' '}
              </p>
            </div>

            {/* slider */}
            <section className='tabs_slider_main_section'>
              <Carousel
                fade
                prevIcon=<button
                  disabled={index == 0}
                  className=' back_btn'
                ></button>
                nextIcon=<button
                  disabled={index == 4}
                  className='next_btn'
                ></button>
                onSelect={handleSelect}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Carousel.Item>
                  <img
                    src={eauto_slider_1}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_desktop_img'
                  />
                  <img
                    src={auto_mob_1}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_mob_img'
                  />

                  <Carousel.Caption className='carousel_caption'>
                    <h3>Most Powerful E-Auto Rickshaw</h3>
                    <p>
                      With the incredible instant torque of an electric motor,
                      carry 4 people easily on steep inclines of 14° (25%).
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    src={eauto_slider_2}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_desktop_img'
                  />
                  <img
                    src={auto_mob_2}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_mob_img'
                  />

                  <Carousel.Caption className='carousel_caption'>
                    <h3>Smart & Connected With 4G, GPS, BLE 5.0 </h3>
                    <p>
                      Our e-autos are equipped with smart technology such as 4G
                      connectivity, Bluetooth 5.0, and smart alerts.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    src={eauto_slider_3}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_desktop_img'
                  />
                  <img
                    src={auto_mob_3}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_mob_img'
                  />

                  <Carousel.Caption className='carousel_caption'>
                    <h3>Fully Digital Instrument Cluster </h3>
                    <p>
                      Digital cluster enables personalization, provides varied
                      information for the driver and allows flexibility of
                      display.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={eauto_slider_4}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_desktop_img'
                  />
                  <img
                    src={auto_mob_4}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_mob_img'
                  />

                  <Carousel.Caption className='carousel_caption'>
                    <h3>No Clutch, No Gear Changes, No Vibration </h3>
                    <p>
                      Drive easily and automatically with RACE e-auto that
                      provides steady and instant power without the need for a
                      clutch or gear.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={eauto_slider_5}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_desktop_img'
                  />
                  <img
                    src={auto_mob_5}
                    alt='img'
                    loading='lazy'
                    className='img-fluid vehicle_sol_mob_img'
                  />

                  <Carousel.Caption className='carousel_caption'>
                    <h3>Always up to Date </h3>
                    <p>
                      RACE batteries and e-autos are constantly upgraded with
                      cutting-edge technology to provide a powerful drive,
                      high-performance vehicle, and automated smart ecosystem.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </section>

            {/* table section */}
            <section className='graph_table_section_main'>
              <section className='eauto_table_main_section'>
                <div className='eauto_table_main_headings'>
                  <h3>Reduce Upfront Cost of Your EV Auto With RACE </h3>
                  <p className='para_white'>
                    Get easier upgrade to newer & evolving battery technologies
                    with RACE e-auto.
                  </p>
                  <p className='para_blue'>
                    Total cost of ownership comparison - over 8 year vehicle
                    life{' '}
                  </p>
                </div>
                <div className='scroll_content scroll_content_gap_left'>
                  <div className='row '>
                    <div className='col-lg-1 col-md-0'></div>
                    <div className='col-2 col-lg-2 col-md-3'>
                      <div className='e_auto_graph_first_col'>
                        <div className='e_auto_graph_first_col_div'>
                          <h2>Total one time cost</h2>
                        </div>
                        <div className='e_auto_graph_first_col_div'>
                          <h2>Lifetime energy cost</h2>
                        </div>
                        <div className='e_auto_graph_first_col_div'>
                          <h2 className='pb_0 mb_00'>
                            Monthly maintenance cost
                          </h2>
                          <h2 className='pt_0'>Lifetime maintenance cost</h2>
                        </div>
                        <div className='e_auto_graph_first_col_div '>
                          <h2 className='white_space_one'>
                            Battery/Engine Replacement cost
                          </h2>
                        </div>
                        <div className='e_auto_graph_first_col_div'>
                          <h2>Total cost of ownership </h2>
                        </div>
                      </div>
                    </div>
                    <div className='col-2 col-lg-2 col-md-2'>
                      <div className='e_auto_graph_other_cols_border'>
                        <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_one'>
                          <h3 className='headings'>RACE/OEM Vehicle</h3>
                          <img src={e_1} className='auto_img img-fluid' />
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 2,50,000/-</h4>
                            <h5>No Permit Required</h5>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 4,62,400/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 200/-</h4>
                            <h4>Rs. 19,200/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                            <h4>-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_bottoms'>
                            <h4
                              style={{ color: '#17E884', marginTop: '16px' }}
                              className='high_light_text'
                            >
                              Rs. 7,31,600/-
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-2 col-lg-2 col-md-2'>
                      <div className='e_auto_graph_other_cols_border'>
                        <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_one'>
                          <h3 className='headings'>
                            RACE <br /> Retrofit
                          </h3>
                          <img src={e_2} className='auto_img img-fluid' />
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 1,50,000/-</h4>
                            <h5>No Permit Required</h5>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 6,80,000/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 200/-</h4>
                            <h4>Rs. 19,200/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                            <h4>-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_bottoms'>
                            <h4
                              style={{ color: '#17E884', marginTop: '16px' }}
                              className='high_light_text'
                            >
                              Rs. 8,49,200/-
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-2 col-lg-2 col-md-2'>
                      <div className='e_auto_graph_other_cols_border_two'>
                        <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_two'>
                          <h3 className='headings'>CNG</h3>
                          <img src={e_3} className='auto_img img-fluid' />
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 4,00,000/-</h4>
                            <h5>Incl. Permit in Hyderabad</h5>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 8,16,000/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 2,000/-</h4>
                            <h4>Rs. 1,92,000/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                            <h4>Rs. 53,333/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_bottoms'>
                            <h4
                              style={{ color: '#EB5757', marginTop: '16px' }}
                              className='high_light_text'
                            >
                              Rs. 14,61,333/-
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-2 col-lg-2 col-md-2'>
                      <div className='e_auto_graph_other_cols_border_two'>
                        <div className='e_auto_graph_other_cols e_auto_graph_other_cols_bg_two'>
                          <h3 className='headings'>
                            EV Fixed <br /> Battery
                          </h3>
                          <img src={e_4} className='auto_img img-fluid' />
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 3,30,000/-</h4>
                            <h5>No Permit Required</h5>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 2,72,000/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms'>
                            <h4>Rs. 200/-</h4>
                            <h4>Rs. 19,200/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_top_bottoms border_tops'>
                            <h4>Rs. 3,00,000/-</h4>
                          </div>
                          <div className='e_auto_graph_small_box border_bottoms'>
                            <h4
                              style={{ color: '#EB5757', marginTop: '16px' }}
                              className='high_light_text'
                            >
                              Rs. 9,21,200/-
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <EautoTableSlider />

              <section className='Assumptions_section'>
                <div className='row '>
                  <div className='col-lg-1 col-md-0'></div>
                  <div className='col-lg-10 col-md-12 m-auto'>
                    <div className='Assumptions_section_headings border_top'>
                      <h1>Assumptions</h1>
                    </div>
                  </div>

                  <div className='col-lg-9 col-md-12 m-auto'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='Assumptions_single_col'>
                          <img
                            src={Assumptions1}
                            className='img-fluid Engine_icon'
                          />
                          <div>
                            <h3>Engine overhaul costs</h3>
                            <p>Rs. 20,000/Every 3 years</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='Assumptions_single_col'>
                          <img src={Assumptions2} className='img-fluid' />
                          <div>
                            <h3>Battery Replacement costs</h3>
                            <p>Rs. 1,50,000/Every 4 years</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='Assumptions_single_col'>
                          <img src={Assumptions3} className='img-fluid' />
                          <div>
                            <h3>Permit costs</h3>
                            <p>Vary from city to city</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>

            {/* form section */}
            <section className='fleet_form_section_main'>
              <div className='fleet_form_section_main_headings'>
                <h4>Interested in Being Our Area Partner?​</h4>
                <p>
                  Partner with us to deploy the most advanced swappable
                  batteries and high-performing e-autos and earn high returns on
                  your investments.
                </p>
              </div>
              <section className='Battery_Form_main_section'>
                <div className=''>
                  <section className='invest_form_main_border'>
                    <div className='invest_form_main_box'>
                      <h3>
                        Subject: Hey! I am interested in being a Area partner.🙂
                      </h3>
                      <form
                        className='footprint_form'
                        onSubmit={Contactus_from_submit}
                        autoComplete='off'
                      >
                        <div className='row'>
                          <div className='col-lg-10'>
                            <div className='row'>
                              {/* name */}
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='name'
                                    value={formData.name ?? ''}
                                    onChange={onChange}
                                    required
                                  />
                                  <label className='did-floating-label'>
                                    Name*
                                  </label>
                                </div>
                              </div>
                              {/* email */}
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='email'
                                    placeholder=' '
                                    name='email'
                                    value={formData.email ?? ''}
                                    onChange={onChange}
                                    required
                                  />
                                  <label className='did-floating-label'>
                                    Email Id*
                                  </label>
                                </div>
                              </div>
                              {/* phone number */}
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='number'
                                    placeholder=' '
                                    name='phone'
                                    value={formData.phone ?? ''}
                                    onChange={onChange}
                                    required
                                  />
                                  <label className='did-floating-label'>
                                    Phone number*
                                  </label>
                                </div>
                              </div>

                              {/* Where did you .... */}
                              <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <Dropdown className='custom_drop_down'>
                                    <Dropdown.Toggle
                                      variant=''
                                      className='custom_drop_down_btn'
                                      id='dropdown-basic'
                                    >
                                      {selectedcount ? (
                                        <span> {selectedcount} Selected</span>
                                      ) : (
                                        <span>
                                          Where did you hear about us?
                                        </span>
                                      )}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Search Engine (Google, Bing, etc.)'
                                          id='flexCheckChecked1'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked1'
                                        >
                                          Search Engine (Google, Bing, etc.)
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Recommended by a Friend/Colleague'
                                          id='flexCheckChecked2'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked2'
                                        >
                                          Recommended by a Friend/Colleague
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Social Media (LinkedIn, Twitter)'
                                          id='flexCheckChecked3'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked3'
                                        >
                                          Social Media (LinkedIn, Twitter)
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Blog/Publication'
                                          id='flexCheckChecked4'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked4'
                                        >
                                          Blog/Publication
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Signage'
                                          id='flexCheckChecked5'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked5'
                                        >
                                          Signage
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Networking Event'
                                          id='flexCheckChecked6'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked6'
                                        >
                                          Networking Event
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Startup Founder'
                                          id='flexCheckChecked7'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked7'
                                        >
                                          Startup Founder
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Word of Mouth'
                                          id='flexCheckChecked8'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked8'
                                        >
                                          Word of Mouth
                                        </label>
                                      </div>
                                      <div className='form-check'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          name='hearAboutUs'
                                          value='Others'
                                          id='flexCheckChecked9'
                                          onClick={handleChangeHearAbout}
                                        />
                                        <label
                                          className='form-check-label'
                                          for='flexCheckChecked9'
                                        >
                                          Others
                                        </label>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              {/* small heading */}
                              <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h4 className=''>RACE Area Partner</h4>
                              </div>

                              <section>
                                <div className=''>
                                  <div className='form_radio_buttons'>
                                    {/* tab content */}
                                    <div className=''>
                                      {/* Area partner */}
                                      <div className='new_form_tabs_content_main_single'>
                                        <div className='row'>
                                          <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className='select-container mb_48'>
                                              <label
                                                className={`select-label ${labelPosition}`}
                                              >
                                                Investment range*
                                              </label>
                                              <select
                                                className='new_select__box'
                                                value={selectedValue_investment}
                                                onChange={
                                                  handleSelectChange_investment
                                                }
                                                required
                                              >
                                                <option value=''></option>
                                                <option value='Less than 1 Lakh'>
                                                  Less than 1 Lakh
                                                </option>
                                                <option value='1- 10 Lakh'>
                                                  1- 10 Lakh
                                                </option>
                                                <option value='10 Lakh- 1 Cr'>
                                                  10 Lakh- 1 Cr
                                                </option>
                                                <option value='More than 1 Cr'>
                                                  More than 1 Cr
                                                </option>
                                              </select>
                                            </div>
                                          </div>

                                          {/* company name */}
                                          <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_48'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='company_name'
                                                value={
                                                  formData.company_name ?? ''
                                                }
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                Company Name
                                              </label>
                                            </div>
                                          </div>

                                          {/* Please help us understand your previous experience.  */}
                                          <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_48'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='previous_experience'
                                                value={
                                                  formData.previous_experience ??
                                                  ''
                                                }
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                Please help us understand your
                                                previous experience.
                                              </label>
                                            </div>
                                          </div>

                                          {/* small heading */}
                                          <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <h4 className=''>
                                              Preferred Partnership Location
                                            </h4>
                                          </div>

                                          {/* City */}
                                          <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_24'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='city'
                                                value={formData.city ?? ''}
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                City
                                              </label>
                                            </div>
                                          </div>
                                          {/* State */}
                                          <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_24'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='state'
                                                value={formData.state ?? ''}
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                State
                                              </label>
                                            </div>
                                          </div>
                                          {/* Country */}
                                          <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_24'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='country'
                                                value={formData.country ?? ''}
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                Country
                                              </label>
                                            </div>
                                          </div>
                                          {/* Pincode */}
                                          <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className='did-floating-label-content mb_24'>
                                              <input
                                                className='did-floating-input'
                                                type='text'
                                                placeholder=' '
                                                name='pincode'
                                                value={formData.pincode ?? ''}
                                                onChange={onChange}
                                              />
                                              <label className='did-floating-label'>
                                                Pincode
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                          <div className='col-lg-2'>
                            <div className='form_submit_btn form_submit_btn_flex_end'>
                              <button className='' type='submit'>
                                Submit{' '}
                                <img
                                  src={btn_arrow}
                                  className='learn_arrow'
                                  alt='arrow'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </section>
            </section>
          </div>
        </section>
      </section>

      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </>
  )
}

export default EautoSection
