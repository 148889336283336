import React, { useRef, useEffect } from 'react'
import box from '../assets/imgs/custom/Vehicle.webm'
import box_ring from '../assets/imgs/custom/custom-ring.webp'

const CustomHero = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch((error) => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className="custom_hero_main_section">
        <div className="container">
          <div className="box">
            <video
              ref={videoRef}
              autoPlay
              muted
              width="100%"
              height="100%"
              loop
              className="banner_img"
            >
              <source src={box} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <img
            src={box_ring}
            alt="img"
            loading="lazy"
            className="img-fluid box_ring"
          />
        </div>
      </section>
    </>
  )
}

export default CustomHero
