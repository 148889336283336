import React from 'react'
import EautoSection from './EautoSection'
import OEMSection from './OEMSection'
import $ from 'jquery'
import FleetSection from './FleetSection'

const SolutionTabs = () => {
  $(document).ready(function () {
    $('.solution_tabs_btns').click(function () {
      $('.solution_tabs_btns').removeClass('actives')
      $(this).addClass('actives')
    })
  })
  return (
    <>
      {/* <a id='Fleet-OEM' className='section-scroll-link' href='#'></a> */}

      <section className='solution_tabs_main_section'>
        <div className='container'>
          <div className='solution_tabs_btns_main_div'>
            <button className='solution_tabs_btns  actives'>
              <a href='#Fleet'> Fleet</a>
            </button>
            <button className='solution_tabs_btns '>
              <a href='#E-Auto'> E-Auto</a>
            </button>
            <button className='solution_tabs_btns '>
              <a href='#OEM'> OEM</a>
            </button>
          </div>
        </div>

        <a id='fleet' className='section-scroll-link' href='#'></a>

        <div id='Fleet'>
          <FleetSection />
        </div>
        <div id='E-Auto'>
          <EautoSection />
        </div>
        <a id='oem' className='section-scroll-link' href='#'></a>
        <div id='OEM'>
          <OEMSection />
        </div>
      </section>
    </>
  )
}

export default SolutionTabs
