import React from 'react'
import { BsChevronDown } from 'react-icons/bs'
import about_bg_fade1 from '../assets/imgs/about-bg-fade1.webp'
import about_bg_fade2 from '../assets/imgs/about-bg-fade2.webp'
import one from '../assets/imgs/vault/a.png'

const VaultHero = () => {
  return (
    <>
      <section className="race_vault_main_section overflow_hidden">
        <section className="about_hero_main_section ">
          <img
            src={about_bg_fade1}
            className="img-fluid about_bg_fade1"
            alt="img"
            loading="lazy"
          />
          <img
            src={about_bg_fade2}
            className="img-fluid about_bg_fade2"
            alt="img"
            loading="lazy"
          />

          <div className="container">
            <h1 className="main_heading gradient_heading partner_hero">
              Invest in Next-Gen Fuel for a Greener Future
            </h1>
            <p className="main_para">
              RACE Vault presents an opportunity to invest in RACE’s world-class
              technology assets that earn high revenue and accelerate India's
              battery revolution.
            </p>

            <div className="arrow_down_btn bounce">
              <BsChevronDown className="hero_down_arrow_icon" />
            </div>
          </div>
        </section>
        <section className="vault_big_img_section">
          <img src={one} className="img-fluid" alt="img" loading="lazy" />
        </section>
      </section>
    </>
  )
}

export default VaultHero
