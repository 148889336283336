import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Brand1 from '../assets/imgs/newsroom/brand1.webp'
import Brand2 from '../assets/imgs/newsroom/brand2.webp'
import Brand3 from '../assets/imgs/newsroom/brand3.webp'
import Brand4 from '../assets/imgs/newsroom/brand4.webp'
import Brand5 from '../assets/imgs/newsroom/brand5.webp'
import product1 from '../assets/imgs/newsroom/product1.png'
import product2 from '../assets/imgs/newsroom/product2.webp'
import product3 from '../assets/imgs/newsroom/product3.png'
import product4 from '../assets/imgs/newsroom/product4.png'
import product5 from '../assets/imgs/newsroom/product5.png'
import product11 from '../assets/imgs/newsroom/products11.png'
import product44 from '../assets/imgs/newsroom/product44.webp'
import leadership1 from '../assets/imgs/newsroom/Leadership1.png'
import leadership2 from '../assets/imgs/newsroom/leadership2.png'
import leadership3 from '../assets/imgs/newsroom/leadership3.png'
import leadership4 from '../assets/imgs/newsroom/leadership4.png'
import leadership5 from '../assets/imgs/newsroom/leadership5.png'
import leadership6 from '../assets/imgs/newsroom/leadership6.webp'
import leadership7 from '../assets/imgs/newsroom/leadership7.webp'
import leadership8 from '../assets/imgs/newsroom/leadership8.webp'
import leadership9 from '../assets/imgs/newsroom/leadership9.webp'
import leadership13 from '../assets/imgs/newsroom/leadership13.webp'
import eye from '../assets/imgs/newsroom/eye.webp'
import download from '../assets/imgs/newsroom/download.webp'
import footer_bg from '../assets/imgs/footer-bg.webp'
import preshkit_bg from '../assets/imgs/About/team-bg.webp'

import { Link } from 'react-router-dom'

const PressKit = () => {
  const [show, setShow] = useState(false)
  const [presskitImage, setPresskitImage] = useState(null)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showSection, setShowSection] = useState(false)

  const toggleSection = () => {
    setShowSection(!showSection)
  }

  return (
    <>
      <section className="PressKit_main_section">
        <img
          src={footer_bg}
          className="img-fluid footer_bg"
          alt="img"
          loading="lazy"
        />
        <img
          src={preshkit_bg}
          className="img-fluid preshkit_bg"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <div className="PressKit_main_headings">
            <h1>Press Kit</h1>
          </div>

          <section className="tabs_div">
            <Tabs
              defaultActiveKey="Brand"
              id="uncontrolled-tab-example"
              className=" tabs_div_inner"
            >
              <Tab eventKey="Brand" title="Brand" className="">
                <section>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 presh_kit_img_box">
                      <div className="preshkit_products_box">
                        <img
                          src={Brand1}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(Brand1)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={Brand1} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 presh_kit_img_box">
                      <div className="preshkit_products_box">
                        <img
                          src={Brand2}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(Brand2)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={Brand2} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 presh_kit_img_box  ">
                      <div className="preshkit_products_box">
                        <img
                          src={Brand3}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(Brand3)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={Brand3} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12  col-md-6 col-sm-12  presh_kit_img_box ">
                      <div className="preshkit_products_box">
                        <img
                          src={Brand4}
                          className="img-fluid Brand4_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(Brand4)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={Brand4} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12  col-md-6 col-sm-12  presh_kit_img_box brand5">
                      <div className="preshkit_products_box">
                        <img
                          src={Brand5}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(Brand5)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={Brand5} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="preshkit_view_all_btn_div">
                    <Link to="#">View all</Link>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="Products " title="Products ">
                <section>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-md-6 col-sm-12 ">
                      <div className="preshkit_products_box">
                        <img
                          src={product1}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(product1)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={product1} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h2>RACE E-Auto </h2>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-8 col-md-8 col-sm-12 ">
                      <div className="preshkit_products_box">
                        <img
                          src={product2}
                          className="img-fluid newsroom_desktop_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <img
                          src={product11}
                          className="img-fluid newsroom_mob_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(product2)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={product2} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h2>RACE Energy fleet solutions </h2>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-4 col-sm-12 ">
                      <div className="preshkit_products_box">
                        <img
                          src={product3}
                          className="img-fluid "
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(product3)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={product3} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h2>RACE Drivers </h2>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-3 col-md-3 col-sm-12 ">
                      <div className="preshkit_products_box">
                        <img
                          src={product4}
                          className="img-fluid product4_img newsroom_desktop_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <img
                          src={product44}
                          className="img-fluid product4_img newsroom_mob_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(product4)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={product4} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h2> RACE E-auto on ride</h2>
                        </div>
                      </div>
                    </div>

                    <div className=" col-12 col-lg-5 col-md-5 col-sm-12 ">
                      <div className="preshkit_products_box">
                        <img
                          src={product5}
                          className="img-fluid product5_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>

                        <div className="preshkit_products_box_content">
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(product5)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={product5} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <h2> RACE Battery</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="preshkit_view_all_btn_div">
                    <Link to="#">View all</Link>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="Leadership" title="Leadership">
                <section>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-md-6 col-sm-12 ">
                      <div className="preshkit_products_box leadership_preshkit_products_box">
                        <img
                          src={leadership1}
                          className="img-fluid  preshkit_products_box_leadership_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content_btns">
                          <Link
                            to="#"
                            onClick={() => {
                              handleShow()
                              setPresskitImage(leadership1)
                            }}
                          >
                            <img
                              src={eye}
                              className="img-fluid eye_icons"
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                          <Link to={leadership1} target="_blank" download>
                            <img
                              src={download}
                              className="img-fluid "
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-8 col-md-8 col-sm-12 ">
                      <div className="preshkit_products_box leadership_preshkit_products_box">
                        <img
                          src={leadership2}
                          className="img-fluid  preshkit_products_box_leadership_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content_btns">
                          <Link
                            to="#"
                            onClick={() => {
                              handleShow()
                              setPresskitImage(leadership2)
                            }}
                          >
                            <img
                              src={eye}
                              className="img-fluid eye_icons"
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                          <Link to={leadership2} target="_blank" download>
                            <img
                              src={download}
                              className="img-fluid "
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-6 col-sm-12 ">
                      <div className="preshkit_products_box leadership_preshkit_products_box">
                        <img
                          src={leadership3}
                          className="img-fluid  preshkit_products_box_leadership_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content_btns">
                          <Link
                            to="#"
                            onClick={() => {
                              handleShow()
                              setPresskitImage(leadership3)
                            }}
                          >
                            <img
                              src={eye}
                              className="img-fluid eye_icons"
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                          <Link to={leadership3} target="_blank" download>
                            <img
                              src={download}
                              className="img-fluid "
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-3 col-md-3 col-sm-12 ">
                      <div className="preshkit_products_box leadership_preshkit_products_box">
                        <img
                          src={leadership4}
                          className="img-fluid  preshkit_products_box_leadership_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content_btns">
                          <Link
                            to="#"
                            onClick={() => {
                              handleShow()
                              setPresskitImage(leadership4)
                            }}
                          >
                            <img
                              src={eye}
                              className="img-fluid eye_icons"
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                          <Link to={leadership4} target="_blank" download>
                            <img
                              src={download}
                              className="img-fluid "
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-5 col-md-5 col-sm-12 ">
                      <div className="preshkit_products_box leadership_preshkit_products_box">
                        <img
                          src={leadership5}
                          className="img-fluid preshkit_products_box_leadership_img"
                          alt="img"
                          loading="lazy"
                        />
                        <div className="overlay"></div>
                        <div className="preshkit_products_box_content_btns">
                          <Link
                            to="#"
                            onClick={() => {
                              handleShow()
                              setPresskitImage(leadership5)
                            }}
                          >
                            <img
                              src={eye}
                              className="img-fluid eye_icons"
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                          <Link to={leadership5} target="_blank" download>
                            <img
                              src={download}
                              className="img-fluid "
                              alt="img"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showSection && (
                    <div className="row">
                      <div className="col-12 col-lg-4 col-md-6 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership6}
                            className="img-fluid  preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership6)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership6} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership9}
                            className="img-fluid  preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership9)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership9} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership8}
                            className="img-fluid  preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership8)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership8} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 col-md-3 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership13}
                            className="img-fluid  preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership13)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership13} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-5 col-md-5 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership7}
                            className="img-fluid preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership7)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership7} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4 col-md-4 col-sm-12 ">
                        <div className="preshkit_products_box leadership_preshkit_products_box">
                          <img
                            src={leadership1}
                            className="img-fluid preshkit_products_box_leadership_img"
                            alt="img"
                            loading="lazy"
                          />
                          <div className="overlay"></div>
                          <div className="preshkit_products_box_content_btns">
                            <Link
                              to="#"
                              onClick={() => {
                                handleShow()
                                setPresskitImage(leadership1)
                              }}
                            >
                              <img
                                src={eye}
                                className="img-fluid eye_icons"
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                            <Link to={leadership1} target="_blank" download>
                              <img
                                src={download}
                                className="img-fluid "
                                alt="img"
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="preshkit_view_all_btn_div"
                    onClick={toggleSection}
                  >
                    <Link to="#">{showSection ? 'See Less' : 'View All'}</Link>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </section>
        </div>
      </section>

      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img src={presskitImage} className="img-fluid"  alt="img" loading="lazy" />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PressKit
