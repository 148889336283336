import React from 'react'
import slidericon1 from '../assets/imgs/partner/slidericon1.webp'
import slidericon2 from '../assets/imgs/partner/slidericon2.webp'
import slidericon3 from '../assets/imgs/partner/slidericon3.webp'
import slidericon4 from '../assets/imgs/partner/slidericon4.webp'
import slidericon5 from '../assets/imgs/partner/slidericon5.webp'
import slidericon6 from '../assets/imgs/partner/slidericon6.webp'
import arrow_img from '../assets/imgs/btn_arrow_blue.png'
import slider1 from '../assets/imgs/partner/slider1.webp'
import slider2 from '../assets/imgs/partner/slider2.webp'
import slider3 from '../assets/imgs/partner/slider3.webp'
import slider4 from '../assets/imgs/partner/slider4.webp'
import slider5 from '../assets/imgs/partner/slider5.webp'
import slider6 from '../assets/imgs/partner/slider6.webp'
import { Link } from 'react-router-dom'

const PartnershipSlider = () => {
  return (
    <>
      <section className="Partnership_slider_main_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 Partnership_slider_main_headings">
              <h1>Discover India's Growing EV Industry</h1>
              <p>
                Rise in temperatures and fuel costs and depreciating air quality
                are leading more consumers to adopt sustainable solutions. RACE
                is building deep technology to reshape how we commute. The
                360-degree offerings for electric vehicles bring maintenance
                costs much lower than ICE vehicles and make owning and using an
                EV easy, seamless and economic.  
              </p>
            </div>
          </div>
          <section className="Partnership_slider_section_main">
            <div className="scroller_slider_main">
              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots_active"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon1}
                          alt="img"
                          loading="lazy"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h3>Consumer Attitudes</h3>
                        <p>
                          Rise in temperatures and fuel costs and depreciation
                          of air quality are leading more consumers to adopt
                          sustainable solutions. With electric vehicle
                          maintenance costs much lower than ICE vehicles, it
                          acts as an added benefit to consumers. The electric
                          vehicle registrations in India saw a three-fold jump
                          last year, with{' '}
                          <span className="slider_blue_color">
                            {' '}
                            10.20 lakh vehicles{' '}
                          </span>{' '}
                          being registered in 2022. In addition, the Indian
                          E-2-wheeler market sales are estimated to hit{' '}
                          <span className="slider_grident_color">
                            {' '}
                            4.5 million by 2025.
                          </span>{' '}
                          The sheer numbers are proof enough!{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider1}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots_active"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon2}
                          alt="img"
                          loading="lazy"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h3>Favorable Policies</h3>
                        <p>
                          The government of India has set an ambitious goal to
                          make India a 100% electric vehicle nation by the year
                          2030. The central government has been laying emphasis
                          on the electric vehicle industry and has introduced
                          several effective policies to improve the adoption of
                          electric mobility in the country. Faster Adoption and
                          Manufacturing of (Hybrid &) Electric Vehicles (FAME)
                          schemes I and II have been widely successful in
                          strengthening electric vehicle (EV) adoption across
                          India. The schemes provide financial assistance to
                          manufacturers to set up facilities, as well as to
                          consumers who purchase electric vehicles by offering
                          various subsidies and tax incentives.
                        </p>
                        <div className="readmore_btn">
                          <Link to="" className="">
                            Read More{' '}
                            <img src={arrow_img} alt="img" loading="lazy" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider2}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots_active"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon3}
                          alt="img"
                          loading="lazy"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h3>Improving Infrastructure</h3>
                        <p>
                          With the aid of the central and state governments, the
                          sector is increasingly focusing on setting-up better
                          infrastructure to meet the growing demand. Through
                          various public-private partnerships, the industry is
                          working towards achieving 461000 EV charging/swapping
                          stations by 2030.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider3}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots_active"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon4}
                          alt="img"
                          loading="lazy"
                          className="img-fluid ripee"
                        />
                      </div>
                      <div>
                        <h3>Focus on ‘Make In India’</h3>
                        <p>
                          Make in India is playing a vital role in the electric
                          vehicle sector, enabling in- house manufacturing of
                          various components and reducing dependencies on
                          imports. The production Linked Incentive scheme with a
                          budget of INR 44,038 crores for the automotive
                          industry is a driving factor for the local
                          manufacturing of batteries and other electric vehicle
                          components.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider4}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots_active"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon5}
                          alt="img"
                          loading="lazy"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h3>More Career Opportunities</h3>
                        <p>
                          There is a multitude of career opportunities provided
                          by the electric vehicle sector. The vast potential
                          will enable many young minds to explore job
                          opportunities in the country, thereby contributing to
                          economic growth. the electric vehicle industry is
                          estimated to create over{' '}
                          <span className="slider_grident_color">
                            {' '}
                            9 million
                          </span>{' '}
                          job opportunities by 2030.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider5}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="scroller_slider_main_inner_single">
                <div className="scroller_slider_indicators_dots_main">
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots"></div>
                  <div className="scroller_slider_indicators_dots_active"></div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="Partnership_slider_content_side">
                      <div className="Partnership_slider_content_side_icon">
                        <img
                          src={slidericon6}
                          alt="img"
                          loading="lazy"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h3>Gaining Popularity of Climate Tech</h3>
                        <p>
                          Sustainability and climate action goals are becoming a
                          front-runner for the Indian government as well as many
                          private investment firms. in the year 2021,{' '}
                          <span className="slider_blue_color"> $7 billion</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 slider_imgs">
                    <img
                      src={slider6}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="Partnership_slider_section_main_mobile">
            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon1}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Consumer Attitudes</h3>
                  </div>
                </div>
                <p className="Partnership_slider_content_side_para">
                  <p>
                    Rise in temperatures and fuel costs and depreciation of air
                    quality are leading more consumers to adopt sustainable
                    solutions. With electric vehicle maintenance costs much
                    lower than ICE vehicles, it acts as an added benefit to
                    consumers. The electric vehicle registrations in India saw a
                    three-fold jump last year, with{' '}
                    <span className="slider_blue_color">
                      {' '}
                      10.20 lakh vehicles{' '}
                    </span>{' '}
                    being registered in 2022. In addition, the Indian
                    E-2-wheeler market sales are estimated to hit{' '}
                    <span className="slider_grident_color">
                      {' '}
                      4.5 million by 2025.
                    </span>{' '}
                    The sheer numbers are proof enough!{' '}
                  </p>
                </p>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 slider_imgs">
                <img
                  src={slider1}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon2}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Favorable Policies</h3>
                  </div>
                </div>
                <p
                  className="Partnership_slider_content_side_para"
                  style={{ marginBottom: '0' }}
                >
                  The government of India has set an ambitious goal to make
                  India a 100% electric vehicle nation by the year 2030. The
                  central government has been laying emphasis on the electric
                  vehicle industry and has introduced several effective policies
                  to improve the adoption of electric mobility in the country.
                  Faster Adoption and Manufacturing of (Hybrid &) Electric
                  Vehicles (FAME) schemes I and II have been widely successful
                  in strengthening electric vehicle (EV) adoption across India.
                  The schemes provide financial assistance to manufacturers to
                  set up facilities, as well as to consumers who purchase
                  electric vehicles by offering various subsidies and tax
                  incentives.
                </p>
                <div className="readmore_btn">
                  <Link to="" className="">
                    Read More <img src={arrow_img} alt="img" loading="lazy" />
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 col-sm-12 slider_imgs"
                style={{ marginTop: '36px' }}
              >
                <img
                  src={slider2}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon3}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Improving Infrastructure</h3>
                  </div>
                </div>
                <p className="Partnership_slider_content_side_para">
                  With the aid of the central and state governments, the sector
                  is increasingly focusing on setting-up better infrastructure
                  to meet the growing demand. Through various public-private
                  partnerships, the industry is working towards achieving 461000
                  EV charging/swapping stations by 2030.
                </p>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 slider_imgs">
                <img
                  src={slider3}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon4}
                      alt="img"
                      loading="lazy"
                      className="img-fluid  ripee"
                    />
                  </div>
                  <div>
                    <h3 className="ripee_text">Focus on ‘Make In India’</h3>
                  </div>
                </div>
                <p className="Partnership_slider_content_side_para">
                  Make in India is playing a vital role in the electric vehicle
                  sector, enabling in- house manufacturing of various components
                  and reducing dependencies on imports. The production Linked
                  Incentive scheme with a budget of INR 44,038 crores for the
                  automotive industry is a driving factor for the local
                  manufacturing of batteries and other electric vehicle
                  components.
                </p>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 slider_imgs">
                <img
                  src={slider4}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon5}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>More Career Opportunities</h3>
                  </div>
                </div>
                <p className="Partnership_slider_content_side_para">
                  There is a multitude of career opportunities provided by the
                  electric vehicle sector. The vast potential will enable many
                  young minds to explore job opportunities in the country,
                  thereby contributing to economic growth. the electric vehicle
                  industry is estimated to create over{' '}
                  <span className="slider_grident_color"> 9 million</span> job
                  opportunities by 2030.
                </p>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 slider_imgs">
                <img
                  src={slider5}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row Partnership_slider_section_main_mobile_row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Partnership_slider_content_side">
                  <div className="Partnership_slider_content_side_icon">
                    <img
                      src={slidericon6}
                      alt="img"
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h3>Gaining Popularity of Climate Tech</h3>
                  </div>
                </div>
                <p className="Partnership_slider_content_side_para">
                  Sustainability and climate action goals are becoming a
                  front-runner for the Indian government as well as many private
                  investment firms. in the year 2021,{' '}
                  <span className="slider_blue_color"> $7 billion</span>
                </p>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 slider_imgs">
                <img
                  src={slider6}
                  alt="img"
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default PartnershipSlider
