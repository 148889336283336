import React from 'react'
import { BsChevronDown } from 'react-icons/bs'
import about_bg_fade1 from '../assets/imgs/about-bg-fade1.webp'
import about_bg_fade2 from '../assets/imgs/about-bg-fade2.webp'
import about_arrow from '../assets/imgs/about-arrow.webp'

const AboutHero = () => {
  return (
    <>
      <section className="about_hero_main_section overflow_hidden">
        <img
          src={about_bg_fade1}
          className="img-fluid about_bg_fade1"
          alt="img"
          loading="lazy"
        />
        <img
          src={about_bg_fade2}
          className="img-fluid about_bg_fade2"
          alt="img"
          loading="lazy"
        />

        <div className="container">
          <h1 className="main_heading">
            Powering the World’s Largest <span> Battery Swap Network </span>
          </h1>
          <p className="main_para">
            Reimagining mobility in India by enhancing EV battery technology,
            uplifting driver income and reducing pollution.
          </p>

          <div className="arrow_down_btn bounce">
            <BsChevronDown className="hero_down_arrow_icon" />
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutHero
