import React, { useState, useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import OEM1_slider_1 from '../assets/imgs/custom/OEM1_slider_1.webp'
import OEM1_slider_2 from '../assets/imgs/custom/OEM1_slider_2.webp'
import OEM1_slider_3 from '../assets/imgs/custom/OEM1_slider_3.webp'

import OEM_mob_1 from '../assets/imgs/custom/OEM_mob_1.png'
import OEM_mob_2 from '../assets/imgs/custom/OEM_mob_2.png'
import OEM_mob_3 from '../assets/imgs/custom/OEM_mob_3.png'

import Dropdown from 'react-bootstrap/Dropdown'
import footer_bg from '../assets/imgs/footer-bg.webp'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

const OEMSection = () => {
  const navigate = useNavigate()

  // ===========Modal after form submit==========

  const [show_success, setShow_success] = useState(false)

  const handleClose_success = () => setShow_success(false)
  const handleShow_success = () => setShow_success(true)

  const [show_error, setShow_error] = useState(false)

  const handleClose_error = () => setShow_error(false)
  const handleShow_error = () => setShow_error(true)

  // ===========Hooks for form Fields==========

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    partnership_type: '',
    investment_range: '',
    investment_range_f: '',
    selectedValue_race: '',
    company_name: '',
    previous_experience: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    power_availability: '',
    type_of_space: '',
    type_of_vehicle: '',
    annual_vehicle_requirement: '',
    location_of_sale: '',
    contents: '',
    hear_about_us: '',
    new_fields: '',
    page_name: '',
    other_partnership: ''
  })

  const {
    name,
    email,
    phone,
    partnership_type,
    investment_range,
    investment_range_f,
    hear_about_us,
    company_name,
    previous_experience,
    city,
    state,
    country,
    pincode,
    annual_vehicle_requirement,
    location_of_sale,
    contents,
    other_partnership
  } = formData

  const [selectedcount, setselectedcount] = useState()
  const [Vehiclecount, SetVehiclecount] = useState()

  // ===========Current page path==========

  const currentPath = window.location.pathname
  // const currentPathSlug = currentPath.substring(
  //   currentPath.lastIndexOf('/') + 1,
  // )

  const [index, setIndex] = useState(0)
  const [selectedValue_space, setSelectedValue_space] = useState('')
  const [labelPosition_space, setLabelPosition_space] = useState('')

  const handleSelectChange_space = event => {
    setSelectedValue_space(event.target.value)
    setLabelPosition_space(event.target.value ? 'top' : '')
  }

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      // Call a function to go to the next slide
      // Example: nextSlide();
    }, 5000)

    setIntervalId(carouselInterval)

    return () => clearInterval(carouselInterval)
  }, [])

  const handleMouseEnter = () => {
    clearInterval(intervalId)
    console.log('hover')
  }

  const handleMouseLeave = () => {
    const newInterval = setInterval(() => {
      // Call a function to go to the next slide
      // Example: nextSlide();
    }, 2000)

    setIntervalId(newInterval)
    console.log('not hover')
  }

  // =============Contact us from submit==================

  const Contactus_from_submit = e => {
    e.preventDefault()
    contactUsApi()
    handleShow_success()
    setFormData('')
    navigate('/thankyou')
    // if (
    //   name != '' &&
    //   email != '' &&
    //   phone != '' &&
    //   hearInput_string != '' &&
    //   selectedValue_space != '' &&
    //   annual_vehicle_requirement != '' &&
    //   location_of_sale != ''
    // ) {
    //   contactUsApi()
    //   handleShow_success()
    //   setFormData('')
    //   navigate('/thankyou')
    // } else {
    //   handleShow_error()
    // }
  }

  // =============contactUs Api call==================

  const contactUsApi = () => {
    var formdata = new FormData()
    formdata.append('name', name ?? '')
    formdata.append('email', email ?? '')
    formdata.append('mobile', phone ?? '')

    formdata.append('type_of_vehicle', vehicleTypeInput_string ?? '')
    formdata.append('type_of_space', selectedValue_space ?? '')
    formdata.append(
      'annual_vehicle_requirement',
      annual_vehicle_requirement ?? ''
    )
    formdata.append('location_of_sale', location_of_sale ?? '')
    formdata.append('hear_about_us', hearInput_string ?? '')
    formdata.append('page_name', currentPath ?? '')
    formdata.append('other_partnership', other_partnership ?? '')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/contact-us', requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  // hook for hear about us
  const [hearInput, setHearInput] = useState([])
  const hearInput_string = hearInput.join(', ')

  // =============Hear about us==================

  const handleChangeHearAbout = e => {
    let GethearValue = [...hearInput]

    if (e.target.checked) {
      console.log(e.target.value)
      GethearValue = [...hearInput, e.target.value]
      if (GethearValue) {
        setHearInput(GethearValue)
        const GethearValuecount = GethearValue.length
        setselectedcount(GethearValuecount)
      }
    } else {
      GethearValue.splice(hearInput.indexOf(e.target.value), 1) // araay list
      setHearInput(GethearValue)
      const GethearValuecount = GethearValue.length
      setselectedcount(GethearValuecount)
    }
  }

  // =============Vehicle Type==================

  const [vehicleTypeInput, setvehicleTypeInput] = useState([])
  const vehicleTypeInput_string = vehicleTypeInput.join(', ')

  const handleChangeVehicleType = e => {
    let VehicleTypeValue = [...vehicleTypeInput]

    if (e.target.checked) {
      console.log(e.target.value)
      VehicleTypeValue = [...vehicleTypeInput, e.target.value]
      if (VehicleTypeValue) {
        setvehicleTypeInput(VehicleTypeValue)
        console.log(VehicleTypeValue)
      }
    } else {
      VehicleTypeValue.splice(vehicleTypeInput.indexOf(e.target.value), 1) // araay list
      setvehicleTypeInput(VehicleTypeValue)
      console.log(VehicleTypeValue)
    }
  }

  const onChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <section className='OEM_section_main'>
      <img
        src={footer_bg}
        alt='img'
        loading='lazy'
        className='img-fluid footer_bg'
      />

      <section className='fleet_main_section'>
        <div className='container'>
          <div className='fleet_main_section_headings'>
            <h1>OEM (Original Equipment Manufacturer)</h1>
            <p>
              Make your vehicle a hot seller by switching to RACE’s tech and
              reducing upfront costs by up to 50%
            </p>
          </div>

          {/* slider */}
          <section className='tabs_slider_main_section'>
            <Carousel
              fade
              prevIcon=<button
                disabled={index == 0}
                className=' back_btn'
              ></button>
              nextIcon=<button
                disabled={index == 4}
                className='next_btn'
              ></button>
              onSelect={handleSelect}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Carousel.Item>
                <img
                  src={OEM1_slider_1}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={OEM_mob_1}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />

                <Carousel.Caption className='carousel_caption'>
                  <h3>Turnkey Solution for Any EV</h3>
                  <p>
                    Convert any fixed battery EV to a swappable EV to make it
                    compatible with our modular swappable batteries and
                    hot-swapping connectors. We work with the existing
                    e-powertrain and bodies.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={OEM1_slider_2}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={OEM_mob_2}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />

                <Carousel.Caption className='carousel_caption'>
                  <h3>Race Extenders ​</h3>
                  <p>
                    Use our swapping network & batteries that work in tandem
                    with your fixed battery EVs to give an unlimited range.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={OEM1_slider_3}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_desktop_img'
                />
                <img
                  src={OEM_mob_2}
                  alt='img'
                  loading='lazy'
                  className='img-fluid vehicle_sol_mob_img'
                />

                <Carousel.Caption className='carousel_caption'>
                  <h3>Electric Powertrains for Any Three-Wheeler (L5) ​</h3>
                  <p>
                    Looking for an electric powertrain as well? Use our tried
                    and tested powertrains to make any autorickshaw, passenger
                    or cargo, electric. Avoid all the development time and jump
                    straight to production.{' '}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </section>

          {/* form section */}
          <section className='fleet_form_section_main'>
            <div className='fleet_form_section_main_headings'>
              <h4>Interested in Partnering With Us ​</h4>
              <p>
                Partner with us to build the future generation of Indian e-autos
                with high-capacity battery setups connected to automated swap
                stations.
              </p>
            </div>
            <section className='Battery_Form_main_section'>
              <div className=''>
                <section className='invest_form_main_border'>
                  <div className='invest_form_main_box'>
                    <h3>
                      Subject: Hey! I am interested in partnering with RACE.🙂
                    </h3>
                    <form
                      className='footprint_form'
                      onSubmit={Contactus_from_submit}
                      autoComplete='off'
                    >
                      <div className='row flex_end_content'>
                        <div className='col-lg-10 col-md-12 col-sm-12'>
                          <div className='row'>
                            {/* name */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='name'
                                  value={formData.name ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Name*
                                </label>
                              </div>
                            </div>
                            {/* email */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='email'
                                  placeholder=' '
                                  name='email'
                                  value={formData.email ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Email Id*
                                </label>
                              </div>
                            </div>
                            {/* phone number */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='number'
                                  placeholder=' '
                                  name='phone'
                                  value={formData.phone ?? ''}
                                  onChange={onChange}
                                  required
                                />
                                <label className='did-floating-label'>
                                  Phone number*
                                </label>
                              </div>
                            </div>

                            {/* Where did you .... */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <Dropdown className='custom_drop_down'>
                                  <Dropdown.Toggle
                                    variant=''
                                    className='custom_drop_down_btn'
                                    id='dropdown-basic'
                                  >
                                    {selectedcount ? (
                                      <span> {selectedcount} Selected</span>
                                    ) : (
                                      <span>Where did you hear about us?</span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Search Engine (Google, Bing, etc.)'
                                        id='flexCheckChecked1'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked1'
                                      >
                                        Search Engine (Google, Bing, etc.)
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Recommended by a Friend/Colleague'
                                        id='flexCheckChecked2'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked2'
                                      >
                                        Recommended by a Friend/Colleague
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Social Media (LinkedIn, Twitter)'
                                        id='flexCheckChecked3'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked3'
                                      >
                                        Social Media (LinkedIn, Twitter)
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Blog/Publication'
                                        id='flexCheckChecked4'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked4'
                                      >
                                        Blog/Publication
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Signage'
                                        id='flexCheckChecked5'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked5'
                                      >
                                        Signage
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Networking Event'
                                        id='flexCheckChecked6'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked6'
                                      >
                                        Networking Event
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Startup Founder'
                                        id='flexCheckChecked7'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked7'
                                      >
                                        Startup Founder
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Word of Mouth'
                                        id='flexCheckChecked8'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked8'
                                      >
                                        Word of Mouth
                                      </label>
                                    </div>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        name='hearAboutUs'
                                        value='Others'
                                        id='flexCheckChecked9'
                                        onClick={handleChangeHearAbout}
                                      />
                                      <label
                                        className='form-check-label'
                                        for='flexCheckChecked9'
                                      >
                                        Others
                                      </label>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h4>OEM ( Original Equipment Manufacturer) </h4>
                          </div>

                          <div className='row'>
                            {/* Type of Space* */}
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                              <div className='select-container mb_48'>
                                <label
                                  className={`select-label ${labelPosition_space}`}
                                >
                                  Type of Space*
                                </label>
                                <select
                                  className='new_select__box'
                                  value={selectedValue_space}
                                  onChange={handleSelectChange_space}
                                  required
                                >
                                  <option value=''></option>
                                  <option value='Commercial Building'>
                                    Commercial Building
                                  </option>
                                  <option value='Petrol Pump'>
                                    Petrol Pump
                                  </option>
                                  <option value='Automative Store'>
                                    Automative Store
                                  </option>
                                  <option value='Parking Space'>
                                    Parking Space
                                  </option>
                                  <option value='Other'>Other (Mention)</option>
                                </select>
                              </div>
                            </div>
                            {/* Other Type of space */}
                            {selectedValue_space === 'Other' && (
                              <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='did-floating-label-content mb_48'>
                                  <input
                                    className='did-floating-input'
                                    type='text'
                                    placeholder=' '
                                    name='other_partnership'
                                    value={formData.other_partnership ?? ''}
                                    onChange={onChange}
                                  />
                                  <label className='did-floating-label'>
                                    Other Type of space
                                  </label>
                                </div>
                              </div>
                            )}

                            {/* Annual Vehicle Requirement  */}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                              <div className='did-floating-label-content mb_48'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='annual_vehicle_requirement'
                                  value={
                                    formData.annual_vehicle_requirement ?? ''
                                  }
                                  onChange={onChange}
                                />
                                <label className='did-floating-label'>
                                  Annual Vehicle Requirement
                                </label>
                              </div>
                            </div>

                            {/* Location of Sale/ Market/ Demand */}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                              <div className='did-floating-label-content mb_24'>
                                <input
                                  className='did-floating-input'
                                  type='text'
                                  placeholder=' '
                                  name='location_of_sale'
                                  value={formData.location_of_sale ?? ''}
                                  onChange={onChange}
                                />
                                <label className='did-floating-label'>
                                  Location of Sale/ Market/ Demand
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-2 col-md-12 col-sm-12'>
                          <div className='form_submit_btn form_submit_btn_flex_end'>
                            <button className='' type='submit'>
                              Submit{' '}
                              <img
                                src={btn_arrow}
                                className='learn_arrow'
                                alt='arrow'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </section>
          </section>
        </div>
      </section>
      <Modal
        show={show_success}
        onHide={handleClose_success}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='success_modal'
      >
        <Modal.Body> Your Data is Successfully submitted. </Modal.Body>
      </Modal>

      <Modal
        show={show_error}
        onHide={handleClose_error}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='Error_modal'
      >
        <Modal.Body> Please fill all required details/fields. </Modal.Body>
      </Modal>
    </section>
  )
}

export default OEMSection
