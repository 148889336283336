import React from 'react'
import BatteryHero from './BatteryHero'
import Advancedbattery from './Advancedbattery'
import GIFSection from './GIFSection'
import NumbersSection from './NumbersSection'
import InvestTech from './InvestTech'
import BatteryForm from './BatteryForm'

const BatteryMain = () => {
  return (
    <>
      <div className="body_overflow_hide">
        <BatteryHero />
        <Advancedbattery />
        <GIFSection />
        <NumbersSection />
        <InvestTech />
        <BatteryForm />
      </div>
    </>
  )
}

export default BatteryMain
