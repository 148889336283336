import React, { useRef, useEffect } from 'react'

import icon4 from '../assets/imgs/About/Logo_Animation.webm'
import $ from 'jquery'
const BoxIcons = () => {
  $(document).on('click mousemove', 'body', function (e) {
    var x = e.clientX
    var y = e.clientY
    var newposX = x - 60
    var newposY = y - 60
    $('.circle').css(
      'transform',
      'translate3d(' + newposX + 'px,' + newposY + 'px,0px)',
    )
  })

  const videoRef = useRef(null)

  useEffect(() => {
    const isAutoplaySupported = 'autoplay' in document.createElement('video')

    if (isAutoplaySupported) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video autoplay started')
        })
        .catch((error) => {
          console.log('Video autoplay failed:', error)
        })
    } else {
      console.log('Autoplay is not supported in this browser.')
    }
  }, [])
  return (
    <>
      <section className="box_icons_main_section">
        <div className="container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="box_icons_boxes_main">
                <video
                  ref={videoRef}
                  autoPlay
                  muted
                  width="100%"
                  height="100%"
                  loop
                  className="banner_img"
                >
                  <source src={icon4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BoxIcons
