import React, { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import News1 from '../assets/imgs/About/news1.webp'
import News2 from '../assets/imgs/About/news2.webp'
import footer_bg from '../assets/imgs/footer-bg.webp'
import news_bg_ring from '../assets/imgs/About/news_bg_ring.webp'
import btn_arrow from '../assets/imgs/arrow_btn.png'
import { Link } from 'react-router-dom'
import { FiArrowUpRight } from 'react-icons/fi'
import dayjs from 'dayjs'

const News = () => {
  const [moreStories, setMoreStories] = useState([])
  const baseurl = 'https://race.pselier.com'

  const morestoriesAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/more-stories', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.result) {
          setMoreStories(result?.result)
        }
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    morestoriesAPI()
    console.log(moreStories, 'morestories')
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 769, min: 0 },
      items: 1
    }
  }
  return (
    <>
      <a id='News' className='section-scroll-link' href='#'></a>
      <section className='news_section_main' id=''>
        <img
          src={footer_bg}
          className='img-fluid footer_bg'
          alt='img'
          loading='lazy'
        />

        <img
          src={news_bg_ring}
          className='img-fluid news_bg_ring'
          alt='img'
          loading='lazy'
        />
        <div className='container'>
          <div className='news_section_main_inner'>
            <h1 className='main_headings'>In the News</h1>

            <section>
              <Carousel
                swipeable={true}
                draggable={true}
                responsive={responsive}
                infinite={true}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={3000}
              >
                {moreStories?.news?.length ? (
                  moreStories?.news?.map(currEle => {
                    return (
                      <>
                        <div className='new_box_border'>
                          <div className='new_box_main'>
                            <img
                              src={
                                baseurl +
                                '/' +
                                'uploads/admin_blogs_images/' +
                                currEle?.featured_image
                              }
                              className='img-fluid'
                              alt='img'
                              loading='lazy'
                            />
                            <div className='new_read_more'>
                              <Link
                                className='read_more'
                                target='_blank'
                                to={currEle?.link}
                              >
                                Read More
                                <img
                                  src={btn_arrow}
                                  className='learn_arrow'
                                  alt='arrow'
                                />
                              </Link>
                            </div>
                            <div className='news_content'>
                              <h3>
                                <span>{currEle?.category?.name}</span> ,
                                <span style={{ marginLeft: '3px' }}>
                                  {dayjs(currEle?.created_at).format(
                                    'MMM DD, YYYY'
                                  )}
                                </span>
                              </h3>
                              <h2>{currEle?.title}</h2>
                              <p>{currEle?.short_description}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
                ) : (
                  <></>
                )}
              </Carousel>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default News
