import React, { useState } from 'react'
import { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'

const Morestories = () => {
  const [moreStories, setMoreStories] = useState([])

  const morestoriesAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/more-stories', requestOptions)
      .then(response => response.json())
      .then(result => {
        setMoreStories(result.result)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    morestoriesAPI()
    console.log(moreStories, 'morestories')
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 769, min: 0 },
      items: 1
    }
  }

  return (
    <>
      <section className='more_stories_main_section '>
        <div className='container'>
          <div className='more_stories_main_headings'>
            <h1>MORE STORIES</h1>
          </div>

          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            showDots={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
          >
            {moreStories?.blogs?.length ? (
              moreStories?.blogs?.map(currEle => {
                return (
                  <>
                    <div className='more_stories_single_box'>
                      <div className='news_by_and_date_div'>
                        <span className='news_color'>{currEle?.type} |</span>
                        <span className='news_by'>
                          {currEle.category?.name}
                        </span>
                      </div>
                      <h3>
                        <Link to={`/article/${currEle.slug}`}>
                          {' '}
                          {currEle.title}{' '}
                        </Link>
                      </h3>
                    </div>
                  </>
                )
              })
            ) : (
              <></>
            )}
          </Carousel>

          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            showDots={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            className='morestories_news_slider'
          >
            {moreStories?.news?.length ? (
              moreStories?.news?.map(currEle => {
                return (
                  <>
                    <div className='more_stories_single_box'>
                      <div className='news_by_and_date_div'>
                        <span className='news_color'>{currEle?.type} |</span>
                        <span className='news_by'>
                          {currEle.category?.name}
                        </span>
                      </div>
                      <h3>
                        <Link to={`/article/${currEle.slug}`}>
                          {' '}
                          {currEle.title}{' '}
                        </Link>
                      </h3>
                    </div>
                  </>
                )
              })
            ) : (
              <></>
            )}
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default Morestories
