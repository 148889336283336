import React from 'react'
import form_bg1 from '../assets/imgs/form_bg1.webp'
import form_bg2 from '../assets/imgs/form-bg-ring.webp'
import form_bg_fade3 from '../assets/imgs/fade.webp'
import form_bg_fade4 from '../assets/imgs/fade.webp'
import footer_bg from '../assets/imgs/footer-bg.webp'
import Linkedin from '../assets/imgs/Linkedin.webp'
import twitter from '../assets/imgs/Twitter.webp'
import { Link } from 'react-router-dom'
import CommonForm from '../CommonForm'

const ContactForm = () => {

  return (
    <>
      <section className="contact_us_form_main_page overflow_hidden">
        <img
          src={form_bg1}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg1"
        />
        <img
          src={form_bg2}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg2"
        />
        <img
          src={form_bg_fade3}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg_fade3"
        />
        <img
          src={form_bg_fade3}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg_fade4"
        />

        <div className="container">
          <div className="contact_us_form_main_headings">
            <h1>Get in Touch</h1>
            <section>
              <div className="our_footprint_inner_main_box_border">
                <div className="our_footprint_inner_main_box">
                  <div>
                    <CommonForm />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section className="contact_map_main_section">
        <img
          src={footer_bg}
          alt="img"
          loading="lazy"
          className="img-fluid footer_bg"
        />

        <img
          src={form_bg_fade4}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg_fade5"
        />
        <img
          src={form_bg_fade4}
          alt="img"
          loading="lazy"
          className="img-fluid form_bg_fade6"
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12 order-2 order-lg-1 map_col">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d475.69598470759513!2d78.3941879!3d17.4803848!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI4JzQ5LjMiTiA3OMKwMjMnMzkuMSJF!5e0!3m2!1sen!2sin!4v1688101871989!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ borderRadius: '12px' }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade" title='map'
              ></iframe>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 order-1 order-lg-2 contact_side_col_main">
              <div className="contact_side_col">
                <h3> Contact Us</h3>
                <div className="contact_side_single_col">
                  <h5>Phone Number:</h5>

                  <a href="tel:+91 9281066240">+91 92810 66240</a>
                </div>
                <div className="contact_side_single_col">
                  <h5>E-mail:</h5>
                  <a href="mailto:hello@racenergy.in">hello@racenergy.in</a>
                </div>

                <h3 className="Follow_us_div"> Follow Us</h3>
                <div className="Follow_us_socail_icons">
                  <Link
                    to="https://www.linkedin.com/company/racenergy/"
                    target="_blank"
                  >
                    <img
                      src={Linkedin}
                      alt="img"
                      loading="lazy"
                      className="img-fluid "
                    />
                  </Link>
                  <Link to="https://twitter.com/RACEnergy" target="_blank">
                    <img
                      src={twitter}
                      alt="img"
                      loading="lazy"
                      className="img-fluid "
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactForm
