import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useLocation, Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

import side_arrow from '../assets/imgs/testimonial_bg.webp'
import auto_img from '../assets/imgs/partner/partner-model-bg.webp'
import auto_img_mob from '../assets/imgs/partner/partner-model-bg_mob.png'

import Franchise_modal from '../assets/imgs/partner/Franchise-modal.webp'
import Franchise_modal_mob from '../assets/imgs/partner/Franchise_modal_mob.png'
import icon1 from '../assets/imgs/partner/icon1.webp'
import icon2 from '../assets/imgs/partner/icon2.webp'
import icon3 from '../assets/imgs/partner/icon3.webp'
import icon4 from '../assets/imgs/partner/icon4.webp'
import oem1 from '../assets/imgs/partner/oem1.webp'
import oem2 from '../assets/imgs/partner/oem2.webp'
import oem3 from '../assets/imgs/partner/oem3.webp'
import oem4 from '../assets/imgs/partner/icon4.webp'
import oem_bg from '../assets/imgs/partner/oem-bg.webp'
import oem_mob from '../assets/imgs/partner/oem_mob.png'
import fleet_bg from '../assets/imgs/partner/fleet-bg.webp'
import fleet_mob from '../assets/imgs/partner/fleet_mob.png'
import fleet1 from '../assets/imgs/partner/fleet1.webp'
import fleet2 from '../assets/imgs/partner/fleet2.webp'
import fleet3 from '../assets/imgs/partner/fleet3.webp'
import fleet4 from '../assets/imgs/partner/fleet4.webp'
import Franchise1 from '../assets/imgs/partner/Franchise1.webp'
import Franchise2 from '../assets/imgs/partner/Franchise2.webp'
import Franchise3 from '../assets/imgs/partner/Franchise3.webp'
import Franchise4 from '../assets/imgs/partner/Franchise4.webp'
import E_partner1 from '../assets/imgs/partner/e_p_1.webp'
import E_partner2 from '../assets/imgs/partner/e_p_2.webp'

const PartnershipModels = () => {
  const [activeTab, setActiveTab] = useState('area')

  useEffect(() => {
    const hash = window.location.hash.slice(1)
    if (hash === 'Franchise' || hash === 'Fleet' || hash === 'OEM') {
      setActiveTab(hash)
    }
  }, [])

  const handleTabChange = tab => {
    setActiveTab(tab)
  }

  return (
    <>
      <a id='Partnership' className='section-scroll-link' href='#'></a>
      <section className='Partnership_Models_main_section'>
        <img
          src={side_arrow}
          className='img-fluid side_arrow'
          alt='img'
          loading='lazy'
        />

        <section className=' join_our_main_section'>
          <div className='container'>
            <div className='main_headings'>
              <h1>Partnership Models</h1>
            </div>

            <ul className=' tabs_div_inner  partner_tabs'>
              <li
                onClick={() => handleTabChange('area')}
                className={activeTab === 'area' ? 'active' : ''}
              >
                Area Partner
              </li>
              <li
                onClick={() => handleTabChange('Franchise')}
                className={activeTab === 'Franchise' ? 'active' : ''}
              >
                Franchise
              </li>
              <li
                onClick={() => handleTabChange('Fleet')}
                className={activeTab === 'Fleet' ? 'active' : ''}
              >
                Fleet
              </li>
              <li
                onClick={() => handleTabChange('OEM')}
                className={activeTab === 'OEM' ? 'active' : ''}
              >
                OEM
              </li>
            </ul>
            <div style={{ position: 'relative' }}>
              {activeTab === 'area' && (
                <div>
                  {' '}
                  <div>
                    <div className='partner_model_tabs_content'>
                      <h2>Area Partner</h2>
                      <p>
                        Bring & manage RACE’s efficient, automated swap network
                        and truly unlock your area’s potential.
                      </p>
                    </div>
                    <div className='row auto_img_bg_div'>
                      <div className='col-12 '>
                        <img
                          src={auto_img}
                          className='img-fluid vehicle_sol_desktop_img'
                          alt='img'
                          loading='lazy'
                        />
                        <img
                          src={auto_img_mob}
                          className='img-fluid vehicle_sol_mob_img'
                          alt='img'
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='auto_img_content_main'>
                      <div className='row '>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={icon1}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>High returns and low breakeven periods.</p>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={icon2}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Diverse & holistic product portfolio. Best in
                              class & always evolving!
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={icon3}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Best in technology & safety. High performance for
                              drivers.
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={icon4}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Holistic solutions in 2-wheelers & 3-wheelers to
                              address large market opportunity.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'Franchise' && (
                <div>
                  <div id=''>
                    <div className='partner_model_tabs_content'>
                      <h2>Franchise</h2>

                      <p>
                        With ultra-low power & space requirements, host our
                        modern swap station of the future anywhere you like and
                        cater to the growing EV demand.
                      </p>
                      <div className='Existing_partners_div'>
                        <h4>Existing partners</h4>
                        <img
                          src={E_partner2}
                          className='img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                        <img
                          src={E_partner1}
                          className='img-fluid'
                          alt='img'
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='row auto_img_bg_div'>
                      <div className='col-12 '>
                        <img
                          src={Franchise_modal}
                          className='img-fluid Franchise_desktop'
                          alt='img'
                          loading='lazy'
                        />
                        <img
                          src={Franchise_modal_mob}
                          className='img-fluid Franchise_mob'
                          alt='img'
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='auto_img_content_main'>
                      <div className='row '>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={Franchise1}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              The space you own is your asset. Liven it up with
                              swap station .{' '}
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={Franchise4}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Batteries are the new fuel. Invest & earn from a
                              battery swap station{' '}
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={Franchise3}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              A no-brainer value pack. Earn guaranteed monthly
                              income.
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={Franchise2}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Invest in a battery swap station today to stay up
                              with the times.​
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'Fleet' && (
                <div>
                  <div>
                    <div className='partner_model_tabs_content'>
                      <h2>Fleet Partnership</h2>
                      <p>
                        Move any kind of EV of yours to our swapping platform
                        and never worry about the battery and downtime again.
                      </p>
                    </div>
                    <div className='row auto_img_bg_div'>
                      <div className='col-12 '>
                        <img
                          src={fleet_bg}
                          className='img-fluid Franchise_desktop'
                          alt='img'
                          loading='lazy'
                        />
                        <img
                          src={fleet_mob}
                          className='img-fluid Franchise_mob'
                          alt='img'
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='auto_img_content_main'>
                      <div className='row '>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={fleet1}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              No downtime for charging, always keep generating
                              revenue
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={fleet2}
                              className='img-fluid'
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Reduce upfront costs as battery investment is on
                              us.
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={fleet3}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Go smart with our software package Driver
                              profiling, geofencing, etc.
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={fleet4}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Holistic solutions in 2-wheelers & 3-wheelers to
                              address large market opportunity.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'OEM' && (
                <div>
                  <div>
                    <div className='partner_model_tabs_content '>
                      <h2>OEM (Original Equipment Manufacturer) </h2>
                      <p>
                        Make your EV a hot-selling product by switching to
                        swappable batteries and lowering the price, even lower
                        than ICE Vehicles!
                      </p>
                    </div>
                    <div className='row auto_img_bg_div'>
                      <div className='col-12 '>
                        <img
                          src={oem_bg}
                          className='img-fluid Franchise_desktop'
                          alt='img'
                          loading='lazy'
                        />
                        <img
                          src={oem_mob}
                          className='img-fluid Franchise_mob'
                          alt='img'
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='auto_img_content_main'>
                      <div className='row '>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={oem1}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />

                            <p>
                              Make your vehicles compatible with our swappable
                              batteries in no time.
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={oem2}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              We work within your fixed battery volume and make
                              it swappable.{' '}
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={oem3}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Never worry about battery related maintenance and
                              after sales issues.
                            </p>
                          </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                          <div className='auto_img_content_box'>
                            <img
                              src={oem4}
                              className='img-fluid '
                              alt='img'
                              loading='lazy'
                            />
                            <p>
                              Turn any ICE cargo or passenger auto-rickshaw to
                              electric with our powertrains.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default PartnershipModels
