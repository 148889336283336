import React from 'react'
import plant_bg from '../assets/imgs/vault/plant_bg.webp'

const PlantBG = () => {
  return (
    <>
      <section className="plant_bg_main_section">
        <img src={plant_bg} className="img-fluid" alt="img" loading="lazy" />
        <h1>
          Every <span> Battery</span> You Invest = Planting{' '}
          <span className="span2"> 30 Trees</span>
        </h1>
      </section>
    </>
  )
}

export default PlantBG
