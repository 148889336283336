import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// export const fun = (item) => {
//   const div1 = document.getElementById(
//     'uncontrolled-tab-example-tab-AreaPartner',
//   )
//   const div2 = document.getElementById('uncontrolled-tab-example-tab-Franchise')
//   const div3 = document.getElementById('uncontrolled-tab-example-tab-Fleet')
//   const div4 = document.getElementById('uncontrolled-tab-example-tab-OEM')
//   console.log(div1)
//   div1.classList.remove('active')

//   console.log(div1, div2, div3, div4)
//   if (item == 'Fleet') {
//     div3.classList.add('active')
//   }
// }
