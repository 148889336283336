import React, { useState, useEffect } from 'react'
import next from '../assets/imgs/newsroom/next.png'
import back from '../assets/imgs/newsroom/back.png'
import { Link, useNavigate } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
import { FaFacebook, FaWhatsapp, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

import share from '../assets/imgs/newsroom/share.svg'

import dayjs from 'dayjs'
const ArticleHero = ({ singleBlog }) => {
  const navigate = useNavigate()
  const [Blog, setBlog] = useState([])
  const [currIndex, setCurrIndex] = useState(0)
  const [Banner, setBanner] = useState([])
  const baseurl = 'https://race.pselier.com'

  const { slug } = useParams()
  const [isListVisible, setListVisible] = useState(false)

  const handleButtonClick = () => {
    setListVisible(!isListVisible)
  }
  const currentPath = window.location
  const handleShareWhatsApp = () => {
    // WhatsApp sharing link
    const url = currentPath
    const encodedUrl = encodeURIComponent(url)
    const whatsappLink = `https://api.whatsapp.com/send?text=%20${encodedUrl}`

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappLink, '_blank')
  }
  const handleSharefacebook = () => {
    const url = currentPath

    const encodedUrl = encodeURIComponent(url)
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
    window.open(facebookLink, '_blank')
  }

  const handleSharetwitter = () => {
    const url = currentPath
    const encodedUrl = encodeURIComponent(url)
    const twitterLink = `https://twitter.com/intent/tweet?text=url=${encodedUrl}`

    // Open the Twitter sharing link in a new tab
    window.open(twitterLink, '_blank')
  }

  const handleSharelinkedin = () => {
    const url = currentPath
    const encodedUrl = encodeURIComponent(url)
    const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`

    window.open(linkedInLink, '_blank')
  }

  const blogAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/blogs', requestOptions)
      .then(response => response.json())
      .then(result => setBlog(result.result))
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    blogAPI()
    console.log(Blog)
  }, [])

  function BlogParaContent () {
    return {
      __html: singleBlog?.result?.description
    }
  }

  // banner API
  const BannerAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch('https://race.pselier.com/api/blogs_banners', requestOptions)
      .then(response => response.json())
      .then(result => setBanner(result.result))
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    BannerAPI()
  }, [])

  // ===================
  const NextSingleBlogAPI = () => {
    let index = Blog.findIndex(blog => blog.slug === slug)
    console.log(index)
    index++
    console.log('after plus', index)

    console.log(Blog)
    const currentbolg = Blog[index]
    console.log(currentbolg.slug)

    navigate(`/article/${currentbolg.slug}`)
  }

  const backSingleBlogAPI = () => {
    let index = Blog.findIndex(blog => blog.slug === slug)
    console.log(index)
    index--
    console.log('after plus', index)

    console.log(Blog)
    const currentbolg = Blog[index]
    console.log(currentbolg.slug)

    navigate(`/article/${currentbolg.slug}`)
  }
  return (
    <>
      {singleBlog && (
        <section className='article_hero_main_section'>
          <div className='container'>
            <div className='article_hero_main_headings'>
              <div onClick={backSingleBlogAPI}>
                <Link to='#'>
                  <div className='article_back_next_btn back_img'>
                    <img
                      src={back}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Link>
              </div>
              <div onClick={NextSingleBlogAPI}>
                <Link to='#'>
                  <div className='article_back_next_btn next_img'>
                    <img
                      src={next}
                      className='img-fluid'
                      alt='img'
                      loading='lazy'
                    />
                  </div>
                </Link>
              </div>

              <h1>{singleBlog?.result?.title}</h1>
              <div className='article_by_date_div'>
                <span className='color_span'>
                  {singleBlog?.result?.category.name}
                </span>
                <span>
                  | by {singleBlog?.result?.author_name}|
                  {dayjs(singleBlog?.result?.created_at).format('MMM DD, YYYY')}
                </span>
              </div>
              <div className='article_tags'>
                <div> #{singleBlog?.result?.tages}</div>
              </div>
              <div>
                <p className='blog_details_short_desc'>
                  {singleBlog?.result?.short_description}
                </p>
              </div>

              <section className='ArticleSlider_main_slider'>
                <div className='container'>
                  <Carousel fade>
                    {Banner?.map(currbanner => {
                      return (
                        <Carousel.Item>
                          <div className='ArticleSlider_main_single_border'>
                            <div className='ArticleSlider_main_single'>
                              <div className='row'>
                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                  <img
                                    src={
                                      baseurl +
                                      '/' +
                                      'uploads/admin_blogs_images/' +
                                      currbanner?.image
                                    }
                                    className='img-fluid '
                                    alt='img'
                                    loading='lazy'
                                  />
                                </div>
                                <div className='col-lg-9 col-md-9 col-sm-12'>
                                  <h2>{currbanner?.title} </h2>
                                  <p>{currbanner?.short_description}</p>
                                  <div className='article_learn_more_div'>
                                    <Link to={currbanner?.link}>
                                      Learn More
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    })}
                  </Carousel>
                </div>
              </section>

              <div
                className='border_radius'
                dangerouslySetInnerHTML={BlogParaContent()}
              />
              <Link to='#' onClick={handleButtonClick}>
                <div className='article_share_btn_div'>
                  <img
                    src={share}
                    alt='img'
                    loading='lazy'
                    className=''
                    style={{ borderRadius: '0' }}
                  />
                </div>
              </Link>
              {isListVisible && (
                <div className='share-buttons'>
                  <div className='artcile_share_icons'>
                    <Link className='' onClick={handleSharefacebook}>
                      {' '}
                      <FaFacebook />
                    </Link>
                  </div>
                  <div className='artcile_share_icons'>
                    <Link className='' onClick={handleShareWhatsApp}>
                      {' '}
                      <FaWhatsapp />
                    </Link>
                  </div>
                  <div className='artcile_share_icons'>
                    <Link className='' onClick={handleSharelinkedin}>
                      {' '}
                      <FaLinkedinIn />
                    </Link>
                  </div>
                  <div className='artcile_share_icons'>
                    <Link className='' onClick={handleSharetwitter}>
                      {' '}
                      <FaTwitter />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ArticleHero
