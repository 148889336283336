import React, { useState, useEffect } from 'react'
import number_bg_1 from '../assets/imgs/battery/Union.webp'
import number_bg_tab_1 from '../assets/imgs/battery/numbers-bg4.webp'
import sidering from '../assets/imgs/battery/side-ring.webp'
import number_img_1 from '../assets/imgs/battery/numbers-img11.webp'
import number_img_2 from '../assets/imgs/battery/numbers-img22.png'
import number_img_1_mob from '../assets/imgs/battery/numbers-img-mob.png'
import number_img_2_mob from '../assets/imgs/battery/numbers-img-mob2.png'
import numbers_img_tag from '../assets/imgs/battery/numbers-img-tag.png'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CountUp, { useCountUp } from 'react-countup'
const NumbersSection = () => {
  const [endValue1, setEndValue1] = useState(0)
  const [endValue2, setEndValue2] = useState(0)
  const [endValue3, setEndValue3] = useState(0)
  const [endValue4, setEndValue4] = useState(0)
  const [endValue5, setEndValue5] = useState(0)
  const [endValue6, setEndValue6] = useState(0)
  const [endValue7, setEndValue7] = useState(0)
  const [endValue8, setEndValue8] = useState(0)

  const handleMouseEnter = () => {
    setEndValue1(168)
    setEndValue2(9.5)
    setEndValue3(1.6)
    setEndValue4(48)
    setEndValue5(202)
    setEndValue6(8.9)
    setEndValue7(1.8)
    setEndValue8(48)
  }

  const handleMouseLeave = () => {
    setEndValue1(0)
    setEndValue2(0)
    setEndValue3(0)
    setEndValue4(0)
    setEndValue5(0)
    setEndValue6(0)
    setEndValue7(0)
    setEndValue8(0)
  }

  useEffect(() => {
    function handleResize () {
      if (window.innerWidth <= 768) {
        // Adjust this width as needed for your mobile view breakpoint
        handleMouseEnter()
        console.log('mobile')
      }
    }

    window.addEventListener('resize', handleResize)
    // window.removeEventListener('resize', handleMouseLeave)

    // Call the function on initial load if the screen size is initially within the mobile view range
    handleResize()

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <section className='Numbers_Section_main' id='gen'>
        <img
          src={number_bg_1}
          alt='img'
          loading='lazy'
          className='img-fluid number_bg_1'
        />
        <img
          src={sidering}
          alt='img'
          loading='lazy'
          className='img-fluid sidering'
        />
        <div className='container'>
          <div className='Numbers_Section_main_headings'>
            <h1>Scorching Outside, Chilled Inside</h1>
            <p>
              RACE Batteries have an aluminium channel matrix with thermal
              interface material powered by phase change technology.
              <span className='bold_color'> 50 °C </span>
              outside, <span className='bold_color'> 25 °C</span> inside.
            </p>
          </div>
          <section
            className='number__section'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            // onTouchStart={handleMouseEnter}
            // onTouchEnd={handleMouseLeave}
            // onMouseOver={handleMouseEnter}
          >
            <div className='tabs_div'>
              <Tabs
                defaultActiveKey='Gen 1'
                id='uncontrolled-tab-example'
                className='mb-3 '
              >
                <Tab eventKey='Gen 1' title='Gen 1'>
                  <img
                    src={number_bg_tab_1}
                    alt='img'
                    loading='lazy'
                    className='img-fluid number_bg_tab_1'
                  />

                  <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-12 number_side_img_col'>
                      <img
                        src={number_img_2}
                        alt='img'
                        loading='lazy'
                        className='img-fluid full_img number_img_1'
                      />
                      <img
                        src={number_img_1_mob}
                        alt='img'
                        loading='lazy'
                        className='img-fluid full_img number_img_11'
                      />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12 number_side_content_col number1_desktop'>
                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue1}
                            duration={2.75}
                            className='num endValue1'
                            enableScrollSpy
                          />
                          <span className='unit'>Wh/kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '10px' }}>Energy Density</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue2}
                            decimals={1}
                            duration={2.75}
                            className='num endValue2'
                            enableScrollSpy
                          />{' '}
                          <span className='unit'>kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '18px' }}>Weight</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue3}
                            decimals={1}
                            duration={2.75}
                            className='num endValue3'
                            enableScrollSpy
                          />{' '}
                          <span className='unit'>kWh</span>
                        </h2>
                        <h3 style={{ paddingLeft: '35px' }}>Energy</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue4}
                            duration={2.75}
                            className='num endValue4'
                            enableScrollSpy
                          />{' '}
                          <span className='unit last_unit'>V</span>
                        </h2>
                        <h3 style={{ paddingLeft: '0px', marginLeft: '-70px' }}>
                          Voltage Platform
                        </h3>
                      </div>
                    </div>

                    {/* number1_mobile  */}
                    <div className='col-lg-4 col-md-4 col-sm-12 number_side_content_col number1_mobile'>
                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={168}
                            duration={2.75}
                            enableScrollSpy
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>Wh/kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '10px' }}>Energy Density</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue2}
                            decimals={1}
                            duration={2.75}
                            className='num endValue2'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={9.5}
                            duration={2.75}
                            enableScrollSpy
                            decimals={1}
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '18px' }}>Weight</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue3}
                            decimals={1}
                            duration={2.75}
                            className='num endValue3'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={1.6}
                            duration={2.75}
                            enableScrollSpy
                            decimals={1}
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>kWh</span>
                        </h2>
                        <h3 style={{ paddingLeft: '35px' }}>Energy</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue4}
                            duration={2.75}
                            className='num endValue4'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={48}
                            duration={2.75}
                            enableScrollSpy
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit '>V</span>
                        </h2>
                        <h3 style={{ paddingLeft: '0px', marginLeft: '-70px' }}>
                          Voltage Platform
                        </h3>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='Gen2' title='Gen 2'>
                  <img
                    src={number_bg_tab_1}
                    alt='img'
                    loading='lazy'
                    className='img-fluid number_bg_tab_2'
                  />
                  <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-12 number_side_img_col'>
                      <img
                        src={number_img_1}
                        alt='img'
                        loading='lazy'
                        className='img-fluid number_img_2 full_img'
                      />
                      <img
                        src={number_img_2_mob}
                        alt='img'
                        loading='lazy'
                        className='img-fluid number_img_2_mob full_img'
                      />

                      <img
                        src={numbers_img_tag}
                        alt='img'
                        loading='lazy'
                        className='img-fluid numbers_img_tag'
                      />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12 number_side_content_col number1_desktop'>
                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue5}
                            duration={2.75}
                            className='num endValue1'
                            enableScrollSpy
                          />
                          <span className='unit'>Wh/kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '15px' }}>Energy Density</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue6}
                            decimals={1}
                            duration={2.75}
                            className='num endValue2'
                            enableScrollSpy
                          />{' '}
                          <span className='unit'>kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '17px' }}>Weight</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue7}
                            duration={2.75}
                            decimals={1}
                            className='num endValue3'
                            enableScrollSpy
                          />{' '}
                          <span className='unit'>kWh</span>
                        </h2>
                        <h3 style={{ paddingLeft: '32px' }}>Energy</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          <CountUp
                            start={0}
                            end={endValue8}
                            duration={2.75}
                            className='num endValue4'
                            enableScrollSpy
                          />{' '}
                          <span className='unit'>V</span>
                        </h2>
                        <h3 style={{ paddingLeft: '0px', marginLeft: '-70px' }}>
                          Voltage Platform
                        </h3>
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-12 number_side_content_col number1_mobile'>
                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue5}
                            duration={2.75}
                            className='num endValue1'
                            enableScrollSpy
                          /> */}
                          <CountUp
                            start={0}
                            end={202}
                            duration={2.75}
                            enableScrollSpy
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>Wh/kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '15px' }}>Energy Density</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue6}
                            decimals={1}
                            duration={2.75}
                            className='num endValue2'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={8.9}
                            duration={2.75}
                            enableScrollSpy
                            decimals={1}
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>kg</span>
                        </h2>
                        <h3 style={{ paddingLeft: '17px' }}>Weight</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue7}
                            duration={2.75}
                            decimals={1}
                            className='num endValue3'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={1.8}
                            duration={2.75}
                            enableScrollSpy
                            decimals={1}
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>kWh</span>
                        </h2>
                        <h3 style={{ paddingLeft: '32px' }}>Energy</h3>
                      </div>

                      <div className='number_side_content_col_single'>
                        <h2>
                          {/* <CountUp
                            start={0}
                            end={endValue8}
                            duration={2.75}
                            className='num endValue4'
                            enableScrollSpy
                          />{' '} */}
                          <CountUp
                            start={0}
                            end={48}
                            duration={2.75}
                            enableScrollSpy
                            scrollSpyOnce={false}
                            className='mobile_count_num'
                          ></CountUp>
                          <span className='unit'>V</span>
                        </h2>
                        <h3 style={{ paddingLeft: '0px', marginLeft: '-70px' }}>
                          Voltage Platform
                        </h3>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default NumbersSection
