import React from 'react'
import race_valut from '../assets/imgs/Franchise.png'
import high_return from '../assets/imgs/box_height_returns.png'
import Investment_money_mob from '../assets/imgs/partner/Launching.png'
import Investment_money from '../assets/imgs/partner/invest-money.png'
import partnership_bottom_left from '../assets/imgs/partnership_bottom-left.webp'
import { FiArrowUpRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import btn_arrow from '../assets/imgs/arrow_btn.png'

const Investment = () => {
  return (
    <>
      <section className="investment_main_section">
        <img
          src={partnership_bottom_left}
          alt="img"
          loading="lazy"
          className="img-fluid partnership_bottom_left"
        />

        <section className="partnership_main_section">
          <div className="container">
            <div className="partnership_main_inner_box_border">
              <div className="partnership_main_inner_box">
                <div className="partnership_main_headings mb__36">
                  <h1>Let’s Talk About Investment.</h1>
                  <p>
                    Promote green technology by investing in RACE batteries.
                    Earn income on the energy used via batteries & expand EV
                    adoption in India.
                  </p>
                </div>

                <div className="partnership_single_box">
                  <img
                    src={high_return}
                    alt="img"
                    loading="lazy"
                    className="img-fluid high_return_img"
                  />
                  <img
                    src={Investment_money}
                    alt="img"
                    loading="lazy"
                    className="img-fluid Investment_money"
                  />
                  <img
                    src={Investment_money_mob}
                    alt="img"
                    loading="lazy"
                    className="img-fluid Investment_money_mob"
                  />

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div>
                        <img
                          src={race_valut}
                          alt="img"
                          loading="lazy"
                          className="img-fluid partner_race_vault_img"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className="partnership_single_box_content">
                        <h1>RACE Vault</h1>
                        <p>
                          Invest in RACE's world-class technology assets to give
                          your investment portfolio a new look with guaranteed
                          return. Boost India's clean-tech revolution to reduce
                          pollution and move the country towards sustainability.{' '}
                        </p>
                      </div>
                      <div className="partnership_single_box_content_blue_btn">
                        <Link to="/vault" className="">
                          Start Investing{' '}
                          <img
                            src={btn_arrow}
                            className="learn_arrow"
                            alt="arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Investment
