import React from 'react'
import PartnerHero from './PartnerHero'
import PartnershipModels from './PartnershipModels'
import Investment from './Investment'
import PartnershipSlider from './PartnershipSlider'
import Partners from './Partners'
import BecomeourPartner from './BecomeourPartner'

const PartnerMain = () => {
  return (
    <>
      <PartnerHero />
      <PartnershipModels />
      <Investment />
      <PartnershipSlider />
      <Partners />
      <BecomeourPartner />
    </>
  )
}

export default PartnerMain
